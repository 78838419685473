import api from "../configurations/http-common";


export const createCustomer = async (payload) => {
    return api.unsecuredAxios().post("web/customer", payload);
  };

  export const updateCustomer = async (id,payload) => {
    return api.securedAxios().put("web/customer/"+id, payload);
  };

  export const updatePassword = async (userId,payload) => {
    return api.securedAxios().put("web/user/"+userId+"/password", payload);
  };
const CustomerService = {
    createCustomer,
    updateCustomer,
    updatePassword
  };
  
  export default CustomerService;