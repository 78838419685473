import { Box, Button, Dialog, Grid, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import EmployeeService from '../../../services/EmployeeService'
import useStyles from '../../onboard/Styles'
import MenuItem from "@mui/material/MenuItem"
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg"
import Loader from '../../../components/Loader'
import CustomDialog from '../../../components/CustomDialog'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { countryList } from '../../../util/CountryList'
// import "./style.css";




const EmployeeAdd = ({ flag, setFlag, loadData, title, action, id, setId }) => {
    const [tempRole, setTempRole] = useState("");
    const classes = useStyles();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("")
    const [roleId, setRoleId] = useState("")
    const [phone, setPhone] = useState("")
    const [phoneCountryCode, setPhoneCountryCode] = useState("62")
    const [country, setCountry] = useState("")
    const [status, setStatus] = useState("ACTIVE")
    const [siteId, setSiteId] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setComfimPassword] = useState("")
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([])

    console.log(locations)

    const [roles, setRoles] = useState([]);

    const styleDialog = {
        "& .MuiDialog-paper": {
            position: "absolute",
            boxShadow: 1,

            borderRadius: "12px",
            padding: "1rem",
        },
    };
    useEffect(() => {
        EmployeeService.getAllRoles().then((res) => {
            if (res != undefined) {
                const values = res.data.data.roles;
                console.log("res = " + values);
                const filteredUsers = values.filter(user => ['Admin', 'Graphic Designer'].includes(user.name));
                console.log(filteredUsers)


                const formattedData = filteredUsers.map((value) => ({
                    id: value.id,
                    label: value.name,
                }));

                const filteredUsers1 = values.filter(user => ['Admin'].includes(user.name));
                const formattedData1 = filteredUsers1.map((value) => ({
                    id: value.id,
                    label: value.name,
                }));
                console.log(formattedData1[0]['id']);
                setTempRole(formattedData1[0]['id']);

                setRoles(formattedData);
            }
        });
    }, []);

    useEffect(() => {
        if (id) {
            EmployeeService.getUserById(id).then((res) => {
                console.log(res);
                setFirstName(res.data.data["firstName"]);
                setLastName(res.data.data["lastName"]);
                // setCode(res.data.data.data["code"]);
                setEmail(res.data.data["email"]);
                setCountry(res.data.data["country"])
                const formatPhone = res.data.data["phone"]
                const formattedPhoneNum = formatPhone.split('-');
                const formattedCountryCode = formattedPhoneNum[0];
                const formattedPhone = formattedPhoneNum[1];
                setPhoneCountryCode(formattedCountryCode)
                setPhone(formattedPhone)
                console.log(formattedCountryCode + " phone to format with cc");
                console.log(formattedPhone + " phone to format with phone");
                console.log(formatPhone + "phone to format");
                // setSiteId(res.data.data.data["siteId"]);
                // setJobType(res.data.data.data["jobType"]);
                // setStatus(res.data.data.data["status"]);
                // setResignDate(res.data.data.data["resignDate"]);

                // if (res.data.data.data["joinDate"] !== "" && res.data.data.data["joinDate"] !== undefined) {
                //   var joinDateString = res.data.data.data["joinDate"];
                //   var dateParts = joinDateString.split("-");
                //   var day = parseInt(dateParts[0]);
                //   var month = parseInt(dateParts[1]) - 1; // Months are zero-based in JavaScript Date
                //   var year = parseInt(dateParts[2]);
                //   var mydate = new Date(year, month, day);
                //   setJoinDate(mydate);

                // }

                EmployeeService.getMembershipByuserIdOrEmailOrPhone(res.data.data["email"]).then((response) => {
                    //   if(response.data.code!=="UASE0003"){
                    setRoleId(response.data.data.membership[0].roleId);
                    //   }else{
                    // EmployeeService.getAllRoles().then((res) => {
                    //   if (res != undefined) {
                    //         const values = res.data.data.roles;
                    //         const filteredUsers = values.filter(user => ['Employee'].includes(user.name));
                    //         console.log(filteredUsers[0].id)
                    //         setRoleId(filteredUsers[0].id)
                    //   }
                    // });
                    //   }
                })
            });
        }
    }, []);

    const [tempRoleId, setTempRoleId] = useState("");

    const onClose = () => {
        setFlag(false);
        setId("")
    };

    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [message4, setMessage4] = useState("");
    const [message5, setMessage5] = useState("");
    const [message6, setMessage6] = useState("");
    const [message7, setMessage7] = useState("");
    const [message8, setMessage8] = useState("");
    const [message9, setMessage9] = useState("");



    const employeeEmailHandler = (event) => {
        const values = event.target.value;
        setEmail(values)
        if (values.trim() === '') {
            setMessage4("Email is  required");
        }
        else if (!/^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(values)) {
            setMessage4("Email is Invalid!");
        } else {
            setMessage4("");
        }
    }

    const employeeLocationHandler = (event) => {
        setSiteId(event.target.value);
    }

    const employeeTypeHandler = (event) => {
        const values = event.target.value;
        console.log(values)
        setRoleId(values)
        if (values === '') {
            setMessage8('Code is required');
        } else {
            setMessage8('');
        }
    }

    const handleCountryChange = (event) => {
        const values = event.target.value
        setCountry(values);
        console.log(country);
        if (values === '') {
            setMessage9('Country is required');
        } else {
            setMessage9('');
        }
    };

    let navigate = useNavigate();

    const skipBtnHandler = () => {
        setFlag(false);
    };

    const handleEmployeeStatus = (event) => {
        const values = event.target.value;
        setStatus(values)
    }

    const employeeRoleHandler = (event) => {
        setRoleId(event.target.value);
    }

    const saveBtnHandler = (e) => {
        if (message1 == '' && message2 == '' && message3 == '' && message4 == '' && message5 == '' && message6 == '' && message7 == '' && message8 == '' && message9 == '' && firstName !== '' && lastName !== '' && password !== '' && confirmPassword !== '' && password === confirmPassword && roleId !== '' && phoneCountryCode !== '' && email!==''&&phone!==''
            && country !== ''
        ) {
            e.preventDefault();
            const user = {
                firstName,
                lastName,
                email,
                phoneCountryCode,
                country,
                phone,
                status,
                siteId,
                password,
                confirmPassword
            }

            const payload = ({
                user,
                roleId,
            })
            console.log(payload + "palyload")

            if (id) {
                setLoading(true)
                EmployeeService.updateEmployee(id, payload).then((res) => {
                    setLoading(false)
                    console.log(payload);
                    console.log(res);

                    if (res.data.code === "UASI0002") {
                        toast.success("User updated successfully..", {
                            position: "top-right",
                        });
                        // if(email!==undefined){
                        //   toast.success("Mail Sent Successfully to "+email, {
                        //     position: "top-right",
                        //   });
                        // }
                        setFlag(false);
                        loadData();
                    } else {
                        toast.error(res.data.message, {
                            position: "top-right",
                        });
                    }
                });
            } else {
                setLoading(true)
                EmployeeService.createEmployee(payload).then((res) => {
                    setLoading(false);
                    if (res.data.code === 'ZKCSAI0001') {
                        console.log(res)
                        toast.success("User created successfully..", {
                            position: "top-right"
                        });
                        if (email !== undefined || email !== "") {
                            toast.success("Mail Sent Successfully to " + email, {
                                position: "top-right",
                            });
                        }
                        setFirstName('')
                        setLastName('')
                        // setCode('')
                        setFlag(false)
                        loadData();

                    } else if (res.data.code === 'ZKCSAE0020') {
                        toast.error("User already exists", {
                            position: "top-right"
                        });
                    } else {
                        toast.error(res.data.message, {
                            position: "top-right"
                        });
                    }
                })
            }

        } else {
            if (firstName === '') {
                setLoading(false);
                setMessage1("Please Fill the given fields")
            }
            if (lastName === '') {
                setLoading(false);
                setMessage2("Please Fill the given fields")
            }
            if (password === '') {
                setLoading(false);
                setMessage3("Please Fill the given fields")
            }
            if (email === '') {
                setLoading(false);
                setMessage4("Please Fill the given fields")
            }
            if (phoneCountryCode === '') {
                setLoading(false);
                setMessage5("Please Select Country Code")
            }
            if (phone === '') {
                setLoading(false);
                setMessage6("Please Fill the given fields")
            }
            if (confirmPassword === '') {
                setLoading(false);
                setMessage7("Please Fill the given fields")
            }
            if (password != confirmPassword) {
                setLoading(false);
                setMessage7("Confirm Password must be same as Password")
            }
            if (roleId === '') {
                setLoading(false);
                setMessage8("Please Select User Type")
            }
            if (country === '') {
                setLoading(false);
                setMessage9("Please Select Country")
            }
        }
    }

    const firstNameHandler = (event) => {
        const values = event.target.value;
        const capitalizedValue3 = values.charAt(0).toUpperCase() + values.slice(1).toLowerCase();
        setFirstName(capitalizedValue3)
        if (values.trim() === '') {
            setMessage1('First Name is required');
        } else if (!/^[a-zA-Z]+$/.test(values)) {
            setMessage1('Special Character, Spaces and Numbers are not allowed');
        } else if (!/^.{1,23}$/g.test(values)) {
            setMessage1('Numbers of Characters allowed is 23');
        }
        else {
            setMessage1('')
        }

    }

    const passPattern = /^(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[!@#$%^&*])\S{8,24}$/;

    const passwordHandler = (event) => {
        const values = event.target.value;
        setPassword(values)
        if (values.trim() === '') {
            setMessage3("Password is required");
        }
        else if (!values.match(passPattern)) {
            setMessage3("Password is Invalid!");
        }
        else {
            setMessage3("");
        }

    }

    const confirmPasswordHandler = (event) => {
        const values = event.target.value;
        setComfimPassword(values)
        if (values.trim() === '') {
            setMessage7("Confirm Password is required");
        }
        else if (!values.match(password)) {
            setMessage7("Confirm Password must be same as Password");
        }
        else {
            setMessage7("");
        }

    }

    const lastNameHandler = (event) => {
        const values = event.target.value;
        const capitalizedValue = values.charAt(0).toUpperCase() + values.slice(1).toLowerCase();
        setLastName(capitalizedValue)
        if (values.trim() === '') {
            setMessage2('Last Name is required');
        } else if (!/^[a-zA-Z]+$/.test(values)) {
            setMessage2('Special Character, Spaces and Numbers are not allowed');
        } else if (!/^.{1,23}$/g.test(values)) {
            setMessage2('Numbers of Characters allowed is 23');
        }
        else {
            setMessage2('')
        }
    }

    const formatPhoneNumber = (value) => {
        const digitsOnly = value.replace(/\D/g, "");
        return digitsOnly;
    };

    const mobileHandler = (event) => {
        const values = event.target.value;
        const digitsOnly = formatPhoneNumber(values);
        setPhone(digitsOnly);
        if (values.trim() === '') {
            setMessage6('Phone is required');
        } else if (digitsOnly.length >= 19) {
            setMessage6("Phone length cannot be more than 18");
        } else if (digitsOnly.length < 8) {
            setMessage6("Phone length should be aleast 8");
        }
        else {
            setMessage6("");
        }

    }

    // const countryCodeHandler = (event) => {
    //     const values = event.target.value;
    //     const digitsOnly = formatPhoneNumber(values);
    //     setPhoneCountryCode(digitsOnly)
    //     if (values.trim() === '') {
    //         setMessage5('Code is required');
    //     } else if (digitsOnly.length >= 6) {
    //         setMessage5('Country Code cannot be more than 5')
    //     }
    //     else {
    //         setMessage5("");
    //     }
    // }

    const countryCodeHandler = (event) => {
        setPhoneCountryCode(event)
        // setPhoneCountryCode2(event)
      };

    const countryCodes = [
        { code: '+1', country: 'United States (+1)' },
        { code: '+44', country: 'United Kingdom (+44)' },
        { code: '+91', country: 'India (+91)' },
        // Add more countries and their corresponding country codes here
    ];

    // const countries = [
    //     {  country: 'United States' },
    //     {  country: 'United Kingdom' },
    //     {  country: 'India' },
    //     // Add more countries and their corresponding country codes here
    // ];

    const handleCodeChange = (event) => {
        const values = event.target.value
        setPhoneCountryCode(values);
        if (values === '') {
            setMessage5('Code is required');
        } else {
            setMessage5('');
        }
    };

    return (
        <>
            <CustomDialog open={flag} onClose={onClose} title={title} action={action}>
                <Grid>
                    <Grid container spacing={3} xs={12}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Typography className={classes.labels}>First Name<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={firstName} name="firstName" placeholder='Enter First Name' onChange={firstNameHandler} />
                            {message1 && <div className={classes.redColor}>{message1}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography className={classes.labels}>Last Name<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={lastName} name="lastName" placeholder='Enter Last Name' onChange={lastNameHandler} />
                            {message2 && <div className={classes.redColor}>{message2}</div>}
                        </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item style={{width:'100%'}}>
                      <Typography className={classes.labels}> Country Code <span className={classes.redAsterisk}>  *</span></Typography>
                        <PhoneInput
                          id="phone-inputCreate"
                          style={{
                            height: "40px",
                            display:'flex',
                            alignContent:'center',
                            alignItems: "center",
                            border:'1px solid rgba(20, 20, 20, 0.80)',
                            borderRadius:'2px',
                          }}
                          country={"id"}
                          value={phoneCountryCode}
                          onChange={countryCodeHandler}
                          enableSearch="true"
                          searchPlaceholder={"search"}
                          searchNotFound={"search"}
                          inputProps={{ maxLength: 18}}
                          name="phoneCountryCode"
                        />
                      </Grid>
                    </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography className={classes.labels}>Mobile<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={phone} name="mobile" placeholder='Enter Mobile No' onChange={mobileHandler} />
                            {message6 && <div className={classes.redColor}>{message6}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography className={classes.labels}>Email<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={email} placeholder='Enter Email' onChange={employeeEmailHandler} />
                            {message4 && <div className={classes.redColor}>{message4}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography align="left" className={classes.labels}>User Type<span className={classes.redAsterisk} >  *</span></Typography>
                            <TextField
                                fullWidth
                                select
                                label={roleId === "" ? "Select User Type" : ""}
                                value={roleId}
                                onChange={employeeTypeHandler}
                                InputLabelProps={{ shrink: false }}
                                variant="outlined"
                            >
                                {roles.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {message8 && <div className={classes.redColor}>{message8}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={classes.labels}> Country <span className={classes.redAsterisk}>  *</span></Typography>
                            {id === "" ? (
                                <TextField
                                    fullWidth
                                    select
                                    label={country === "" ? "Select Country" : ""}
                                    value={country}
                                    onChange={handleCountryChange}
                                    InputLabelProps={{ shrink: false }}
                                    variant="outlined"
                                >
                                    {countryList.map(country => (
                                        <MenuItem key={country.country} value={country.country}>
                                            {country.country}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : (<TextField
                                fullWidth
                                select
                                disabled
                                value={country}
                                InputLabelProps={{ shrink: false }}
                                variant="outlined"
                            >
                                {countryList.map(country => (
                                    <MenuItem key={country.country} value={country.country}>
                                        {country.country}
                                    </MenuItem>
                                ))}
                            </TextField>)}
                            {message9 && <div className={classes.redColor}>{message9}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Typography className={classes.labels}>Password<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={password} name="password" placeholder='Enter Password' onChange={passwordHandler} autoComplete='off' />
                            {message3 && <div className={classes.redColor}>{message3}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Typography className={classes.labels}>Confirm Password<span className={classes.redAsterisk}>  *</span></Typography>
                            <TextField fullWidth variant="outlined" value={confirmPassword} name="confirmPassword" placeholder='Enter Confirm Password' onChange={confirmPasswordHandler} autoComplete='off' />
                            {message7 && <div className={classes.redColor}>{message7}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            {loading ?
                                <>
                                    <Loader />
                                    <Button id="cancle-addUserLoading" variant="outlined" size='medium' sx={{ width: '100px' }} onClick={skipBtnHandler}>Cancel</Button>
                                    <Button id="submit-addUserLoading" variant="contained" size='medium' sx={{ width: '100px' }} onClick={saveBtnHandler}>Save</Button>
                                </>
                                :
                                <>
                                    <Button id="cancle-addUser" variant="outlined" size='medium' sx={{ width: '100px' }} onClick={skipBtnHandler}>Cancel</Button>
                                    <Button id="submit-addUser"  variant="contained" size='medium' sx={{ width: '100px' }} onClick={saveBtnHandler}>Save</Button>
                                </>
                            } </Grid>
                    </Grid>
                </Grid>
            </CustomDialog>
        </>
    )
}


export default EmployeeAdd
