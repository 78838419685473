import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import { Grid, IconButton } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Cancel } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '0.2px solid #FFFF',
    maxWidth: '235px',
    display: 'flex',
    boxShadow: "0px 3px 12px 0px rgb(223 233 237 / 95%)",
    borderRadius: '5px',
    marginLeft: '10px'
  },
  search_icon: {
    boxSizing: 'content-box ',
    backgroundColor: process.env.REACT_APP_BG_SEARCHBAR,
    // padding: '9px',

    borderRadius: '0px 8px 8px 0px',
    opacity: '60%',

    "&:hover": {
      background: process.env.REACT_APP_BG_SEARCHBAR,
      opacity: '1',
    },
  },
}));

export default function Search(props) {

  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('')
  const { t } = useTranslation();

  const handleTextChange = () => {
    props.onSearch(inputValue);
  }

  const handleChange = (event) => {
    const value = event.target.value
    setInputValue(value);
  }

  return (
    <Grid className={classes.container} >
      <InputBase style={{ paddingLeft: '10px' }}
        placeholder={t("LAN1")}
        onChange={handleChange}
        value={inputValue}
      />
      {inputValue.length ?
        <IconButton aria-label="menu" >
          <Cancel onClick={() => {
            setInputValue('')
            props.clearSearch()
          }
          } /></IconButton> : ''
      }
      <IconButton aria-label="menu" style={{ backgroundColor: '#e3e5f1', borderRadius: '3px' }}>
        <SearchIcon onClick={handleTextChange} className={classes.search_icon} /></IconButton>
    </Grid>
  );
}
