import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeIcon.svg";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_ORDER_TEMPLATE } from "../../../constants/ReactEndPoints";
import { getTemplateImages, getTemplatesByCategory } from "../../../services/AdvertService";

export default function PreviewTemplate() {

    const navigate = useNavigate();
    const [imageurl, setImageUrl] = useState("");
    const [imageId, setImageId] = useState('');
    const location = useLocation();
    const token = localStorage.getItem('USER_TOKEN');
    const [onClickImage, setOnClickImage] = useState(false);
    const [onClickImageName, setClickImageName] = useState(false);
    const [description, setDescription] = useState("");
    useEffect(() => {
        loadData()
    }, [])

    const handleCancel = () => {
        navigate("/HomeScreen");
    };

    const handleEdit = async (event) => {
        navigate(REACT_ORDER_TEMPLATE, { state: { imageId } });
    }

    const stateID = location?.state?.id ? location?.state?.id : '';
    const stateCategory = location?.state?.category ? location?.state?.category : '';
    const stateName = location?.state?.name ? location?.state?.name : '';

    useEffect(() => {
        getTemplateImages(stateID)
            .then((response) => {
                if (response !== undefined) {
                    if (response?.data?.code === "ZKCSAI0001") {
                        setImageId(stateID);
                        setImageUrl(response?.data?.data?.previewImage);
                        setDescription(response?.data?.data?.description)
                        setClickImageName(response?.data?.data?.templateName)
                        console.log(imageId + " IMAGE ID");
                        console.log(imageurl);
                    } else {
                        toast.error("something went wrong", {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error("something went wrong", {
                        position: "top-right",
                    });
                }
            });
    }, []);


    const [image, setImage] = useState("");
    const templatesImages = [image];
    const flattenedArray = templatesImages.flat();
    const country = localStorage.getItem("COUNTRY");
    const loadData = () => {
        getTemplatesByCategory(stateCategory, country).then(
            (res) => {
                if (res?.data?.code === "ZKCSAE0001") {
                    setImage(res?.data?.data)
                }
            }
        );
    }

     const handleOpen = (imageUrl, id, category) => {
        setImageId(id);
        setOnClickImage(imageUrl)
    };

    const handleOpenName = (item) => {
        setClickImageName(item)
    }

    const handleOpenDescription = (item) => {
        setDescription(item)
    }


    const actions = () => (
        <Box item style={{ display: "inherit" }}>
            <Box display="flex" width={'100%'} alignItems="center" justifyContent={"flex-end"} gap={'10px'}>
                <Button id="previewTemp-cancleButton" sx={{ width: '100px' }} variant="outlined" color="primary" size="medium" onClick={handleCancel}  >Cancel</Button>
                <Button id="previewTemp-editButton" sx={{ width: '100px' }} variant="contained" color="primary" size="medium" onClick={handleEdit}>Edit</Button>
            </Box>
        </Box>
    );

    const [gridVisible, setGridVisible] = useState(true);
    const [gridHide, setGridHide] = useState(false);
    const handleToggleGrid = () => {
        setGridVisible(!gridVisible); // Toggle grid visibility
    };


    return (
        <Grid >
            <RightViewLayout
                title={"Template"}
                navigateBack={'/HomeScreen'}
                actions={actions}
            >
                <Grid container spacing={2}>
                    <Box width="100%" display="flex" >
                        {!onClickImageName ?
                            <Typography ml={'15px'} variant="subtitle2">{stateName}</Typography> :
                            <Typography ml={'15px'} variant="subtitle2">{onClickImageName}</Typography>
                        }
                    </Box>
                    <Grid item xs={6} sm={8} display="flex" flexDirection="column" >
                        <div class="image-container">
                            {!onClickImage ?
                                <img src={imageurl} alt="Preview" draggable="false" /> :
                                <img src={onClickImage} alt="Preview_Image" draggable="false" />
                            }
                        </div>
                        <Typography className="viewTypography1" style={{ marginLeft: '-27px' }}>{description}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={3.5} display="flex" flexDirection="column" alignContent={"flex-end"} height={'76vh'}>
                        {!gridVisible &&
                            <Button id="tempPre-button" onClick={handleToggleGrid}>
                                <Typography>Related Templates</Typography>
                            </Button>}

                        {gridVisible &&
                            <Box sx={{
                                overflow: "hidden",
                                overflowY: "scroll",
                                boxShadow: '#8c929638 0px 4px 9px 5px',
                                borderRadius: '5px',
                                // Hide scrollbar
                                scrollbarWidth: 'none', // Firefox
                                '&::-webkit-scrollbar': {
                                    width: '0.4em', // Chrome and Safari
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            >
                                <Grid display="flex">
                                    <Grid style={{ flexGrow: "1" }}>
                                        <Typography sx={{ pl: 2, pt: 3, fontSize: '17px', fontWeight: '500' }}>Related Templates</Typography>
                                    </Grid>
                                    <Grid sx={{ pt: 2.5, cursor: 'pointer' }}>
                                        <IconButton id="iconButton-preTemp" onClick={handleToggleGrid}>
                                            <CloseIcon style={{ fontSize: '1em' }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                {flattenedArray.map((item, index) => (
                                    <Box sx={{ padding: '10px 10px', justifyContent: 'center', display: 'grid' }}>
                                        <Typography variant="subtitle2" p={1}>{item?.name}</Typography>
                                        <img src={item?.imageUrl} alt={item?.name} loading="lazy" draggable="false"
                                            // onClick={() => handleOpen(item.imageUrl)}
                                            onClick={() => {
                                                handleOpen(item?.previewImage, item?.id, item?.category);
                                                handleOpenName(item?.name);
                                                handleOpenDescription(item?.description);
                                            }}
                                            style={{
                                                display: 'block',
                                                width: '250px',
                                                height: 'auto',
                                                borderRadius: '5px'
                                            }} />
                                    </Box>
                                ))}
                            </Box>

                        }
                    </Grid>
                </Grid>
            </RightViewLayout>
            
        </Grid>
    );
}
