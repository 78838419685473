import axios from 'axios';
import api from "../configurations/http-common";

const header = {
    headers: {
        "Content-Type": "multipart/form-data",
    }
};

export const createBanner = async (file, imageName) => {
    return axios.post(process.env.REACT_APP_SERVER_URL + "/web/banner", file, imageName, header);
};

export const getBannerList = async () => {
    return api.securedAxios().get("/web/banner/getBannersList");
};

export const deleteBanner = async (id) => {
    return api.securedAxios().delete("/web/banner/deleteBanner/" + id);
};

const BannerService = {
    getBannerList,
    createBanner,
    deleteBanner
}

export default BannerService;
