import axios from 'axios';
import api from "../configurations/http-common";

const header = {
    headers: {
        "Content-Type": "multipart/form-data",
    }
};

export const createOrder = async (formData) => {
    return api.securedAxios().post("/web/order",formData,header);
};

export const getCustomerOrders = async (pageNumber, pageSize, request, country) => {
  
   
        return api.securedAxios().get("/web/order/getCustomerOrders/"+country+"?"+
        "pageNumber=" +
        pageNumber +
        "&pageSize=" +
        pageSize +
         request);
    
};

export const getMyOrders = async (pageNumber, pageSize, userId) => {
    return api.securedAxios().get("/web/order/getMyOrder/" + userId + "?" +
        "pageNumber=" +
        pageNumber +
        "&pageSize=" +
        pageSize,
    );
};

export const getOrderDetails = async (id, token) => {
    return api.securedAxios().get("/web/order/getOrderDetails/" + id + "/" + token);
};

export const updateOrderStatus = async (id, status) => {
    return api.securedAxios().put(process.env.REACT_APP_SERVER_URL + "/web/order/update/" + id + "/" + status);
};

export const rejectOrder = async (id, status, rejectionReason) => {
    return api.securedAxios().put("/web/order/update/" + id + "/" + status + "/" + rejectionReason);
};

export const getMyWork = async (pageNumber, pageSize, userId, request) => {
    return api.securedAxios().get("/web/order/getMyWork/" + userId + "?" + "pageNumber=" +
        pageNumber +
        "&pageSize=" +
        pageSize +
        request
    );
};

export const uploadCustomerOrder = async (id, file) => {
    return api.securedAxios().post("/web/order/uploadCustomerOrder/" + id, file, header);
};

export const getCustomerTemplate = async (id) => {
    return api.securedAxios().get("/web/order/getCustomerTemplate/" + id);
}

export const getAssertKey = async (id) => {
    return api.securedAxios().get("web/order/getAssertKey/" + id);
}

export const publishCustomerTemplate = async (id) => {
    return api.securedAxios().get(process.env.REACT_APP_SERVER_URL + "web/order/publishCustomerTemplate/" + id);
}

export const mostlyOrderedTemplate = async () => {
    return api.securedAxios().get("/web/order/mostOrderd");
  };

const OrderService = {
    createOrder,
    getCustomerOrders,
    getMyOrders,
    getOrderDetails,
    updateOrderStatus,
    getMyWork,
    rejectOrder,
    uploadCustomerOrder,
    getCustomerTemplate,
    getAssertKey,
    publishCustomerTemplate,
    mostlyOrderedTemplate
}

export default OrderService;
