import { makeStyles } from "@material-ui/core/styles";
import { DialogActions, Grid, IconButton, Paper, Typography, createTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";
import closeIcon from "../../assets/images/close icon.svg";
import { t } from "i18next";


const useStyles = makeStyles({
  gridContainer: {},
  gridItem: {},
  left_img: {
    width: "100%",
    margin: "auto",
    display: "block",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
});


export default function CutomOuterlayoutLogin(props) {
  const {
    buttonName,
    handldeButton,
    stepsValue,
    headerTitle,
    handleLoginClose,
    handleCreateAccount,
    handleForgotPass
  } = props;
  const steps = stepsValue;
  const classes = useStyles();
  const defaultTheme = createTheme();

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose1 = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <Grid container >
        <Grid item xs={false} sm={4} md={5} className="login-image" />
        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} width={'850px'}>
          <Box sx={{ my: 7, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
            <DialogActions style={{ position: 'absolute', top: '7px', right: '7px', }}>
              <IconButton onClick={props.onClose}>
                <img alt="closeIcon" src={closeIcon} ></img>
              </IconButton>
            </DialogActions>

            <Typography font="normal normal medium 28px/34px Roboto" sx={{ pb: '30px', textAlign: 'left !important', m: 1 }} fontSize={"20px"} color={"#474B4F"}>
              <strong>{headerTitle}</strong>
            </Typography>
            <Box component="form" noValidate >
              {props.children}
              <Grid container>
                <Grid item flexGrow={1} ml={-2}>
                  <Button style={{ font: "normal normal normal 12px/19px Roboto", color: "#474B4F", opacity: "0.8" }}>
                    <input type="checkbox" />&nbsp; {t("LOGIN016")}
                  </Button>
                </Grid>
                <Grid item mr={-3}>
                  <Button onClick={handleForgotPass} style={{ color: "#BBBBBB", }} >
                    <Typography style={{ font: "normal normal normal 12px/19px Roboto", color: "#474B4F", opacity: "0.8" }}>
                    {t("LOGIN017")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, marginLeft: '28px', marginRight: '27px' }}
                onClick={handldeButton}
              >
                {buttonName}
              </Button>
            </Grid>
            <Grid container justifyContent='center'>
              <Typography variant="body2" align="center" sx={{ font: "normal normal normal 12px/19px Roboto", color: "#474B4F", opacity: "0.8" }}>
             { t("LOGIN018")}{' '}
                <Button onClick={handleCreateAccount} sx={{ padding: '0px!important' }} style={{ font: "normal normal normal 12px/19px Roboto", opacity: "0.8", color: '#1487CA', marginLeft: '0px' }}>
            {    t("LOGIN019")}
                </Button>
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
