import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import sidebarContext from '../../util/sidebarContext';
import RefreshToken from "../../pages/onboard/RefreshToken";
import { REACT_LANDING } from "../../constants/ReactEndPoints";
import { useNavigate } from "react-router-dom";


export default function MainLayout(props) {
  const [mode, setMode] = useState(false);
  const [tokenRefreshTimer, setTokenRefreshTimer] = useState("");
  const navigate = useNavigate();

  
  useEffect(() => {
    const token = localStorage.getItem("USER_TOKEN");
    if (token === null) {
      navigate(REACT_LANDING);
    }
  }, [])

  const value = {
    tokenRefreshTimer,
    setTokenRefreshTimer,
  }
  return (
    <sidebarContext.Provider value={value}
    // value={{ mode, setMode, value }}
    >
      <Grid
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          // backgroundColor: "#f7fbfe",
        }}
        className="gridContainerStyle2"
      >
        <RefreshToken />
        <Grid style={{ height: "100%", display: "flex", flexDirection: "row", }}>
          <SideBar />
          <Grid
            container
            // mx={2}
            style={{
              height: "100%",
              overflowX: "hidden",
              display: "block",
              width: mode ? "-webkit-fill-available" : "100%",
              // width: "fit-content"
            }}
          >
            <Box my={0} mx={0} height={"95%"} fullWidth>
              {/* <Header pageTitle={pageTitle} /> */}
              {/* <Box pl={2} pt={4} pb={1}>
                <Breadcrumbs />
              </Box> */}
              <Box pl={2} pr={2} pt={1} pb={1}>
                {" "}
                {props.children}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </sidebarContext.Provider>
  );
}
