import { Masonry } from "@mui/lab";
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllDefultTags, getTemplatesByCategory } from "../../services/AdvertService";
import { REACT_INDUSTRIES_LAYOUT, REACT_PREVIEW_IMAGE } from "../../constants/ReactEndPoints";
import goverment from "../../assets/industries/Group 10636.svg";
import fb from "../../assets/industries/Union 367.svg";
import retail from "../../assets/industries/Union 368.svg";
import fashion from "../../assets/industries/Union 369.svg";
import beauty from "../../assets/industries/Union 370.svg";
import finance from "../../assets/industries/Union 371.svg";
import automative from "../../assets/industries/Union 373.svg";
import health from "../../assets/industries/Union 374.svg";
import hospitality from "../../assets/industries/Union 376.svg";
import realestate from "../../assets/industries/Union 378.svg";
import telecomunication from "../../assets/industries/Union 379.svg";
import tech from "../../assets/industries/Union 380.svg";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../Loader";
import { ReactComponent as PremiumIcon } from "../../assets/industries/premiumIcon.svg";
import { t } from "i18next";
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from "@material-ui/core";
import { Carousel } from "@trendyol-js/react-carousel";


export default function IndustriesLayout() {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
        loadAllTag();
    }, [])
    const location = useLocation();
    const navigate = useNavigate();
    const [image, setImage] = useState("");
    const editCustomer = localStorage.getItem("ROLE_NAME") === "CUSTOMER";



    const useStyles = makeStyles((theme) => ({
        customClass: {
            '& .MuiDialog-paper': {
                backgroundColor: 'transparent',
            },
        },
        btnStyle: {
            color: '#fff',
            cursor: 'pointer'
        }
    }));
    const classes = useStyles();

    const templatesImages = [image];

    const flattenedArray = templatesImages.flat();
    console.log("flattenedArray ", flattenedArray)

    const categories = [
        { name: t("LAN3"), imageUrl: goverment },
        { name: t("LAN4"), imageUrl: fb },
        { name: t("LAN5"), imageUrl: retail },
        { name: t("LAN6"), imageUrl: fashion },
        { name: t("LAN7"), imageUrl: beauty },
        { name: t("LAN8"), imageUrl: finance },
        { name: t("LAN9"), imageUrl: automative },
        { name: t("LAN10"), imageUrl: health },
        { name: t("LAN11"), imageUrl: hospitality },
        { name: t("LAN12"), imageUrl: realestate },
        { name: t("LAN13"), imageUrl: telecomunication },
        { name: t("LAN14"), imageUrl: tech },
    ];

    
    var category = location.state?.categoryName;

    const [selectedCategory, setSelectedCategory] = useState(null);
    const loadData = () => {
        setLoading(true);
        const country = localStorage.getItem("COUNTRY");
        getTemplatesByCategory(category, country).then(
            (res) => {
                if (res.data.code === "ZKCSAE0001") {
                    if (lang !== 'en') {
                        if (category === 'Government') {
                            category = t("Government");
                        } else if (category === 'F&B') {
                            category = t('F&B');
                        } else if (category === 'Retail') {
                            category = t('Retail');
                        } else if (category === 'Fashion') {
                            category = t('Fashion');
                        } else if (category === 'Beauty') {
                            category = t('Beauty');
                        } else if (category === 'Finance') {
                            category =t('Finance');
                        } else if (category === 'Automotive') {
                            category = t('Automotive');
                        } else if (category === 'Health') {
                            category = t('Health');
                        } else if (category === 'Hospitality') {
                            category = t('Hospitality');
                        } else if (category === 'Real Estate') {
                            category = t('Real Estate');
                        } else if (category === 'Telecommunication') {
                            category = t('Telecommunication');
                        } else if (category === 'Tech') {
                            category = t('Tech');
                        }
                    }
                    setSelectedCategory(category);
                    setImage(res.data.data);
                    setLoading(false);
                    if (res.data.code === "ZKCSAE0001") {
                    } else {
                    }
                }
            }
        );
    }
    const [open, setOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState("");

    const [selectedItem, setSelectedItem] = useState("");

    const handleOpen = (state, imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setSelectedItem(state)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEdit = () => {
        const state = selectedItem;
        navigate(REACT_PREVIEW_IMAGE, { state });
        setSelectedImageUrl(selectedImageUrl);
        setOpen(true);
    };

    const lang = localStorage.getItem("i18nextLng");

    const handleSearchClick = (categoryName) => {
        if (lang !== 'en') {
            if (categoryName ===  t("Government")) {
                categoryName = 'Government';
            } else if (categoryName === 'makanan dan minuman') {
                categoryName = 'F&B';
            } else if (categoryName === 'Pengecer') {
                categoryName = 'Retail';
            } else if (categoryName === 'Mode') {
                categoryName = 'Fashion';
            } else if (categoryName === 'Kecantikan') {
                categoryName = 'Beauty';
            } else if (categoryName === 'Keuangan') {
                categoryName = 'Finance';
            } else if (categoryName === 'Otomotif') {
                categoryName = 'Automotive';
            } else if (categoryName === 'Kesehatan') {
                categoryName = 'Health';
            } else if (categoryName === 'Keramahan') {
                categoryName = 'Hospitality';
            } else if (categoryName === 'Perumahan') {
                categoryName = 'Real Estate';
            } else if (categoryName === 'Telekomunikasi') {
                categoryName = 'Telecommunication';
            } else if (categoryName === 'Teknologi') {
                categoryName = 'Tech';
            }
        }
        category = categoryName;
        setSelectedCategory(categoryName);
        loadData();
    };


    const [allTag, setAllTag] = useState([])

    const loadAllTag = () => {
        getAllDefultTags().then((res) => {
            if (res?.data?.code === "ZKCSAI0001") {
                setAllTag(res?.data?.data.tagsList);
            }
        });
    }


    return (
        <Grid container width="100%" margin={"auto"} overflow={"hidden"} >
            <Typography sx={{ color: '#474B4F', opacity: '1', font: 'normal normal 600 18px/29px Roboto', mb: '10px' }}> </Typography>
            <Grid width="100%" margin="auto" paddingTop={'50px'}>
                <Grid style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginBottom:'10px' }}>
                    <Carousel dynamic={true} leftArrow={false} 
                        show={12}
                        slide={3}
                        swiping={true}
                        pageCount={5}
                    >
                        {allTag.map((item) => (
                            <Grid key={item.id}
                                onClick={() => handleSearchClick(item.tag)}
                                style={{ opacity: "1", cursor: "pointer", textAlign: "center", alignContent: "center", padding: "0px 2px", }}
                            >
                                <Typography variant="body2" className="categoryStyle">
                                    <Tooltip title={item.tag.length > 10 ? t(item.tag) : ''}>
                                        <span>
                                            {t(item.tag.substring(0, 11))}
                                        </span>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
            <Typography sx={{ color: '#474B4F', opacity: '1', font: 'normal normal 600 18px/29px Roboto', mb: '10px' }}>{selectedCategory} </Typography>
            {loading ? <Loader /> :
                <Masonry columns={4} spacing={2.5} style={{ marginTop: '10px' }}>
                    {flattenedArray.map((item, index) => (
                        <Grid onClick={() => handleOpen(item, item?.previewImage)}
                            style={{
                                cursor: "pointer", textAlign: "center",
                                width: "auto", height: 'auto',
                                opacity: "1"
                            }}>
                            {item?.advertType === 'premium' ?
                                (
                                    <div class="Imagecontainer">
                                        <div class="overlay1" >
                                            <PremiumIcon
                                                width={"30px"}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                        <img draggable="false" src={item?.previewImage} alt={item?.name} loading="lazy"

                                            style={{
                                                display: 'block',
                                                width: '250px',
                                                height: 'auto',
                                                borderRadius: '5px',
                                                transition: 'all 1s',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <img src={item?.previewImage} alt={item?.name} loading="lazy"
                                        draggable="false"
                                        style={{
                                            display: 'block',
                                            width: '250px',
                                            height: 'auto',
                                            borderRadius: '5px',
                                            transition: 'all 1s',
                                        }}
                                        className="IndustriesImage"
                                    />)}
                        </Grid>
                    ))}
                </Masonry>
            }

            <Dialog open={open} onClose={handleClose} maxWidth="md" className={classes.customClass} style={{ backdropFilter: 'blur(5px)' }} >
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: '#fff' }}>Image Preview</Typography>
                        <div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            {editCustomer &&
                                <EditIcon onClick={handleEdit} style={{ cursor: "pointer", marginRight: '15px', color: '#fff' }} />
                            }
                            <CloseIcon onClick={handleClose} className={classes.btnStyle} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className="container-image-new">
                    <img src={selectedImageUrl} alt="Selected Template" draggable="false" />
                </DialogContent>
            </Dialog>
        </Grid>
    );
}
