import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./configurations/i18n";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "typeface-roboto";

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: "#1487CA",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
          color: "#304669",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: "none",
        },
        sizeLarge: {
          width: "180px",
          height: "46px",
          borderRadius: "8px",
        },
        sizeMedium: {
          font: "normal normal normal 14px/22px Roboto",
          textTransform: "none",
          padding: "8px 16px"
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: "#304669",
    },
    menuItem:{
      color: "#A2A3A3",
      letterSpacing: "0px",
      font: "normal normal normal 14px/19px Roboto",
      opacity: "1"
    },
    title: {
      color: "#304669",
      letterSpacing: "0.4px",
      font: "normal normal normal 20px/29px Roboto",
      opacity: "1",
      fontWeight: 500,
    },
    subtitle3: {
      color: "#304669",
      letterSpacing: "0px",
      font: "normal normal normal 16px/19px Roboto",
      opacity: "1",
      fontWeight: "400",
    },
    link: {
      color: "#304669",
      letterSpacing: "0.4px",
      font: "normal normal normal 16px/19px Roboto",
      opacity: "1",
      fontWeight: "400",
    },
    subtitle4: {
      color: "#304669",
      letterSpacing: "0.4px",
      font: "normal normal normal 16px/19px Roboto",
      fontWeight: "400",
      opacity: "0.8",
    },
    label: {
      color: "#304669",
      letterSpacing: "0",
      font: "normal normal normal 14px/26px Roboto",
      fontWeight: "400",
      opacity: "0.5",
    },
    value: {
      color: "#304669",
      letterSpacing: "0",
      font: "normal normal normal 14px/26px Roboto",
      fontWeight: "400",
      opacity: "1",
    },
    fieldLabel: {
      color: "#304669",
      letterSpacing: "0.9px",
      font: "normal normal normal 14px/22px Roboto",
      fontWeight: "500",
      opacity: "0.5",
    },
    fieldValue: {
      color: "#304669",
      letterSpacing: "0.9px",
      font: "normal normal normal 14px/22px Roboto",
      fontWeight: "500",
      opacity: "1",
    },
    dropdownLabel: {
      color: "#304669",
      letterSpacing: "0px",
      font: "normal normal normal 12px/20px Roboto",
      opacity: "1",
      fontWeight: "500",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={MuiTheme}>
      <I18nextProvider i18n={i18n}>
        <App props={i18n} />
      </I18nextProvider>
    </ThemeProvider>
);



