import axios from 'axios';
import CommonUtil from '../util/CommonUtils';

const axiosHelper = (headers) => {
  let req = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: headers,
  });

  req.interceptors.request.use(function (request) {
    localStorage.setItem('isLoading', true);
    window.dispatchEvent(new Event('storage'));
    return request;
  });

  req.interceptors.response.use(
    function (response) {
      localStorage.setItem('isLoading', false);
      window.dispatchEvent(new Event('storage'));
      return response;
    },
    function (error) {
      if (error.code === "ERR_NETWORK") {
        if (error.message === "Network Error") {
          setTimeout(() => {
            localStorage.setItem("isLoading", false);
            window.dispatchEvent(new Event("storage"));
          }, 1000);
          // toast.error("something went wrong", {
          //   position: "top-right",
          // });
          window.location = "/";
          return;
        }
      }
      if (
        error.code === "ERR_BAD_RESPONSE" &&
        window.location.pathname !== "/"
      ) {
        setTimeout(() => {
          localStorage.setItem("isLoading", false);
          window.dispatchEvent(new Event("storage"));
        }, 1000);
        window.location = "/";
        return;
      }
      if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
        setTimeout(() => {
          localStorage.setItem("isLoading", false);
          window.dispatchEvent(new Event("storage"));
        }, 1000);
        if (error.message === "Network Error") {
          window.location = "/";
          return;
        }
      }
      if (401 === error.response.status && window.location.pathname !== '/') {
        CommonUtil.setCookie('auto_login', '', 0);
        window.location = '/';
      }
    }
  );

  return req;
};

const securedAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
    Authorization: localStorage.getItem('USER_TOKEN')
  });
};

const unsecuredAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
  });
};

const api = {
  axiosHelper,
  securedAxios,
  unsecuredAxios,
};

export default api;
