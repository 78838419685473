import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import TitleBar from "../components/TitleBar";


export default function RightViewLayout(props) {

  return (
    <Box pt={4} width="100%">
      <TitleBar
        navigateBack={props.navigateBack}
        title={props.title ? props.title : ""}
        actions={props.actions}
      />
      <Grid
        fullwidth
        container
        component={Paper}
        elevation={0}
        variant="elevation"
        style={{
          // background: '#F7FBFE',
          minHeight: props.type !== 'table' ? '75vh' : 'none',
          // overflowY: "auto",
          height: "fit-content",
          marginBottom: props.type !== 'table' ? "60px" : 'none',
          backgroundColor: props.type !== 'table' ? "transparent" : 'transparent',
        }}
        p={1}
      >
        <Box width={'100%'}>
          {props.children}
        </Box>
      </Grid>
    </Box>
  );
}
