import { InputLabel } from "@mui/material";

const DeatilsTextFieldTitle = (props) => {

  return (
    <>
      <InputLabel shrink htmlFor='bootstrap-input' sx={{fontSize:'14px !important'}} >
        {props.titleValue} {props.required && <span style={{ color: 'red' }}>*</span>}
      </InputLabel>
    </>
  );
};

export default DeatilsTextFieldTitle;
