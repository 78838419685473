import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as Facebook } from "../../assets/images/facebook (3).svg"
import { ReactComponent as Instagram } from "../../assets/images/instagram (1).svg"
import { ReactComponent as Twitter } from "../../assets/images/twitter (3).svg"
import { ReactComponent as Tiktok } from "../../assets/images/Tik Tok.svg"


import { useTranslation } from 'react-i18next';

/* const MinervaIoTImg = styled('img')({
  height: ' 8px',
}); */
const currentYear = new Date().getFullYear();

export default function Footer(props) {
  const { t } = useTranslation();
  return (
    

    <Grid style={{display:'flex',justifyContent:'space-between',background: "#242424 0% 0% no-repeat padding-box",padding:'20px',width:'100%'}}>
      <Grid ml={'70px'}>
        <Typography variant='label' style={{ margin: "auto", color: "#FFFFFF" }}> &copy; Copyright {currentYear} | ZKDIGIMAX | All rights reserved </Typography>
      </Grid>
      <Grid style={{ width:'165px'}}>
        <Tiktok style={{marginRight:'15px',marginLeft:'30px'}}/>
        <Instagram style={{marginRight:'15px'}}/>
        <Facebook style={{marginRight:'15px'}}/>
        <Twitter />
      </Grid>
    </Grid>
  );
}
