import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { toast } from "react-toastify";
import RightViewLayout from "../../../components/RighViewLayout";
import UserService from "../../../services/UserService";
import useStyles from '../../onboard/Styles';
import MenuItem from "@mui/material/MenuItem"
import CustomerService from "../../../services/CustomerService";
import { t } from "i18next";
import 'react-phone-input-2/lib/style.css'
import { countryList } from "../../../util/CountryList"; 
import PhoneInput from "react-phone-input-2";


export default function EditProfileDetails() {

    const classes = useStyles();
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneCountryCode, setPhoneCountryCode] = useState("")
    const [phone, setPhone] = useState("")
    const [created, setCreated] = useState("");
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [message4, setMessage4] = useState("");
    const [message5, setMessage5] = useState("");

    const countryCodes = [
        { code: '+1', country: 'United States (+1)' },
        { code: '+44', country: 'United Kingdom (+44)' },
        { code: '+91', country: 'India (+91)' },
        // Add more countries and their corresponding country codes here
    ];
    

    const userId = localStorage.getItem('USER_NAME');
    const userRole = localStorage.getItem('ROLE_NAME');

    const handleCancel = () => {
        navigate("/HomeScreen");
    };

    const firstNameHandler = (event) => {
        const values = event.target.value;
        const capitalizedValue3 = values.charAt(0).toUpperCase() + values.slice(1).toLowerCase();
        setFirstName(capitalizedValue3);
        if (values.trim() === '') {
            setMessage1(t('REG25'));
        } else if (!/^[a-zA-Z]+$/.test(values)) {
            setMessage1(t('REG46'));
        } else if (!/^.{1,23}$/g.test(values)) {
            setMessage1(t('REG47'));
        } else {
            setMessage1('');
        }
    };
    const lastNameHandler = (event) => {
        const values = event.target.value;
        const capitalizedValue = values.charAt(0).toUpperCase() + values.slice(1).toLowerCase();
        setLastName(capitalizedValue);
        if (values.trim() === '') {
            setMessage2(t('REG28'));
        } else if (!/^[a-zA-Z]+$/.test(values)) {
            setMessage2(t('REG46'));
        } else if (!/^.{1,23}$/g.test(values)) {
            setMessage2(t('REG47'));
        } else {
            setMessage2('');
        }
    };

    const emailHandler = (event) => {
        const values = event.target.value;
        setEmail(values);
        if (values.trim() === '') {
            setMessage3(t('PRO18'));
        } else if (!/^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(values)) {
            setMessage3(t('PRO28'));
        } else {
            setMessage3('');
        }
    };

    const handleCodeChange = (event) => {
        const values = event.target.value
        setPhoneCountryCode(values);
        if (values === '') {
            setMessage4(t('LVL0007'));
        } else {
            setMessage4('');
        }
    };

    const formatPhoneNumber = (value) => {
        const digitsOnly = value.replace(/\D/g, "");
        return digitsOnly;
    };

    const mobileHandler = (event) => {
        const values = event.target.value;
        const digitsOnly = formatPhoneNumber(values);
        setPhone(digitsOnly);
        if (values.trim() === '') {
            setMessage5(t('REG30'));
        } else if (digitsOnly.length >= 19) {
            setMessage5(t("REG31"));
        } else if (digitsOnly.length < 8) {
            setMessage5(t("REG32"));
        }
        else {
            setMessage5("");
        }

    }

    const countryCodeHandler = (event) => {
        setPhoneCountryCode(event)
        // setPhoneCountryCode2(event)
      };

    useEffect(() => {
        UserService.getUser(userId).then((response) => {
            debugger
            if (response !== undefined) {
                if (response.data.code === "UASI0033") {
                    setFirstName(response.data.data.firstName)
                    setLastName(response.data.data.lastName)
                    setEmail(response.data.data.email)
                    setCreated(response.data.data.createdAt)
                    if(userRole!=='OWNER'){
                        const formatPhone = response.data.data["phone"]
                const formattedPhoneNum = formatPhone.split('-');
                const formattedCountryCode = formattedPhoneNum[0];
                const formattedPhone = formattedPhoneNum[1];
                setPhoneCountryCode(formattedCountryCode)
                setPhone(formattedPhone)
                    }
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            }
        });
    }, []);

    const handleSubmit = (e) => {
        debugger
        // navigate('/HomeScreen')
        if (message1 == '' && message2 == '' && message3 == '' && message4 == '' && message5 == ''  && firstName !== '' && lastName !== ''    && phoneCountryCode !== '' && email!=='' && phone!==''){
            e.preventDefault();

            const payload = {
                firstName,
                lastName,
                email,
                phoneCountryCode,
                phone
            }
            console.log(payload + "palyload")

            debugger
            CustomerService.updateCustomer(userId, payload).then((res) => {

                if (res.data.code === "UASI0002") {
                    if(email===userId){
                        debugger
                        navigate("/ProfileDetails");
                        toast.success(t("PRO19"), {
                            position: "top-right",
                        });
                    }else{
                        debugger
                        UserService.logout().then((data) => {
                            if (data.data.code === "ZKCSAI0001") {
                                navigate("/");
                              localStorage.removeItem('companyCode')
                              localStorage.removeItem('companyName')
                              localStorage.removeItem('USER_TOKEN')
                              localStorage.removeItem('ROLE_NAME')
                              localStorage.removeItem('USER_NAME')
                              localStorage.removeItem('FIRST_NAME')
                              localStorage.removeItem('REFRESH_TOKEN')
                              localStorage.removeItem('companyId')
                              localStorage.removeItem('COUNTRY')
                              toast.success(t("PRO20"), {
                                position: "top-right",
                            });
                            }
                          });
                    }
                } else {
                    toast.error(res.data.message, {
                        position: "top-right",
                    });
                }
            });

        }
       
    }


    return (
        <Grid >
            <RightViewLayout
                title={t("PRO9")}
                navigateBack={'/HomeScreen'}
            >
                <Grid container xs={12}  display="flex" flexDirection="row"
                    style={{ backgroundColor: '#fff', border: '0.5px solid #eeeeee', paddingBottom: '10px', borderRadius: '5px' }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Typography className="viewTypography"> {t("PRO2")}  </Typography>
                        <TextField fullWidth variant="outlined"
                            value={firstName}
                            name="firstName"
                            placeholder={t('REG03')}
                            onChange={firstNameHandler}
                            style={{ paddingTop: "10px", paddingLeft: '27px' }} />
                        {message1 && <div className={classes.redColor1}>{message1}</div>}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Typography className="viewTypography"> {t("PRO3")} </Typography>
                        <TextField fullWidth variant="outlined"
                            value={lastName}
                            name="lastName"
                            placeholder={t('REG06')}
                            onChange={lastNameHandler}
                            style={{ paddingTop: "10px", paddingLeft: '27px', paddingRight: '27px'  }} />
                        {message2 && <div className={classes.redColor1}>{message2}</div>}
                    </Grid>


                    <Grid item xs={12} sm={12}  >
                        <Typography className="viewTypography">{t("PRO4")}  </Typography>
                        <TextField fullWidth variant="outlined"
                            value={email}
                            name="email"
                            placeholder={t('REG12')}
                            onChange={emailHandler}
                            style={{ paddingTop: "10px", paddingLeft: '27px', paddingRight: '27px' }} />
                        {message3 && <div className={classes.redColor1}>{message3}</div>}
                    </Grid>

                    {userRole !== "OWNER"  ? (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                       <Grid item style={{paddingTop:'30px',marginLeft:'25px'}}>
                      <Typography className={classes.labels}> Country Code: </Typography>
                        <PhoneInput
                          id="phone-inputEdit"
                          style={{
                            height: "40px",
                            display:'flex',
                            alignContent:'center',
                            alignItems: "center",
                            border:'1px solid rgba(20, 20, 20, 0.80)',
                            borderRadius:'2px',
                          }}
                          country={"id"}
                          value={phoneCountryCode}
                          onChange={countryCodeHandler}
                          enableSearch="true"
                          searchPlaceholder={"search"}
                          searchNotFound={"search"}
                          inputProps={{ maxLength: 18}}
                          name="phoneCountryCode"
                        />
                      </Grid>
                        {message4 && <div className={classes.redColor1}>{message4}</div>}
                    </Grid>
                    ) : (null)}

                {userRole !== "OWNER"  ? (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Typography className="viewTypography">{t("PRO8")}   </Typography>
                        <TextField fullWidth variant="outlined"
                            value={phone}
                            name="Mobile"
                            placeholder={t('REG10')}
                            onChange={mobileHandler}
                            style={{ paddingTop: "10px", paddingLeft: '27px' , paddingRight: '27px'}} />
                        {message5 && <div className={classes.redColor1}>{message5}</div>}
                    </Grid>
                    ) : (null)}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }} >
                        <Box display="flex" width={'100%'} justifyContent={"flex-end"} gap={'10px'} mt={'25px'} marginBottom={'20px'} marginRight={'27px'} >
                            <Button id="cancle-editProfileDetails" variant="outlined" color="primary" size="large" onClick={handleCancel}  >{t("TEM14")}</Button>
                            <Button id="submit-editProfileDetails" variant="contained" color="primary" size="large" onClick={handleSubmit}>{t("TEM16")}</Button>
                            {/* <Button variant="contained" color="error" size="medium" onClick={handleSubmit}>Delete Account</Button> */}
                        </Box>
                    </Grid>
                </Grid>
            </RightViewLayout>
        </Grid>
    );
}