import React, { useState } from "react";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomOuterLayoutForgetPass from "../../components/layout-components/CustomOuterLayoutForgetPass";
import UserService from "../../services/UserService";
import CommonUtil from "../../util/CommonUtils";
import Authentication from "./Authentication";
import ResetPassSucess from "./ResetPassSucess";
import SetNewPass from "./SetNewPass";

export default function ForgotPassword(props) {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();


  const [passCode, setPassCode] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [userName, setUserName] = useState("");
  const stepsValue = ["Authentication", "Set New Password", "Complete"];

  function getAndRemoveExpiredData(key) {
    const storedData = localStorage.getItem(key);
  
    if (storedData) {
      const parsedData = JSON.parse(storedData);
  
      // Check if the data has expired
      if (new Date().getTime() >= parsedData.expiration) {
        // Data has expired, remove it from localStorage
        localStorage.removeItem(key);
        return null;
      }
  
      // Data is still valid, return it
      return parsedData.value;
    }
  
    // Data not found
    return null;
  }

  //   alert(window.location.pathname);
  const handldesubmitForm = () => {
    if (
      userName !== "" &&
      passCode !== "" &&
      CommonUtil.isValidEmailLogin(userName)
    ) {
      if (activeStep <= 0) {
        setActiveStep(activeStep + 1);
        const retrievedData = getAndRemoveExpiredData('emailCode');
        if (retrievedData !== null) {
        // if(emailCode)
        // UserService.verifyCode(passCode).then((res) => {
          // console.log(passCode);
          // console.log(retrievedData);
          if (retrievedData !== passCode) {
            toast.error("Invalid code", {
              position: "top-right",
            });
            setActiveStep(0);
          } else {
             if (retrievedData === passCode) {
              resetPasswordEmail = userName;
              console.log(userName);
              toast.success("user is verified ", {
                position: "top-right",
              });
              localStorage.setItem("emailCode","")
              // console.log(res);
              setActiveStep(activeStep + 1);
            }
          }
        }else{
          toast.error("code expired ", {
            position: "top-right",
          });
          setActiveStep(0);
        }
        // });
      }
    } else if (userName === "") {
      toast.error("Please Enter Email Id", {
        position: "top-right",
      });
      setActiveStep(0);
    } else if (!CommonUtil.isValidEmailLogin(userName)) {
      toast.error("Please Enter valid Email Id Format", {
        position: "top-right",
      });
      setActiveStep(0);
    } else if (passCode === "") {
      toast.error("Please Enter Verification code", {
        position: "top-right",
      });
      setActiveStep(0);
    }

    var resetPasswordEmail = "";
 
    if (activeStep === 1) {
      if (resetPassword === "" && confirmPassword === "") {
        toast.error("Enter the given fields", {
          position: "top-right",
        });
      }

      const payload = {
        resetPassword,
        confirmPassword,
      };
      if (resetPassword === confirmPassword) {
        UserService.resetPassword(userName, payload).then((res) => {
          if (res.status === 200) {
            console.log(res);
            if (res.data.code === "ZKCSAI0002") {
              toast.success("Password updated successfully ", {
                position: "top-right",
              });

              setActiveStep(activeStep + 1);
            }
          } else if (res.status === 500) {
            toast.error("Internal server error", {
              position: "top-right",
              duration: 5000,
            });
          }
        });
      } else {
        toast.error("password and confirmation password do not match. ", {
          position: "top-right",
          duration: 5000,
        });
      }
      // here last stepper api call
    }
    if (activeStep === 2) {
      navigate("/login")
    }
  };

  const handlePassCode = (data) => {
    setPassCode(data);
  };

  const handleEmail = (data) => {
    // console.log('datafff1',data)
    setUserName(data);
  };

  const handleResetPassword = (data) => {
    console.log("data1", data);
    let value = data;
    setResetPassword(value);
    if (value.trim() === "") {
      setError1("Password is required");
    } else if (
      !/^(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[!@#$%^&*])\S{8,20}$/.test(
        value
      )
    ) {
      setError1("Invalid password format");
    } else {
      setError1("");
    }
  };

  const handleConfirmPassword = (data) => {
    console.log("data2", data);
    let value = data;
    setConfirmPassword(value);
    if (value !== resetPassword) {
      setError2("Password mismatched");
    }
    if (
      !/^(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[!@#$%^&*])\S{8,20}$/.test(
        value
      )
    ) {
      setError2("Invalid password format");
    } else {
      setError2("");
    }
  };
  return (
    // <CutomOuterlayoutOnboarding
    <CustomOuterLayoutForgetPass
      setActiveStep={setActiveStep}
      activeStep={activeStep}
      buttonName={activeStep > 1 ? "Login" : "Next"}
      handldeButton={handldesubmitForm}
      stepsValue={stepsValue}
      headerTitle={""}
      subTitle={""}
      // backFlag={false}
      handleCreateAccount={props.handleCreateAccount}
      onClose={props.onClose}

    >
      {/* {'three steps based on activestep '}
          {'if activestep 0=== show ui and submit the page'}
          {'if activestep 1 === activestep show ui and do validations and submit'}
          {'if activestep 2 === activestep show ui'} */}
      {activeStep == 0 && (
        <Authentication
          getValuePassCode={handlePassCode}
          handleEmail={handleEmail}
        />
      )}
      {activeStep == 1 && (
        <SetNewPass
          handleResetPassword={handleResetPassword}
          handleConfirmPassword={handleConfirmPassword}
          error1={error1}
          error2={error2}
        />
      )}
      {activeStep === 2 && <ResetPassSucess />}
    {/* </CutomOuterlayoutOnboarding> */}
    </CustomOuterLayoutForgetPass>
  );
}
