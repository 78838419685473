import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Tooltip, Typography } from "@mui/material";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import OrderService, { getAssertKey, getCustomerTemplate, publishCustomerTemplate, rejectOrder, updateOrderStatus } from "../../../services/Orderservice";
import RightViewLayout from "../../../components/RighViewLayout";
import useStyles from '../../onboard/Styles';
import { t } from "i18next";
import Loader from "../../../components/Loader";
import OverFlowText from "../../../components/layout-components/OverFlowText";


export default function OrderDetails() {

    const classes = useStyles();
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [title1, setTitle1] = useState("");
    const [title2, setTitle2] = useState("");
    const [bodyContent, setBodyContent] = useState("");
    const [webSite, setWebSite] = useState("");
    const [otherChanges, setOtherChanges] = useState("");
    const [imageurl, setImageUrl] = useState("");
    const [date, setDate] = useState("");
    const [open, setOpen] = useState(false);
    const [logoUrl, setLogoUrl] = useState("");
    const [refrenceUrl, setRefrenceUrl] = useState("");
    const [status, setStatus] = useState("");
    const location = useLocation();
    const [rejectionReason, setRejectionReason] = useState("");
    const [assetKey, setAssertKey] = useState("");
    const [message1, setMessage1] = useState("");
    const token = localStorage.getItem('USER_TOKEN');
    const role = localStorage.getItem("ROLE_NAME");
    const [loading, setLoading] = useState(false);

    const [customValues, setCustomValues] = useState({});

    const formatDate = (dateString) => {
        const originalDate = moment(dateString);
        const formattedDate = originalDate.format('DD MMM YYYY HH:mm:ss');
        return formattedDate;
    };

    const rejectionReasonHandler = (event) => {
        const values = event.target.value;
        setRejectionReason(values);
        if (values.trim() === '') {
            setMessage1(t('CUS14'));
        } /* else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z,&\s]{1,200}$/.test(values)) {
            setMessage1('Accepts Aphabets Comma and & no special Characters, length will be upto Characters 100 allowed and not more than one consecutive space');
        } */ else {
            setMessage1('');
        }
    };

    const [svgContent, setSvgContent] = useState(null);

    const convertPng = (svgUrl) => {

        fetch(svgUrl)
            .then((response) => response.text())
            .then((data) => {
                setSvgContent(data);
                const svgElement = new Blob([data], { type: 'image/svg+xml' });
                // Convert the SVG to PNG
                const svgURL = URL.createObjectURL(svgElement);
                const img = new Image();
                img.src = svgURL;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    // Convert the canvas to PNG format
                    const pngDataUrl = canvas.toDataURL('image/png');
                    setImageUrl(pngDataUrl);
                };

            });

    }


    useEffect(() => {
        loadData()
    }, []);

    const loadData = () => {
        setLoading(true)
        OrderService.getOrderDetails(location.state?.imgId, token)
            .then((res) => {
                setTitle1(res.data.data.title1);
                if (res.data.code === "ZKCSAI0001") {
                    setTitle1(res.data.data.title1);
                    if (res.data.data.imageUrl.includes(".svg")) {
                        convertPng(res.data.data.imageUrl);
                    } else {
                        setImageUrl(res.data.data.imageUrl);
                    }
                    setBodyContent(res.data.data.bodyContent);
                    setOtherChanges(res.data.data.otherChanges);
                    setDate(formatDate(res.data.data.date));
                    setWebSite(res.data.data.webSite);
                    setId(res.data.data.id);
                    setStatus(res.data.data.status);
                    setRejectionReason(res.data.data.rejectionReason);
                    setAssertKey(res.data.data.assetKey);
                    setLogoUrl(res.data.data.logoUrl)
                    setRefrenceUrl(res.data.data.refrenceUrl)
                    if(res.data.data.attributeValue !== undefined && res.data.data.attributeValue !== null){
                        setCustomValues(res.data.data.attributeValue)
                    }
                    setLoading(false)
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            });

    }

    const handleReject = async (event) => {
        setId(id);
        setOpen(true);
    };
    var processing = 'Processing';
    const handleStatus = async (event) => {
        if (role === "ADMIN") {
            processing = "Delivered";
        }
        // if (role === "GRAPHIC DESIGNER") {
        //     processing = "Processing";
        // }
        updateOrderStatus(id, processing, "").then((response) => {
            if (response != undefined) {
                if (response.data.code === "ZKCSAI0001") {
                    toast.success(t("CUS10"), {
                        position: "top-right",
                    });
                    loadData();
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            } else {
                toast.error(t("TEM11"), {
                    position: "top-right",
                });
            }
        })
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
        setMessage1("")
        setRejectionReason("")
    };

    var rejected = "Rejected";
    const handleSubmit = async (id) => {
        if (rejectionReason !== "" && rejectionReason !== null && rejectionReason !== undefined) {

            if (role === "ADMIN") {
                rejected = "Processing";
            }
            rejectOrder(id, rejected, rejectionReason).then((response) => {
                if (response != undefined) {
                    if (response.data.code === "ZKCSAI0001") {
                        setOpen(false);
                        toast.success(t("CUS15"), {
                            position: "top-right",
                        });
                        setRejectionReason("")
                        navigate("/CustomerOrders")
                    } else {
                        toast.error(t("TEM11"), {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            })
        } else {
            setMessage1(t('CUS14'))
        }
    };

    const handleDownload = async (id) => {
        getAssertKey(assetKey).then((response) => {
            if (response != undefined) {
                if (response.data.code === "ZKCSAI0001") {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = response.data.data.url;
                    downloadLink.download = "template.png";
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    toast.success(t("CUS26"), {
                        position: "top-right",
                    });
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            } else {
                toast.error(t("TEM11"), {
                    position: "top-right",
                });
            }
        })
    };

    const handleDownloadLogo = (logoUrl) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = logoUrl;
        downloadLink.download = "template.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toast.success(t("CUS27"), {
            position: "top-right",
        });
    }

    const handleDownloadRefrence = (refrenceUrl) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = refrenceUrl;
        downloadLink.download = "template.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toast.success(t("CUS28"), {
            position: "top-right",
        });
    }

    const handleCustomerDownload = async (id) => {

        getCustomerTemplate(id).then((response) => {

            if (response != undefined) {
                if (response.data.code === "ZKCSAI0001") {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = response.data.data.url;
                    downloadLink.download = "template.png";
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    toast.success(t("CUS29"), {
                        position: "top-right",
                    });
                    // loadData();
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });

                }
            } else {
                toast.error(t("TEM11"), {
                    position: "top-right",
                });
            }
        });
    }

    const handleCustomerPublish = async (id) => {
        publishCustomerTemplate(id).then((response) => {
            if (response != undefined) {
                if (response.data.code === "ZKCSAI0001") {
                    toast.success(t("CUS30"), {
                        position: "top-right",
                    });
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            } else {
                toast.error(t("TEM11"), {
                    position: "top-right",
                });
            }
        });
    }

    const formatLabel = (label) => {
        return label
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
          .replace(/\b\w/g, (c) => c.toUpperCase());
      };

    const actions = () => (
        <Box item style={{ display: "inherit" }}>
            <Box width="100%" paddingRight={1.5} display="flex">
                {role === 'GRAPHIC DESIGNER' ? (
                    <Grid item xs={8} >
                        <Button id="orgDownload-buttonGD" variant="contained" color="primary" size="medium" onClick={handleDownload}>{t("CUS31")}</Button>
                    </Grid>
                ) : (null)}
                {role === 'CUSTOMER' && status === 'Delivered' ? (
                    <Box display="flex" width={'100%'} alignItems="center" justifyContent={"flex-end"} gap={'10px'}>
                        <Button id="orgDownload-buttonCUS" sx={{ width: '100px' }} variant="contained" color="primary" size="medium" onClick={() => handleCustomerDownload(id)}>{t("CUS31")}</Button>
                        {/*  <Tooltip title="Publish to CMS" arrow>
                            <Button sx={{ width: '100px' }} variant="outlined" color="primary" size="medium" onClick={() => handleCustomerPublish(id)}  >Publish</Button>
                        </Tooltip> */}
                    </Box>
                ) : (null)}
            </Box>
        </Box>
    );


    const handleDragStart = (e) => {
        e.preventDefault();
    };


    return (
        <Grid >
            <RightViewLayout
                title={t("CUS16")}
                navigateBack={'/HomeScreen'}
                actions={actions}
            >

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" width={'100%'} flexDirection="row" className="OrderStyle">
                    {loading ?
                        <Loader />
                        :
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography sx={{ margin: '20px 0px 0px 27px', fontWeight: 500 }} >{t("CUS17")}</Typography>
                            <Box marginTop={'3%'} display="flex" justifyContent="center" ml={'auto'} mr={'auto'}  >
                                <img display='flex' src={imageurl} className="TemplateImage"
                                    draggable="false"
                                    onDragStart={handleDragStart}
                                    alt="Preview" style={{ height: '280px', width: '400px' }} />
                            </Box>
                        </Grid  >
                    }
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography sx={{ margin: '20px 0px 0px 27px', fontWeight: 500, }} >{t("CUS18")}</Typography>
                        <Grid item xs={12} sm={8}>
                            <Typography className="viewTypography"> {t("CUS19")} <span className={classes.redAsterisk}>  *</span></Typography>
                            <Typography className="viewTypography1">{date}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography className="viewTypography"> {t("CUS20")} <span className={classes.redAsterisk}>  *</span></Typography>
                            <Typography className="viewTypography1">{title1}</Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={8}>
                            <Typography className="viewTypography">Title2<span className={classes.redAsterisk}>  *</span></Typography>
                            <Typography className="viewTypography1">{title2}</Typography>
                        </Grid> */}
                        <Grid item xs={12} sm={8}>
                            <Typography className="viewTypography">  {t("CUS21")}<span className={classes.redAsterisk}>  *</span></Typography>
                            <Typography className="viewTypography1">{bodyContent}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography className="viewTypography"> {t("CUS22")} <span className={classes.redAsterisk}>  *</span></Typography>
                            <Typography className="viewTypography1">{webSite}</Typography>
                        </Grid>
                        {otherChanges !== null && otherChanges !== undefined ? (
                            <Grid item xs={12} sm={8}>
                                <Typography className="viewTypography"> {t("CUS23")}</Typography>
                                <Typography className="viewTypography1">{otherChanges}</Typography>
                            </Grid>
                        ) : (null)}

                        <Grid item xs={12} sm={8}>

                            {Object.entries(customValues).map(([key, value]) => (
                                <React.Fragment key={key}>
                                    {/* <Box mt={2} border={'1px solid red'}> */}
                                        <Typography className="viewTypography" >
                                            {formatLabel(key)}
                                        </Typography>
                                    {/* </Box> */}
                                    <Typography className="viewTypography1" >
                                        {value ? value : "-"}
                                    </Typography>
                                </React.Fragment>
                            ))}
                        </Grid>

                        <Grid xs={12} sm={9} style={{ display: 'flex' }}>
                            <Grid item xs={12} sm={8}>
                                <div style={{ display: 'flex' }}>
                                    {/* Logo */}
                                    {logoUrl && (
                                        <div>
                                            <Typography className="viewTypography"> {t("CUS24")}</Typography>
                                            <Box marginTop={'5%'} display="flex" justifyContent="center" ml={'auto'} mr={'auto'}>
                                                <img display='flex' src={logoUrl} draggable="false" onDragStart={handleDragStart} alt="Preview" style={{ height: '200px', width: '200px', marginLeft: '27px' }} />
                                            </Box>
                                            {role === 'GRAPHIC DESIGNER' && status === 'Processing' && (
                                                <Grid item xs={8}>
                                                    <Button id="dowloadLogo-buttonGD" variant="contained" color="primary" size="medium" style={{ marginLeft: '27px', marginTop: '5px' }} onClick={() => handleDownloadLogo(logoUrl)}>Download</Button>
                                                </Grid>
                                            )}
                                        </div>
                                    )}

                                    {/* Reference Image */}
                                    {refrenceUrl && (
                                        <div>
                                            <Typography className="viewTypography"> {t("CUS25")}</Typography>
                                            <Box marginTop={'5%'} display="flex" justifyContent="center" ml={'auto'} mr={'auto'}>
                                                <img display='flex' src={refrenceUrl} draggable="false" onDragStart={handleDragStart} alt="Preview" style={{ height: '200px', width: '200px', marginLeft: '27px' }} />
                                            </Box>
                                            {role === 'GRAPHIC DESIGNER' && status === 'Processing' && (
                                                <Grid item xs={8}>
                                                    <Button id="dowloadRefrenceUrl-buttonGD" variant="contained" color="primary" size="medium" style={{ marginLeft: '27px', marginTop: '5px' }} onClick={() => handleDownloadRefrence(refrenceUrl)}>Download</Button>
                                                </Grid>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>

                        {status === 'Delivered' && rejectionReason !== null && rejectionReason !== undefined && rejectionReason !== '' ? (
                            <Grid item xs={12} sm={8}>
                                <Typography className="viewTypography">Rejected Reason<span className={classes.redAsterisk}>  *</span></Typography>
                                <Typography className="viewTypography1">{rejectionReason}</Typography>
                            </Grid>
                        ) : null}
                        {status === 'Rejected' ? (
                            <Grid item xs={12} sm={8}>
                                <Typography className="viewTypography">Rejected Reason<span className={classes.redAsterisk}>  *</span></Typography>
                                <Typography className="viewTypography1">{rejectionReason}</Typography>
                            </Grid>
                        ) : null}
                        {status === 'Processing' && rejectionReason !== null && rejectionReason !== undefined && rejectionReason !== '' ? (
                            <Grid item xs={12} sm={8}>
                                <Typography className="viewTypography">Rejected Reason<span className={classes.redAsterisk}>  *</span></Typography>
                                <Typography className="viewTypography1">{rejectionReason}</Typography>
                            </Grid>
                        ) : null}
                        {role === 'GRAPHIC DESIGNER' && status === 'Submitted' || role === 'ADMIN' && status === 'Review' ? (
                            <Grid item xs={12} sm={8} display={'flex'} pl={'27px'} pt={'15px'}>
                                <Button id="rejectOrderDetails-Button" variant="outlined" color="primary" size="medium" onClick={handleReject} sx={{ mr: 2 }}>Reject</Button>
                                <Button id="approveOrderDetails-Button" variant="contained" color="primary" size="medium" onClick={handleStatus}>Approve</Button>
                            </Grid>
                        ) : (null)}

                    </Grid>
                </Grid>
            </RightViewLayout>
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h6">Reason For Rejection</Typography>
                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <textarea value={rejectionReason} placeholder="Enter Reason For Rejection" onChange={rejectionReasonHandler}
                        rows={10} cols={52} style={{ resize: "vertical", padding: 10 }} />
                    {message1 && <div className={classes.redColor}>{message1}</div>}
                    <div hidden>{id}</div>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} paddingTop={"10px"}>
                        <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
                            <Button id="orderDetails-cancleButton" variant="outlined" color="primary" size="medium" onClick={() => handleCancel()} sx={{ marginRight: 2, textTransform: 'capitalize' }}>Cancel</Button>
                            <Button id="orderDetails-submitButton" variant="contained" color="primary" size="medium" onClick={() => handleSubmit(id)}>Submit</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}