import { Grid, Typography } from "@material-ui/core";
import React from "react";
import success from "../../assets/images/icon_successed_100px@2x.png";

function ResetPassSucess() {
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        flexDirection={"column"}
        style={{ padding: "5%" }}
      >
        <Grid item style={{ margin: "auto" }}>
          <img
            src={success} alt="success"
            style={{   width: "50%", height: "50%" ,marginLeft:"20%" }}
          />
          <Typography  variant="h6"
                fontSize={24}
                fontWeight={800}
                textAlign={"center"} gutterBottom>Password Reset Successful</Typography>
          {/* <Typography
            align="center"
            style={{
              fontSize: "14px",
              fontFamily: "Lato",
              fontWeight: "400",
              color: "#7C8D9D",
              lineHeight: "50px",
              
            }}
          >
            <Link to="/login" style={{ color: "#1487CA " }}>
              Sign In
            </Link>
          </Typography> */}
        </Grid>
      </Grid>
    </>
  );
}

export default ResetPassSucess;
