import { Box, Grid, Tooltip } from "@mui/material";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { ReactComponent as Refresh } from "../../../assets/images/refresh_Icon.svg";
import CustomDropDownDatePicker from "../../../components/CustomDropDownDatePicker";
import DataTable from "../../../components/DataTable";
import RightViewLayout from "../../../components/RighViewLayout";
import { getRejectedTemplates } from "../../../services/AdvertService";
import { t } from "i18next";

const options = [
  { value: 'Today', label: 'Today' },
  { value: 'Yesterday', label: 'Yesterday' },
  { value: 'Week', label: 'Week' },
];

export default function RejectedTemplates() {
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [callApiFilter, setcallApiFilter] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState("");

  const [pageOperation, setPageOperation] = useState({
    page: 0,
    rowsPerPage: 10,
  });
  const formatDate = (dateString) => {
    const originalDate = moment(dateString);
    const formattedDate = originalDate.format('DD MMM YYYY HH:mm:ss');
    return formattedDate;
  };

  const loadData = () => {
    let query = "";
    if (userFilter) {
      query += "&filterAdvert=" + userFilter;
    }
    const country = localStorage.getItem("COUNTRY");
    if (query === "") {
      getRejectedTemplates(pageOperation.page + 1, pageOperation.rowsPerPage, "", country).then(
        (res) => {

          if (res.data.code === "ZKCSAE0001") {
            console.log(res.data.data.zkadverts)

            if (res.data.code === "ZKCSAE0001") {
              setRows(res.data.data.zkadverts);
              setTotalRecords(res?.data?.data?.totalCount);
            } else {
              setRows([]);
              setTotalRecords(0);
            }

          }
        }
      );
    } else if (query !== "") {
      getRejectedTemplates(1, pageOperation.rowsPerPage, query, country).then((res) => {
        if (res != undefined) {
          if (res.data.code === "ZKCSAE0001") {
            setRows(res.data.data.zkadverts);
            setTotalRecords(res?.data?.data?.totalCount);
          } else {
            setRows([]);
            setTotalRecords(0);
          }
        }
      });
    }
  };


  useEffect(() => {
    if (callApiFilter) {
      loadData();

      setcallApiFilter(false);
    }
  }, [callApiFilter]);


  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      flex: 1,
      renderCell: (e) => e.row.id,
      hide: true,
    },
    {
      field: "created_at",
      headerName: t("REJ2"),
      width: 200,
      flex: 1,
      renderCell: (e) => formatDate(e.row.createdAt),
    },
    {
      field: "Graphic Designer Name",
      headerName: t("REJ3"),
      width: 200,
      flex: 1,
      renderCell: (e) => e.row.createdBy,
    },
    {
      field: "status",
      headerName: t("REJ4"),
      width: 200,
      flex: 1,
      renderCell: (e) => e.row.status,
    },

    {
      field: "Template name",
      headerName: t("REJ5"),
      width: 200,
      flex: 1.2,
      renderCell: (e) =>
      (<Tooltip title={e.row.tags} placement="bottom-start">
         {e.row.tags}
        </Tooltip>)
    },

    {
      field: "Template Description",
      headerName: t("REJ6"),
      width: 200,
      flex: 1,
      renderCell: (e) => e.row.description,
    },
  ];

  const handleChangePage = (newPage) => {
    setSelectedValue("");
    setPageOperation({ ...pageOperation, page: newPage });
    setcallApiFilter(true);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setSelectedValue("");
    setPageOperation({ ...pageOperation, page: 0, rowsPerPage: pageSize });
    setcallApiFilter(true);
  };

  const handleSelectedRows = (data) => {
    console.log("data", data);
  };

  const handleRefresh = () => {
    setSelectedValue("");
    loadData();
    setPageOperation({ page: 0, rowsPerPage: 10 });
  }


  var userFilter = "";
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedValue(value)

    userFilter = value;
    loadData();
    setPageOperation({ ...pageOperation, page: 0 });
  };

  const actions = () => (
    <Box item style={{ display: "inherit" }}>
      <CustomDropDownDatePicker value={selectedValue} onChange={handleChange} options={options} />
      <Tooltip title={t("USER2")} arrow>
        <Refresh id="rejectedTemp-refresh"
          width={"40px"}
          style={{ marginRight: "10px", cursor: "pointer" }}
          onClick={() => {
            handleRefresh();
          }}
        />
      </Tooltip>
    </Box>
  );

  return (
    <Grid >
      <RightViewLayout
        title={t("REJ1")}
        actions={actions}
        type="table"
      >
        <Grid container margin='auto' paddingLeft={'0px'} overflow={"initial"} display="flex" >
          <DataTable
            columns={columns}
            rows={rows}
            onSelection={(data) => handleSelectedRows(data)}
            page={pageOperation.page}
            totalRecords={totalRecords}
            rowsPerPage={pageOperation.rowsPerPage}
            onPageChange={(pn) => handleChangePage(pn)}
            onPageSizeChange={(ps) => handleChangeRowsPerPage(ps)}
            disableRowSelectionOnClick
          />
        </Grid>
      </RightViewLayout>
    </Grid>
  );
}
