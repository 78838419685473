import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RightViewLayout from "../../../components/RighViewLayout";
import UserService from "../../../services/UserService";
import { useNavigate } from 'react-router';
import { t } from "i18next";


export default function ProfileDetails(props) {

    const navigate = useNavigate();
    const userId = localStorage.getItem('USER_NAME');
    const userRole = localStorage.getItem('ROLE_NAME');
    const [profile, setProfile] = useState("");

    useEffect(() => {
        UserService.getUser(userId).then((res) => {
            if (res.data.code === "UASI0033") {
                setProfile(res.data.data);
            }
        });
    }, []);

    const handleCancel = () => {
        navigate("/HomeScreen");
    };

    const handleSubmit = () => {
        navigate("/EditProfileDetails");
    };

    return (
        <Grid >
            <RightViewLayout
                title={t("PRO1")}
                navigateBack={'/HomeScreen'}
            >
                <Grid container xs={12} display="flex" flexDirection="row"
                    style={{ backgroundColor: '#fff', border: '0.5px solid #eeeeee', paddingBottom: '10px', borderRadius: '5px' }}>
                    <Grid item md={12} >
                        <Typography className="viewTypography">{ t("PRO2")} </Typography>
                        <Typography className="viewTypography2">{profile.firstName}</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography className="viewTypography"> {t("PRO3")} </Typography>
                        <Typography className="viewTypography2">{profile.lastName}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography className="viewTypography"> {t("PRO4") }</Typography>
                        <Typography className="viewTypography2">{profile.email}</Typography>
                    </Grid>
                    {userRole !== "OWNER"  ? (
                    <Grid item xs={12} >
                        <Typography className="viewTypography"> {t("PRO5")}</Typography>
                        <Typography className="viewTypography2">{profile.phone}</Typography>
                    </Grid>
                    ) : (null)}

                    {userRole !== "OWNER"  ? (
                    <Grid item xs={12} >
                        <Typography className="viewTypography"> {t("PRO6")}</Typography>
                        <Typography className="viewTypography2">{profile.country}</Typography>
                    </Grid>
                    ) : (null)}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }} >
                        <Box display="flex" width={'100%'} justifyContent={"flex-end"} gap={'10px'} mt={'25px'} marginBottom={'20px'} marginRight={'27px'} >
                            <Button id="cancel-profile" variant="outlined" color="primary" size="large" onClick={handleCancel}  >{t("TEM14")}</Button>
                            {userRole !== "OWNER"  ? (
                            <Button id='profile-submit' variant="contained" color="primary" size="large" onClick={handleSubmit}>{t("USER11")}</Button>
                            ) : (null)}
                            {/* <Button variant="contained" color="error" size="medium" onClick={handleSubmit}>Delete Account</Button> */}
                        </Box>
                    </Grid>
                </Grid>
            </RightViewLayout>
        </Grid>
    );
}