import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { t } from "i18next";

export default function CustomDropDownDatePicker({ value, onChange, options }) {

    return (
        <>
            <FormControl sx={{ width: 170 }}>

                <InputLabel htmlFor="select-placeholder">
                    {value === '' ? t('Today') : ""}
                </InputLabel>

                <Select style={{ backgroundColor: '#f5f7f8', marginRight: '13px', height: '40px', marginTop: '5px' }}
                    value={value}
                    onChange={onChange}
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {t(option.label)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </ >
    );
}
