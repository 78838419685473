import { Grid } from "@mui/material";
import React from "react";
import CustomeTemplates from "../../../components/layout-components/customHomeScreenTemplatesLayout"

export default function HomeScreen(){
    return(
        <Grid  style={{ height: "100%", display: "flex", flexDirection: "row", }}>
            <Grid container width={'100%'} margin={"auto"} >
                <CustomeTemplates/>
            </Grid>
        </Grid>

    );
}