import api from "../configurations/http-common";

export const createEmployee = async (payload) => {
  return api.securedAxios().post("web/users", payload);
};
export const updateListOfEmployeeByDeptId = async (payload) => {
  return api.securedAxios().put("web/employee/listupdate", payload);
};

export const getAllEmployees = async (pageNumber, pageSize, request) => {
  return api
    .securedAxios()
    .get(
      "web/user/list?" +
      "pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      request
    );
};

export const deleteUserById = async (id) => {
  return api.securedAxios().delete("web/users/"+id);
};

export const getEmployeeById = async (id) => {
  return api.securedAxios().get("web/employee/"+id);
};

export const getUserById = async (id) => {
  return api.securedAxios().get("web/users/"+id);
};

export const updateEmployee = async (id,payload) => {
  return api.securedAxios().put("web/users/"+id, payload);
};

export const getAllRoles = async (request) => {
  return api.securedAxios().get("web/role");
};
const getMembershipByuserIdOrEmailOrPhone = (email) => {
  return api.securedAxios().get("web/"+email+"/memberships")
}

const EmployeeService = {
  createEmployee,
  getAllEmployees,
  deleteUserById,
  getUserById,
  updateEmployee,
  getAllRoles,
  getMembershipByuserIdOrEmailOrPhone
};

export default EmployeeService;
