import api from "../configurations/http-common";


export const createTags = async (payload) => {
  return api.securedAxios().post("/web/tags", payload);
};

export const updateTags = async (id, payload) => {
  return api.securedAxios().put("/web/tags/" + id, payload);
};

export const getAllTags = async (pageNumber, pageSize) => {
  return api.securedAxios().get("/web/tags/getAllTags?" +
    "pageNumber=" +
    pageNumber +
    "&pageSize=" +
    pageSize);
};

export const getAllDefaultTags = async () => {
  return api.unsecuredAxios().get("/web/tags/type/Default");
};

export const deleteTagById = async (id) => {
  return api.securedAxios().delete("/web/tags/" + id);
};

const TagService = {
  getAllTags,
  createTags,
  updateTags,
  getAllDefaultTags,
  deleteTagById
};

export default TagService;