import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";

import CustomDialog from "../../../components/CustomDialog";
import useStyles from "../../onboard/Styles";

const EmployeeFilter = ({
  flag,
  setFlag,
  filters,
  setFilters,
  setcallApiFilter,
  filterFlag,
  title
}) => {
  const classes = useStyles();

  const onClose = () => {
    setFlag(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevDepartment) => ({
      ...prevDepartment,
      [name]: value,
    }));
  };

  const saveBtnHandler = (e) => {
    e.preventDefault();
    setcallApiFilter(true);
    setFlag(false);
    filterFlag(true)
    
  };
  const skipBtnHandler = () => {
    setFlag(false);
  };

  return (
    <CustomDialog open={flag} onClose={onClose} title={'Filter User'}>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        width={"auto"}
        height={"auto"}
        xs={12}

      >
        <Grid container spacing={4} xs={12}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography className={classes.labels}>
              First Name<span className={classes.redAsterisk}> </span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={filters.firstName}
              name="firstName"
              placeholder="Enter First Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography className={classes.labels}>
              Last Name<span className={classes.redAsterisk}> </span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={filters.lastName}
              name="lastName"
              placeholder="Enter Last Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.labels}>
              Email<span className={classes.redAsterisk}> </span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={filters.email}
              name="email"
              placeholder="Enter User Email"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} gap={'10px'}>
          <Box display="flex" width={'100%'} justifyContent={"flex-end"} gap={'10px'} mr={'30px'} mt={'25px'}>
            <Button id="cancel-filter" variant="outlined" onClick={skipBtnHandler}>
              Cancel
            </Button>
            <Button id="submit-filter"  variant="contained"  onClick={saveBtnHandler}>
              Filter User
            </Button>
          </Box>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default EmployeeFilter;
