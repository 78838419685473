import axios from 'axios';
import api from "../configurations/http-common";

const header = {
  headers: {
    "Content-Type": "multipart/form-data",
  }
};

export const createAdvert = async (file, file1, file2, file3, tags, description, advertType, templateName,newFields, country, token) => {
  return axios.post(process.env.REACT_APP_SERVER_URL + "/web/templates", file, file1, file2, file3, tags, description, advertType, templateName, token,newFields, country, header);
};

export const listAdverts = async (pageNumber, pageSize, request, country) => {
  return api
    .securedAxios()
    .get(
      "/web/templates/getTemplates/Submitted/" + country + "?" + "pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize
      + request
    );
};

export const getAdvertApprovedList = async (country) => {
  return api
    .securedAxios()
    .get(
      "/web/templates/beforLogin/Approved/" + country
    );
};

export const getAdvertApprovedListLanding = async (country) => {
  return api
    .unsecuredAxios()
    .get(
      "/web/templates/beforLogin/Approved"
    );
};

export const updateTemplate = async (id, status) => {
  return api.securedAxios().put("/web/templates/update/" + id + "/" + status);
};

export const getTemplateById = async (id, token) => {
  return api.securedAxios().get("web/templates/getImageKey/" + id + "/" + token);
}

export const getRejectedTemplates = async (pageNumber, pageSize, request, country) => {
  return api
    .securedAxios()
    .get(
      "/web/templates/getTemplates/Rejected/" + country + "?" + "pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize
      + request
    );
};

export const getTemplatesByCategory = async (selectedCategory, country) => {
  return api.unsecuredAxios().get("/web/templates/beforLogin/" + selectedCategory + "/Approved/" + country);
};

export const getTemplatesBySearch = async (selectedCategory, country) => {
  return api.unsecuredAxios().get("/web/templates/beforLogin/search/" + selectedCategory + "/Approved/" + country);
};

export const getDefaultTag = async () => {
  return api.unsecuredAxios().get("/web/tags/type/Default");
};

export const getAllDefultTags = async () => {
  return api.securedAxios().get("/web/tags/getAllTags");
};


export const getAuditTemplates = async (pageNumber, pageSize, request, country) => {
  return api.securedAxios().get("/web/templates/getAuditTemplates/" + country + "?" +
    "pageNumber=" +
    pageNumber +
    "&pageSize=" +
    pageSize +
    request
  );
};

export const getTemplateImages = async (id) => {
  return api.securedAxios().get("/web/templates/getTemplateImages/" + id);
};

export const deleteTemplate = async (id) => {
  return api.securedAxios().delete("/web/templates/deleteTemplate/" + id);
};

export const updateTemplateById = async (payload) => {
  // debugger
  return api.securedAxios().put("/web/templates/updateTemplate", payload);
};


export const recentlyViewedTemplate = async (id) => {
  return api.securedAxios().get(`/web/recentlyViewed/${id}`);
};

export const mostlyViewedTemplate = async () => {
  return api.securedAxios().get("/web/templates/getMostlyViewedTemplates");
};


export const recentlyViewed = async (payload) => {
  return api.securedAxios().post("/web/recentlyViewed", payload);
};
  
export const mostlyViewd = async (newId) => {
  return api.securedAxios().put(`/web/templates/mostlyViewedTemplates/${newId}`);
};

export const getAttributesByTemplateId = async (id) => {
  return api.securedAxios().get("/web/attributes/attributesTemp/" + id);
};



const AdvertService = {
  createAdvert,
  getAdvertApprovedList,
  getAdvertApprovedListLanding,
  listAdverts,
  updateTemplate,
  updateTemplateById,
  getTemplateById,
  getRejectedTemplates,
  getTemplatesByCategory,
  getAuditTemplates,
  getTemplateImages,
  deleteTemplate,
  getDefaultTag,
  getAllDefultTags,
  recentlyViewed,
  recentlyViewedTemplate,
  mostlyViewd,
  mostlyViewedTemplate,
  getTemplatesBySearch,
  getAttributesByTemplateId
}

export default AdvertService;
