import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as KeyBoardLeftArrowIcon } from '../assets/images/KeyboardArrowLeftIcon.svg';

const TitleBar = (props) => {

  let navigate = useNavigate();
  return (
    <>
      <Box display={"flex"} mt={3.0} mb={1.0} height={'5vh'} width="100%" alignItems={'center'} >
        {props.navigateBack && (
          <Box item pt={0.6} ml={0.4}>
            <Tooltip title={'Back'}>
              <KeyBoardLeftArrowIcon
                style={{ cursor: 'pointer' }} width={17} height={17} fontSize='small'
                onClick={() => navigate(-1)}
              />  
            </Tooltip>
          </Box>
        )}
        <Box item flexGrow={1} ml={1}>
          <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>{props.title}</Typography>
        </Box>

        {props.actions && <props.actions />}
      </Box>
    </>
  );
};
export default TitleBar;
