import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Visibility } from "../../../assets/images/View.svg";
import { ReactComponent as Done } from "../../../assets/images/approve.svg";
import { ReactComponent as Refresh } from "../../../assets/images/refresh_Icon.svg";
import { ReactComponent as Clear } from "../../../assets/images/reject.svg";
import DataTable from "../../../components/DataTable";
import IconHandler from "../../../components/IconHandler";

import { t } from "i18next";
import CustomDropDownDatePicker from "../../../components/CustomDropDownDatePicker";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_TEMPLATE_IMAGES } from "../../../constants/ReactEndPoints";
import {
  listAdverts,
  updateTemplate
} from "../../../services/AdvertService";

const options = [
  { value: 'Today', label: 'Today' },
  { value: 'Yesterday', label: 'Yesterday' },
  { value: 'Week', label: 'Week' },
];

export default function TemplateList() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [deptFlag, setDeptFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [imageurl, setImageUrl] = useState("");
  const [callApiFilter, setcallApiFilter] = useState(true);
  const [id, setId] = useState('')
  const [selectedValue, setSelectedValue] = React.useState("");

  const [pageOperation, setPageOperation] = useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [filters, setFilters] = useState({});
  const formatDate = (dateString) => {
    const originalDate = moment(dateString);
    const formattedDate = originalDate.format('DD MMM YYYY HH:mm:ss');
    return formattedDate;
  };

  const toolbarProps = {
    refresh: true,

    onRefresh: (data) => {
      loadData()
      setFilters({
        ...filters,
        createdAt: "",
        createdBy: "",
        status: "",
        templateName: ""
      });
      setcallApiFilter(true);
      setPageOperation({ page: 0, rowsPerPage: 10 });
    }
  };



  const loadData = () => {
    let query = "";
    if (filters?.createdAt) {
      query += "&createdAt=" + filters.createdAt;
    }

    if (filters?.createdBy) {
      query += "&createdBy=" + filters.createdBy;
    }
    if (filters?.description) {
      query += "&description=" + filters.description;
    }
    if (filters?.status) {
      query += "&status=" + filters.status;
    }
    if (filters?.templateName) {
      query += "&templateName=" + filters.templateName;
    }
    if (userFilter) {
      query += "&filterAdvert=" + userFilter;
    }
    console.log("query", query);
    const country = localStorage.getItem("COUNTRY");
    if (query === "") {
      listAdverts(pageOperation.page + 1, pageOperation.rowsPerPage, "", country).then(
        (res) => {
          if (res.data.code === "ZKCSAE0001") {

            console.log(res.data.code)

            if (res.data.code === "ZKCSAE0001") {
              console.log(res);
              setRows(res.data.data.zkadverts);
              setTotalRecords(res?.data?.data?.totalCount);
            } else {
              setRows([]);
              setTotalRecords(0);
            }

          }
        }
      );
    } else if (query !== "") {
      listAdverts(1, pageOperation.rowsPerPage, query, country).then((res) => {
        // if (res.status === 200) {
        if (res != undefined) {
          if (res.data.code === "ZKCSAE0001") {
            setRows(res.data.data.zkadverts);
            setTotalRecords(res?.data?.data?.totalCount);
          } else {
            setRows([]);
            setTotalRecords(0);
          }
        }
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (id) => {
    updateTemplate(id, approved).then((response) => {
      if (response != undefined) {
        if (response.data.code === "ZKCSAI0001") {
          setOpen(false);
          toast.success(t("APPUP6"), {
            position: "top-right",
          });
          loadData();
        } else {
          toast.error(t("TEM11"), {
            position: "top-right",
          });
        }
      } else {
        toast.error(t("TEM11"), {
          position: "top-right",
        });
      }
    })
  };



  const handleCancel = () => {
    updateTemplate(id, rejected).then((response) => {
      if (response != undefined) {
        if (response.data.code === "ZKCSAI0001") {
          setOpen(false);
          toast.success(t("APPUP5"), {
            position: "top-right",
          });
          loadData();
        } else {
          toast.error(t("TEM11"), {
            position: "top-right",
          });
        }
      } else {
        toast.error(t("TEM11"), {
          position: "top-right",
        });
      }
    })

  };

  useEffect(() => {
    if (callApiFilter) {
      loadData();
      setcallApiFilter(false);
    }
  }, [callApiFilter]);


  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      flex: 1,
      renderCell: (e) => e.row.id,
      hide: true,
    },
    {
      field: "createdAt",
      headerName: t("TEM4"),
      width: 200,
      flex: 1,
      renderCell: (e) => formatDate(e.row.createdAt),
    },
    {
      field: "createdBy",
      headerName: t("APPUP2"),
      width: 200,
      flex: 1,
      renderCell: (e) => e.row.createdBy,
    },
    {
      field: "status",
      headerName: t("REJ4"),
      width: 200,
      flex: 1,
      renderCell: (e) => e.row.status,
    },

    {
      field: "templateName",
      headerName: t("APPUP3"),
      width: 200,
      flex: 1.2,
      renderCell: (e) => e.row.templateName,
    },

    {
      field: "description",
      headerName: t("APPUP4"),
      width: 200,
      flex: 1.2,
      renderCell: (e) => e.row.description,
    },
    {
      headerName: t("USER10"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (e) => (
        <IconHandler>
          <Tooltip title={t("CUS7")} arrow>
            <Visibility id="publicTemp-view"
              onClick={() => handleActions("View", e)}
              style={{ alignSelf: "center", paddingTop: "0px", margin: "5px" }}
            />
          </Tooltip>
          <Tooltip title={t("CUS8")} arrow>
            <Done id="publicTemp-done"
              onClick={() => handleActions("Approve", e)}
              style={{ alignSelf: "center", paddingTop: "0px", margin: "7px", width: '1.1em' }}
            />
          </Tooltip>
          <Tooltip title={t("CUS9")} arrow>
            <Clear id="publicTemp-reject"
              onClick={() => handleActions("Reject", e)}
              style={{ alignSelf: "center", paddingTop: "0px", margin: "7px", width: '1.1em' }}
            />
          </Tooltip>
        </IconHandler>
      ),
    },
  ];
  const token = localStorage.getItem('USER_TOKEN');
  const approved = 'Approved';
  const rejected = "Rejected";
  const handleActions = (action, data) => {
    if (action === "Reject") {
      setId(data['id'])
      console.log(id)
      updateTemplate(data['id'], rejected).then((response) => {
        if (response != undefined) {
          if (response.data.code === "ZKCSAI0001") {
            toast.success(t("APPUP5"), {
              position: "top-right",
            });
            loadData();
          } else {
            toast.error(t("TEM11"), {
              position: "top-right",
            });
          }
        } else {
          toast.error(t("TEM11"), {
            position: "top-right",
          });
        }
      })
    } else if (action === "Approve") {
      setId(data['id'])
      console.log(id)
      updateTemplate(data['id'], approved).then((response) => {
        if (response != undefined) {
          if (response.data.code === "ZKCSAI0001") {
            toast.success(t("APPUP6"), {
              position: "top-right",
            });
            loadData();
          } else {
            toast.error(t("TEM11"), {
              position: "top-right",
            });
          }
        } else {
          toast.error(t("TEM11"), {
            position: "top-right",
          });
        }
      })
    } else if (action === "View") {
      const templateId = data.id;
      console.log(data['id'])
      navigate(REACT_TEMPLATE_IMAGES, { state: { templateId } });


      /*  getTemplateImages(data['id']).then((response) => {
         if (response != undefined) {
           if (response.data.code === "ZKCSAI0001") {
             setOpen(true);
             setId(data['id'])
             setImageUrl(response.data.data.previewImage);
             loadData();
           } else {
             toast.error("something went wrong", {
               position: "top-right",
             });
           }
         } else {
           toast.error("something went wrong", {
             position: "top-right",
           });
         }
       }) */
    }
  };

  const handleChangePage = (newPage) => {
    setSelectedValue("");
    setPageOperation({ ...pageOperation, page: newPage });
    setcallApiFilter(true);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setSelectedValue("");
    setPageOperation({ ...pageOperation, page: 0, rowsPerPage: pageSize });
    setcallApiFilter(true);
  };

  const handleSelectedRows = (data) => {
    console.log("data", data);
  };

  console.log("filter", filters);

  const handleRefresh = () => {
    setSelectedValue("");
    loadData()
    setFilters({
      ...filters,
      createdAt: "",
      createdBy: "",
      status: "",
      templateName: ""
    });
    setcallApiFilter(true);
    setPageOperation({ page: 0, rowsPerPage: 10 });
  }

  var userFilter = "";
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedValue(value)

    userFilter = value;
    loadData();
    setPageOperation({ ...pageOperation, page: 0 });
  };

  const actions = () => (
    <Box item style={{ display: "inherit" }}>
      <CustomDropDownDatePicker value={selectedValue} onChange={handleChange} options={options} />
      <Tooltip title={t("USER2")} arrow>
        <Refresh id="tempList-refresh"
          width={"40px"}
          style={{ marginRight: "10px", cursor: "pointer" }}
          onClick={() => {
            handleRefresh();
          }}
        />
      </Tooltip>
    </Box>
  );

  return (
    <Grid >
      <RightViewLayout
        title={t("APPUP1")}
        actions={actions}
        type="table"
      >
        <Grid container margin='auto' paddingLeft={'0px'} overflow={"initial"} display="flex" >
          <DataTable

            columns={columns}
            rows={rows}
            onSelection={(data) => handleSelectedRows(data)}
            page={pageOperation.page}
            totalRecords={totalRecords}
            rowsPerPage={pageOperation.rowsPerPage}
            onPageChange={(pn) => handleChangePage(pn)}
            onPageSizeChange={(ps) => handleChangeRowsPerPage(ps)}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Grid>
      </RightViewLayout>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography variant="h6">{t("TEM12")}</Typography>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
        </DialogTitle>
        <DialogContent>
          <img src={imageurl} alt="Selected Template" style={{ width: "450px", height: '300px' }} />
          <div hidden>{id}</div>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} paddingTop={"10px"}>
            <Box display="flex" justifyContent="flex-end">
              <Button id="tempList=cancleButton" variant="outlined" color="primary" onClick={() => handleCancel(id)} sx={{ marginRight: 2 }}> {t("CUS9")}  </Button>
              <Button id="tempList=submitButton" variant="contained" color="primary" onClick={() => handleSubmit(id)}>   {t("CUS8")} </Button>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
