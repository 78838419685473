import { DialogActions, Grid, IconButton, Paper, StepLabel, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import * as React from "react";
import closeIcon from "../../assets/images/close icon.svg";


export default function CustomOuterLayoutForgetPass(props) {
  const { activeStep, buttonName, handldeButton, stepsValue, headerTitle, subtitle, setActiveStep, handleCreateAccount, onClose } = props;
  const steps = stepsValue;


  return (
    <Grid container>
      <Grid item xs={12} component={Paper} elevation={6} width={'650px'}>
        <Box sx={{ my: 7, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
          <DialogActions style={{ position: 'absolute', top: '7px', right: '7px', }}>
            <IconButton onClick={onClose}>
              <img alt="closeIcon" src={closeIcon} ></img>
            </IconButton>
          </DialogActions>

          <Typography font="normal normal medium 30px/34px Roboto" sx={{ pb: '13px' }} fontSize={"22px"}>
            <strong>{'Forgot Password'}</strong>
          </Typography>

          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              {activeStep !== -1 &&
                <>
                  <Box sx={{ width: "100%" }} pt={2} p={2}  >
                    <Stepper nonLinear activeStep={activeStep}>
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>

                </>
              }
              {props.children}
              {activeStep !== -1 && activeStep <= 1 &&
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: '3%'
                    }}
                  >
                    <Button id="forgotPass-next"
                      style={{ display: "flex", height: '53px', width: "100%", backgroundColor: "#1487CA" }}
                      onClick={handldeButton}
                      variant="contained"
                      color="primary"
                    >
                      {buttonName}
                    </Button>
                  </div>
                </>
              }
            </Box>
            <Grid container justifyContent='center'>
              <Typography variant="body2" align="center" sx={{ font: "normal normal normal 12px/19px Roboto", color: "#474B4F", opacity: "0.8" }}>
                Already have an account?{' '}
                <Button onClick={handleCreateAccount}style={{ font: "normal normal normal 12px/19px Roboto", opacity: "0.8", color: '#1487CA', marginLeft: '-10px' }}>
                  Sign In
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>

  );
}
