
import { ReactComponent as customerIcon } from "../../assets/images/customerOrder.svg";
import { ReactComponent as customerIcon_white } from "../../assets/images/customerOrder_white.svg";
import { ReactComponent as HomeIcon_white } from "../../assets/images/home (1).svg";
import { ReactComponent as HomeIcon } from "../../assets/images/home (2).svg";
import { ReactComponent as myworkIcon } from "../../assets/images/mywork.svg";
import { ReactComponent as myworkIcon_white } from "../../assets/images/mywork_w.svg";
import { ReactComponent as rejectedUpload } from "../../assets/images/rejectedUpload.svg";
import { ReactComponent as rejectedUpload_w } from "../../assets/images/rejectedUpload_w.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/uploadIcon.svg";
import { ReactComponent as UploadIcon_white } from "../../assets/images/upload_white.svg";
import BrowserLanguage from "../../util/BrowserLanguage";
import { t } from "i18next";

let language = BrowserLanguage.getDefaultLanguage();
const dataEn = [
  {
    id: "8",
    name: "Home",
    link: "/HomeScreen",
    Icon: HomeIcon,
    Icon1: HomeIcon_white,
  },
  {
    id: "5",
    name: "Upload",
    link: "/AddTemplate",
    Icon: UploadIcon_white,
    Icon1: UploadIcon,
  },
  {
    id: "6",
    name: "Customer Orders",
    link: "/CustomerOrders",
    Icon: customerIcon_white,
    Icon1: customerIcon,
  },
  {
    id: "11",
    name: "My Work",
    link: "/MyWork",
    Icon: myworkIcon_white,
    Icon1: myworkIcon,
  },
  {
    id: "12",
    name: "Rejected Uploads",
    link: "/RejectedTemplates",
    Icon: rejectedUpload_w,
    Icon1: rejectedUpload,
  }
];

export default dataEn;


