import { FormControl, Grid, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as VisibilityIcon } from "../../assets/images/ViewIcon1.svg";
import { ReactComponent as VisibilityOffIcon } from "../../assets/images/ViewIcon2.svg";

import jwt_decode from "jwt-decode";
import DeatilsTextFieldTitle from "../../components/layout-components/CustomInputLabel";
import CutomOuterlayoutLogin from "../../components/layout-components/CutomOuterlayoutLogin";
import UserService from "../../services/UserService";
import CommonUtil from "../../util/CommonUtils";
import useStyles from "../onboard/Styles";
import Loader from "../../components/Loader";

import { useTranslation } from "react-i18next";

export default function Login(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [message1, setMessage1] = useState("");

  const handleEmail = (e) => {
    const values = e.target.value;
    setUserName(values);
    if (values === '') {
      setMessage1(t("LOGIN007"));
    } else if (!CommonUtil.isValidEmailLogin(values)) {
      setMessage1(t("LOGIN008"))
    } else {
      UserService.verifyUser(values)
        .then((res) => {
          if (res.data.code === "UASE0074") {
            setMessage1(t("LOGIN009"));
          }
          else {
            setMessage1('');
          }
        })
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [loginOpen, setLoginOpen] = useState(false);
  const handleLoginClose = () => {
    setLoginOpen(false);
  };


  const [loading, setLoading] = useState(false);

  const handleVerifyEmail = () => {
    if (message1 === "") {
      setLoading(true);
      const payload = {
        userName,
        password,
      };

      if (userName === "" && password === "") {
        setLoading(false);
        toast.error(t("LOGIN012"), {
          position: "top-right",
        });
        return;
      }

      if (userName === "") {
        setLoading(false);
        toast.error(t("LOGIN013"), {
          position: "top-right",
        });
        return;
      }

      if (password === "") {
        setLoading(false);
        toast.error(t("LOGIN014"), {
          position: "top-right",
        });
        return;
      }

      if (!CommonUtil.isValidEmailLogin(userName)) {
        setLoading(false);
        toast.error(t("LOGIN015"), {
          position: "top-right",
        });
        return;
      }
      setLoading(true);
      UserService.autorize(payload)
        .then((res) => {
         
          if (res.status === 200) {
            if (res.data.code === "ZKCSAI0001" || res.data.code === "UASI0011") {
              localStorage.setItem("USER_TOKEN", res.data.data.access_token);
              localStorage.setItem("REFRESH_TOKEN", res.data.data.refresh_token);
              const decoded = jwt_decode(localStorage.getItem("USER_TOKEN"));
              localStorage.setItem("companyId", decoded.companyId);
              localStorage.setItem("companyCode", decoded.companyCode);
              localStorage.setItem("companyName", decoded.companyName);
              localStorage.setItem("USER_NAME", decoded.user_name);
              localStorage.setItem("ROLE_NAME", decoded.roleCode);
              localStorage.setItem("USER_ID", decoded.userId);
              localStorage.setItem("FIRST_NAME", decoded.firstName + ' ' + decoded.lastName);
              UserService.getUser(userName).then((res) => {
                if (res.data.code === "UASI0033") {
                  localStorage.setItem("COUNTRY", res.data.data.country);
                }
                navigate('/HomeScreen');
              });
              setLoading(false);

            } else {
              setUserName('')
              setPassword('')
              toast.error( t("LOGIN010"), {
                position: "top-right",
              });
              setLoading(false);
            }
          } else {
            toast.error(res.data.message, {
              position: "top-right",
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          toast.error(t("LOGIN011"), {
            position: "top-right",
          });
          console.error(error);
          setLoading(false);
        });
    }
  };

  return (
    <Grid container>
      <CutomOuterlayoutLogin
        headerTitle={t("LOGIN001")}
        buttonName={t("LOGIN002")}
        handldeButton={handleVerifyEmail}
        onClose={props.onClose}
        handleCreateAccount={props.handleCreateAccount}
        handleForgotPass={props.handleForgotPass}
      >
        {loading ?
          <>
            <Loader />
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} mb={2}>
                <DeatilsTextFieldTitle titleValue={t("LOGIN003")} required />
                <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                  <OutlinedInput
                    name="userName"
                    onChange={handleEmail}
                    placeholder={t("LOGIN004")}
                    id="outlined-required"
                  />
                </FormControl>
                {message1 && <div className={classes.redColor}>{message1}</div>}
              </Grid>
              <Grid item xs={12} mb={1}>
                <DeatilsTextFieldTitle titleValue={t("LOGIN005")} required />
                <FormControl variant="outlined" fullWidth margin="normal" id="password" sx={{ mt: '0px' }}>
                  <OutlinedInput
                    name="password"
                    value={password}
                    onChange={handlePassword}
                    placeholder={t("LOGIN006")}
                    id="outlined-adornment-password"
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
          :
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} mb={2}>
              <DeatilsTextFieldTitle titleValue={t("LOGIN003")} required />
              <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                <OutlinedInput
                  name="userName"
                  onChange={handleEmail}
                  placeholder={t("LOGIN004")}
                  id="outlined-required"
                />
              </FormControl>
              {message1 && <div className={classes.redColor}>{message1}</div>}
            </Grid>
            <Grid item xs={12} mb={1}>
              <DeatilsTextFieldTitle titleValue={t("LOGIN005")} required />
              <FormControl variant="outlined" fullWidth margin="normal" id="password" sx={{ mt: '0px' }}>
                <OutlinedInput
                  name="password"
                  value={password}
                  onChange={handlePassword}
                  placeholder={t("LOGIN006")}
                  id="outlined-adornment-password"
                  autoComplete="off"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        }
      </CutomOuterlayoutLogin>
    </Grid>
  );
}
