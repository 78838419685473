import { makeStyles } from "@material-ui/core/styles";
import { DialogActions, Grid, IconButton, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";
import closeIcon from "../../assets/images/close icon.svg";
import Demo from '../../components/layout-components/demo';
import { t } from "i18next";


const useStyles = makeStyles({
  gridContainer: {},
  gridItem: {},
  left_img: {
    width: "100%",
    margin: "auto",
    display: "block",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
});


export default function CutomOuterlayoutSignup(props) {
  const {
    buttonName,
    handldeButton,
    stepsValue,
    headerTitle,
    handleClose,
    handleAlreadyAccount
  } = props;
  const steps = stepsValue;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <Grid container >
      <Grid item xs={false} sm={4} md={5} className="login-image" />
      <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} width={'850px'}>
        <Box sx={{ my: 7, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center'}}>

          <DialogActions style={{ position: 'absolute', top: '7px', right: '7px', }}>
            <IconButton onClick={props.onClose}>
              <img alt="closeIcon" src={closeIcon} ></img>
            </IconButton>
          </DialogActions>

          <Typography font="normal normal medium 28px/34px Roboto" alignItems={'flex-start'} sx={{ pb: '20px' }} fontSize={"22px"} color={"#474B4F"}>
            <strong>{headerTitle}</strong>
          </Typography>
          <Box component="form" noValidate>
            {props.children}
          </Box>
          <Grid container>
            <Button id="sigup-createButton"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, }}
              onClick={handldeButton}
            >
              {buttonName}
            </Button>
          </Grid>
          <Grid container justifyContent='center'>
            <Typography variant="body2" align="center" sx={{ font: "normal normal normal 12px/19px Roboto", color: "#474B4F", opacity: "0.8" }}>
              {t("REG18")}
              <Button id="signup-loginButton" onClick={handleAlreadyAccount} sx={{ padding: '2px!important' }} style={{font: "normal normal normal 12px/19px Roboto", opacity: "0.8", color: '#1487CA', minWidth:'0px', padding:'0px!important' }}>
              {t("REG19")}
              </Button>
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
