import { Dialog } from '@mui/material'
import React from 'react'
import Login from '../../pages/onboard/Login';

function demo(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };
    return (
        <div>
            <Dialog onClose={handleClose} open={open} style={{backdropFilter:'blur(5px)'}}
                top="140px"
                left="260px"
                maxWidth="1100px"
                maxHeight="800px"
                opacity="1"
                overflowY="hidden"
                overflowX="hidden"
            >

                <Login top="140px" onClose={handleClose} 
                    left="260px"
                    maxWidth="1100px"
                    maxHeight="800px"
                    opacity="1"
                    overflowY="hidden"
                    overflowX="hidden"></Login>
            </Dialog>
        </div>
    )
}

export default demo
