const countries = [
  {
    country: 'Afghanistan',
  },
  {
    country: 'Aland Islands, Finland',
  },
  {
    country: 'Albania',
  },
  {
    country: 'Algeria',
  },
  {
    country: 'American Samoa, United States',
  },
  {
    country: 'Andorra',
  },
  {
    country: 'Angola',
  },
  {
    country: 'Anguilla, United Kingdom',
  },
  {
    country: 'Antarctica, Antarctic Treaty',
  },
  {
    country: 'Antigua and Barbuda',
  },
  {
    country: 'Ascension Island, United Kingdom',
  },
  {
    country: 'Argentina',
  },
  {
    country: 'Armenia',
  },
  {
    country: 'Aruba, Netherlands',
  },
  {
    country: 'Australia',
  },
  {
    country: 'Austria',
  },
  {
    country: 'Azerbaijan',
  },
  {
    country: 'Bahamas',
  },
  {
    country: 'Bahrain',
  },
  {
    country: 'Bangladesh',
  },
  {
    country: 'Barbados',
  },
  {
    country: 'Belarus',
  },
  {
    country: 'Belgium',
  },
  {
    country: 'Belize',
  },
  {
    country: 'Benin',
  },
  {
    country: 'Bermuda,United Kingdom',
  },
  {
    country: 'Bhutan',
  },
  {
    country: 'Bolivia',
  },
  {
    country: 'Bonaire, Netherlands',
  },
  {
    country: 'Sint Eustatius,Netherlands',
  },
  {
    country: 'Saba,Netherlands',
  },
  {
    country: 'Bosnia and Herzegovina',
  },
  {
    country: 'Botswana',
  },
  {
    country: 'Bouvet Island, Norway',
  },
  {
    country: 'Brazil',
  },
  {
    country: 'British Indian Ocean Territory',
  },
  {
    country: 'Brunei Darussalam',
  },
  {
    country: 'Bulgaria',
  },
  {
    country: 'Burkina Faso',
  },
  {
    country: 'Burundi',
  },
  {
    country: 'Cabo Verde',
  },
  {
    country: 'Cambodia',
  },
  {
    country: 'Cameroon',
  },
  {
    country: 'Canada',
  },
  {
    country: 'Cayman Islands, United Kingdom'
  },
  {
    country: 'capeverde',
  },
  {
    country: 'Central African Republic',
  },
  {
    country: 'Chad',
  },
  {
    country: 'Chile',
  },
  {
    country: 'China',
  },
  {
    country: 'Christmas Island, Australia',
  },
  {
    country: 'Cocos (Keeling) Islands, Australia',
  },
  {
    country: 'Colombia',
  },
  {
    country: 'Comoros',
  },
  {
    country: 'Congo (Brazzaville)',
  },
  {
    country: 'Congo, (Kinshasa)',
  },
  {
    country: 'Cook Islands, New Zealand',
  },
  {
    country: 'Costa Rica',
  },
  {
    country: 'Cote d Ivoire',
  },
  {
    country: 'Croatia',
  },
  {
    country: 'Cuba',
  },
  {
    country: 'Curaçao,Netherlands',
  },
  {
    country: 'Cyprus',
  },
  {
    country: 'Czech Republic',
  },
  {
    country: 'Denmark',
  },
  {
    country: 'Dem congo',
  },
  {
    country: 'Djibouti',
  },
  {
    country: 'Dominica',
  },
  {
    country: 'Dominican Republic',
  },
  {
    country: 'East Timor',
  },
  {
    country: 'Ecuador',
  },
  {
    country: 'Egypt',
  },
  {
    country: 'El Salvador',
  },
  {
    country: 'Equatorial Guinea',
  },
  {
    country: 'Eritrea',
  },
  {
    country: 'Estonia',
  },
  {
    country: 'Eswatini',
  },
  {
    country: 'Ethiopia',
  },
  {
    country: 'Falkland Islands, United Kingdom',
  },
  {
    country: 'Faroe Islands, Denmark',
  },
  {
    country: 'Fiji',
  },
  {
    country: 'Finland',
  },
  {
    country: 'France',
  },
  {
    country: 'French Guiana, France',
  },
  {
    country: 'French Polynesia, France',
  },
  {
    country: 'French Southern Territories, France',
  },
  {
    country: 'Gabon',
  },
  {
    country: 'Gambia',
  },
  {
    country: 'Georgia',
  },
  {
    country: 'Germany',
  },
  {
    country: 'Ghana',
  },
  {
    country: 'Gibraltar, United Kingdom',
  },
  {
    country: 'Greece',
  },
  {
    country: 'Greenland, Denmark',
  },
  {
    country: 'Grenada',
  },
  {
    country: 'Guadeloupe, France',
  },
  {
    country: 'Guam, United States',
  },
  {
    country: 'Guatemala',
  },
  {
    country: 'Guernsey, British Crown',
  },
  {
    country: 'Guinea',
  },
  {
    country: 'Guinea-Bissau',
  },
  {
    country: 'Guyana',
  },
  {
    country: 'Haiti',
  },
  {
    country: 'Heard Island and McDonald Islands, Australia',
  },
  {
    country: 'Holy See',
  },
  {
    country: 'Honduras',
  },
  {
    country: 'Hong Kong, China',
  },
  {
    country: 'Hungary',
  },
  {
    country: 'Iceland',
  },
  {
    country: 'India',
  },
  {
    country: 'Indonesia',
  },
  {
    country: 'Iran',
  },
  {
    country: 'Iraq',
  },
  {
    country: 'Ireland',
  },
  {
    country: 'Isle of Man, British Crown',
  },
  {
    country: 'Israel',
  },
  {
    country: 'Italy',
  },
  {
    country: 'Ivory coast',
  },
  {
    country: 'Jamaica',
  },
  {
    country: 'Japan',
  },
  {
    country: 'Jersey, British Crown',
  },
  {
    country: 'Jordan',
  },
  {
    country: 'Kazakhstan',
  },
  {
    country: 'Kenya',
  },
  {
    country: 'Kiribati',
  },
  {
    country: 'Korea, Democratic Peoples Republic of Korea',
  },
  {
    country: 'Korea, Republic of South Korea',
  },
  {
    country: 'Kuwait',
  },
  {
    country: 'Kyrgyzstan',
  },
  {
    country: "Lao People's Democratic Republic",
  },
  {
    country: 'Latvia',
  },
  {
    country: 'Lebanon',
  },
  {
    country: 'Lesotho',
  },
  {
    country: 'Liberia',
  },
  {
    country: 'Libya',
  },
  {
    country: 'Liechtenstein',
  },
  {
    country: 'Lithuania',
  },
  {
    country: 'Luxembourg',
  },
  {
    country: 'Macau, China',
  },
  {
    country: 'North Macedonia',
  },
  {
    country: 'Madagascar',
  },
  {
    country: 'Malawi',
  },
  {
    country: 'Malaysia',
  },
  {
    country: 'Maldives',
  },
  {
    country: 'Mali',
  },
  {
    country: 'Malta',
  },
  {
    country: 'Marshall Islands',
  },
  {
    country: 'Martinique, France',
  },
  {
    country: 'Mauritania',
  },
  {
    country: 'Mauritius',
  },
  {
    country: 'Mayotte, France',
  },
  {
    country: 'Mexico',
  },
  {
    country: 'Micronesia',
  },
  {
    country: 'Moldova',
  },
  {
    country: 'Monaco',
  },
  {
    country: 'Mongolia',
  },
  {
    country: 'Montenegro',
  },
  {
    country: 'Montserrat, United Kingdom',
  },
  {
    country: 'Morocco',
  },
  {
    country: 'Mozambique',
  },
  {
    country: 'Myanmar',
  },
  {
    country: 'Namibia',
  },
  {
    country: 'Nauru',
  },
  {
    country: 'Nepal',
  },
  {
    country: 'Netherlands',
  },
  {
    country: 'Netherlands Antilles,Netherlands',
  },
  {
    country: 'New Caledonia, France',
  },
  {
    country: 'New Zealand',
  },
  {
    country: 'Nicaragua',
  },
  {
    country: 'Niger',
  },
  {
    country: 'Nigeria',
  },
  {
    country: 'Niue, New Zealand',
  },
  {
    country: 'Norfolk Island, Australia',
  },
  {
    country: 'Northern Mariana Islands, United States',
  },
  {
    country: 'Norway',
  },
  {
    country: 'Oman',
  },
  {
    country: 'Pakistan',
  },
  {
    country: 'Palau',
  },
  {
    country: 'Palestine',
  },
  {
    country: 'Panama',
  },
  {
    country: 'Papua New Guinea',
  },
  {
    country: 'Paraguay',
  },
  {
    country: 'Peru',
  },
  {
    country: 'Philippines',
  },
  {
    country: 'Pitcairn, United Kingdom',
  },
  {
    country: 'Poland',
  },
  {
    country: 'Portugal',
  },
  {
    country: 'Puerto Rico, United States',
  },
  {
    country: 'Qatar',
  },
  {
    country: 'Romania',
  },
  {
    country: 'Russian Federation',
  },
  {
    country: ' Réunion,France',
  },
  {
    country: 'Rwanda',
  },
  {
    country: 'Saint Barthelemy, France',
  },
  {
    country: 'Saint Helena, United Kingdom',
  },
  {
    country: 'Saint Kitts and Nevis',
  },
  {
    country: 'Saint Lucia',
  },
  {
    country: 'Saint Martin, France',
  },
  {
    country: 'Saint Pierre and Miquelon, France',
  },
  {
    country: 'Saint Vincent and the Grenadines',
  },
  {
    country: 'Samoa',
  },
  {
    country: 'San Marino',
  },
  {
    country: 'Sao Tome and Principe',
  },
  {
    country: 'Saudi Arabia',
  },
  {
    country: 'Senegal',
  },
  {
    country: 'Serbia',
  },
  {
    country: 'Seychelles',
  },
  {
    country: 'Sierra Leone',
  },
  {
    country: 'Singapore',
  },
  {
    country: 'Sint Maarten (Dutch part),Netherlands',
  },
  {
    country: 'St Maarten,Netherlands'
  },
  {
    country: 'St Vincent'
  },
  {
    country: 'St Pierre,France'
  },
  {
    country: 'Slovakia',
  },
  {
    country: 'Slovenia',
  },
  {
    country: 'Solomon Islands',
  },
  {
    country: 'Somalia',
  },
  {
    country: 'South Africa',
  },
  {
    country: 'South Georgia and the South Sandwich Islands,United Kingdom',
  },
  {
    country: 'South Korea',
  },
  {
    country: 'South Sudan',
  },
  {
    country: 'Spain',
  },
  {
    country: 'Sri Lanka',
  },
  {
    country: 'Sudan',
  },
  {
    country: 'Suriname',
  },
  {
    country: 'Svalbard,Norway',
  },
  {
    country: 'Jan Mayen,Norway',
  },
  {
    country: 'Swaziland (Eswatini)',
  },
  {
    country: 'Sweden',
  },
  {
    country: 'Switzerland',
  },
  {
    country: 'Syrian Arab Republic',
  },
  // {
  //   country: 'Taiwan',
  // },
  {
    country: 'Tajikistan',
  },
  {
    country: 'Tanzania',
  },
  {
    country: 'Thailand',
  },
  {
    country: 'Timor-Leste',
  },
  {
    country: 'Togo',
  },
  {
    country: 'Tokelau, New Zealand',
  },
  {
    country: 'Tonga',
  },
  {
    country: 'Trinidad and Tobago',
  },
  {
    country: 'Tunisia',
  },
  {
    country: 'Turkey',
  },
  {
    country: 'Turkmenistan',
  },
  {
    country: 'Turks and Caicos Islands, United Kingdom',
  },
  {
    country: 'Tuvalu',
  },
  {
    country: 'Tristan da Cunha, United Kingdom',
  },
  {
    country: 'Uganda',
  },
  {
    country: 'Ukraine',
  },
  {
    country: 'United Arab Emirates ',
  },
  {
    country: 'United Kingdom',
  },
  {
    country: 'United States Minor Outlying Islands,United States',
  },
  {
    country: 'United States of America',
  },
  {
    country: 'Uruguay',
  },
  {
    country: 'Uzbekistan',
  },
  {
    country: 'Vanuatu',
  },
  {
    country: 'Venezuela',
  },
  {
    country: 'Vietnam',
  },
  {
    country: 'Virgin Islands (British),United Kingdom',
  },
  {
    country: 'Virgin Islands (U.S.),United States',
  },
  {
    country: 'Wallis and Futuna, France ',
  },
  {
    country: 'Yemen',
  },
  {
    country: 'Zambia',
  },
  {
    country: 'Zimbabwe',
  },
];

export const countryList = countries;
