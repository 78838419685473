import React, { useContext } from 'react';
import sidebarContext from '../../util/sidebarContext';
import CommonUtil from '../../util/CommonUtils';

export default function RefreshToken() {
  const { tokenRefreshTimer, setTokenRefreshTimer } = useContext(sidebarContext);

  React.useEffect(() => {
    console.warn('Start Init Refreshed token @' + tokenRefreshTimer);
    if(localStorage.getItem('SET_TIMER') === "true" || CommonUtil.isEmptyString(tokenRefreshTimer) ){
      if(localStorage.getItem('USER_TOKEN')){
        const decoded = CommonUtil.getDecodedUserToken();
        console.warn('Intial refresh token timer ser @'+new Date());
        var currentSeconds = Math.floor(Date.now() / 1000);
        var secondsLeft = decoded.exp - currentSeconds;
        var timer = Math.floor(secondsLeft / 60) - 5;
        timer = timer * 60 * 1000;
        console.warn('Next token generation after', timer + ' ms');
        setTokenRefreshTimer(timer)
        localStorage.setItem('SET_TIMER', "false")
      }
    } else if (tokenRefreshTimer > 3000) {
      setTimeout(() => {
        refreshToken();
      }, tokenRefreshTimer);
    }
  }, [tokenRefreshTimer]);

  
  const refreshToken = async () => {
    var decoded_jwtToken = CommonUtil.getDecodedUserToken();
    var payload = {
      refreshToken: localStorage.getItem('REFRESH_TOKEN').replace('Bearer ', ''),
      companyCode: decoded_jwtToken.companyCode,
    };
    const settings = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('USER_TOKEN'),
      },
      body: JSON.stringify(payload),
    };
    try {
      const fetchResponse = await fetch(process.env.REACT_APP_SERVER_URL + `/web/user/refresh_token`, settings);
      const res = await fetchResponse.json();
      if (res.code === 'UASI0011') {
        console.log('Refreshed token @' + new Date());
        console.log('USER_TOKEN Bearer ' + res.data.access_token);
        console.log('R_TOKEN Bearer ' + res.data.refresh_token);

        localStorage.setItem('USER_TOKEN', 'Bearer ' + res.data.access_token);
        localStorage.setItem('REFRESH_TOKEN', 'Bearer ' + res.data.refresh_token);
        localStorage.setItem('isLoading', false);
        window.dispatchEvent(new Event('storage'));

        CommonUtil.setCookie('Content_token', 'Bearer ' + res.data.access_token, 1);
        CommonUtil.setCookie('Content_token_refresh_token', 'Bearer ' + res.data.refresh_token, 1);

        const decoded = CommonUtil.getDecodedUserToken();
        localStorage.setItem('USER', JSON.stringify(decoded));

        console.log('Refreshed token');
        var currentSeconds = Math.floor(Date.now() / 1000);
        var secondsLeft = decoded.exp - currentSeconds;
        var timer = Math.floor(secondsLeft / 60) - 5;
        timer = timer * 60 * 1000;
        console.warn('Next token generation after', timer + ' ms');
        // setTokenRefreshTimer(timer)
        setTokenRefreshTimer(timer)

      }
    } catch (e) {
      return e;
    }
  };
  return <></>;
}
