import { Box, Button, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { toast } from "react-toastify";
import RightViewLayout from "../../../components/RighViewLayout";
import UserService from "../../../services/UserService";
import useStyles from '../../onboard/Styles';
import CustomerService from "../../../services/CustomerService";
import DeatilsTextFieldTitle from "../../../components/layout-components/CustomInputLabel";
import { ReactComponent as VisibilityIcon } from "../../../assets/images/ViewIcon1.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../assets/images/ViewIcon2.svg";
import { t } from "i18next";

export default function UpdatePassword() {

    const classes = useStyles();
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);

    const userId = localStorage.getItem('USER_NAME');

    const handleCancel = () => {
        navigate("/HomeScreen");
    };

    const passPattern = /^(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[!@#$%^&*])\S{8,24}$/;

    const oldPasswordHandler = (event) => {
        const values = event.target.value;
        setOldPassword(values)
        if (values.trim() === '') {
            setMessage1(t("PRO21"));
        }
        else if (!values.match(passPattern)) {
            setMessage1(t("PRO22"));
        }
        else {
            setMessage1("");
        }
    };

    const newPasswordHandler = (event) => {
        const values = event.target.value;
        setNewPassword(values)
        if (values.trim() === '') {
            setMessage2(t("PRO21"));
        }
        else if (!values.match(passPattern)) {
            setMessage2(t("PRO22"));
        }
        else {
            setMessage2("");
        }
    };

    const confirmPasswordHandler = (event) => {
        const values = event.target.value;
        setConfirmPassword(values)
        if (values.trim() === '') {
            setMessage3(t("PRO23"));
        }
        else if (!values.match(newPassword)) {
            setMessage3(t("PRO24"));
        }
        else {
            setMessage3("");
        }

    }

    const handleSubmit = (e) => {
        if (message1 == '' && message2 == '' && message3 == '' && oldPassword !== '' && newPassword !== '' && confirmPassword !== '' && newPassword === confirmPassword) {
            e.preventDefault();

            const payload = {
                oldPassword,
                newPassword,
                confirmPassword
            }
            console.log(payload + "palyload")

            CustomerService.updatePassword(userId, payload).then((res) => {
                if (res.data.code === "UASI0005") {
                    UserService.logout().then((data) => {
                        if (data.data.code === "ZKCSAI0001") {
                            navigate("/");
                            localStorage.removeItem('companyCode')
                            localStorage.removeItem('companyName')
                            localStorage.removeItem('USER_TOKEN')
                            localStorage.removeItem('ROLE_NAME')
                            localStorage.removeItem('USER_NAME')
                            localStorage.removeItem('FIRST_NAME')
                            localStorage.removeItem('REFRESH_TOKEN')
                            localStorage.removeItem('companyId')
                            localStorage.removeItem('COUNTRY')
                            toast.success(t("PRO25"), {
                                position: "top-right",
                            });
                        }
                    });
                }
                else if (res.data.code === "ZKCSAE0008") {
                    toast.error(t("PRO27"), {
                        position: "top-right",
                    });
                } else {
                    toast.error(res.data.message, {
                        position: "top-right",
                    });
                }
            });

        } else {
            if (oldPassword === '') {
                setMessage1(t("PRO26"))
            }
            if (newPassword === '') {
                setMessage2(t("PRO26"))
            }
            if (confirmPassword === '') {
                setMessage3(t("PRO26"))
            }
            if (newPassword !== confirmPassword) {
                setMessage3(t("PRO24"))
            }
        }

    }

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword((show) => !show);
    };

    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowNewConfirmPassword = () => {
        setShowNewConfirmPassword((show) => !show);
    };

    const handleMouseDownNewConfirmPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Grid >
            <RightViewLayout
                title={t("PRO10")}
                navigateBack={'/HomeScreen'}
            >
                <Grid container xs={12} display="flex" flexDirection="row"
                    style={{ backgroundColor: '#fff', border: '0.5px solid #eeeeee', paddingBottom: '10px' }}>
                    <Grid item xs={12} sm={12} style={{ paddingLeft: "28px", paddingRight: "28px", paddingTop: '30px' }} >
                        <DeatilsTextFieldTitle titleValue={t("PRO11")} required />
                        <FormControl variant="outlined" fullWidth margin="normal" id="oldPassword" >
                            <OutlinedInput
                                name="oldPassword"
                                value={oldPassword}
                                onChange={oldPasswordHandler}
                                placeholder={t("PRO12")}
                                id="outlined-adornment-password"
                                autoComplete="off"
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {message1 && <div className={classes.redColor}>{message1}</div>}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: "28px", paddingRight: "28px", paddingTop: '30px' }} >
                        <DeatilsTextFieldTitle titleValue={t("PRO13")} required />
                        <FormControl variant="outlined" fullWidth margin="normal" id="newPassword" >
                            <OutlinedInput
                                name="newPassword"
                                value={newPassword}
                                onChange={newPasswordHandler}
                                placeholder={t("PRO14")}
                                id="outlined-adornment-password"
                                autoComplete="off"
                                type={showNewPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowNewPassword}
                                            onMouseDown={handleMouseDownNewPassword}
                                            edge="end"
                                        >
                                            {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {message2 && <div className={classes.redColor}>{message2}</div>}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: "28px", paddingRight: "28px", paddingTop: '30px' }} >
                        <DeatilsTextFieldTitle titleValue="Confirm New Password" required />
                        <FormControl variant="outlined" fullWidth margin="normal" id="confirmPassword" >
                            <OutlinedInput
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={confirmPasswordHandler}
                                placeholder={t("PRO16")}
                                id="outlined-adornment-password"
                                autoComplete="off"
                                type={showNewConfirmPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowNewConfirmPassword}
                                            onMouseDown={handleMouseDownNewConfirmPassword}
                                            edge="end"
                                        >
                                            {showNewConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {message3 && <div className={classes.redColor}>{message3}</div>}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }} >
                        <Box display="flex" width={'100%'} justifyContent={"flex-end"} gap={'10px'} mt={'25px'} marginBottom={'20px'} marginRight={'27px'} >
                            <Button id="cancel-updatePassword" variant="outlined" color="primary" size="large" onClick={handleCancel}  >{t("TEM14")}</Button>
                            <Button id="submit-updatePassword" variant="contained" color="primary" size="large" onClick={handleSubmit}>{t("TEM16")}</Button>
                            {/* <Button variant="contained" color="error" size="medium" onClick={handleSubmit}>Delete Account</Button> */}
                        </Box>
                    </Grid>
                </Grid>
            </RightViewLayout>
        </Grid>
    );
}