import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  redAsterisk: {
    color: "#EF5656",
  },
  buttonConfirm: {
    backgroundColor: "#fff",
    color: "1487CA",
  },
  outcontain: {
    border: "1px solid #EF5656",
    margin: "auto",
    textAlign: "center",
  },
  inputChecks: {
    width: "100%",
    paddingBottom: "18px",
  },
  label: {
    color: "#304669",
  },
  redColor: {
    color: "#EF5656",
    fontFamily: "Roboto",
    fontSize: "11px",
    marginTop: "1%",
  },
  redColor1: {
    color: "#EF5656",
    fontFamily: "Roboto",
    fontSize: "11px",
    marginTop: "1%",
    marginLeft: '28px'
  },
  leftGrid: {
    width: "40%",
    height: "auto",
    background: "linear-gradient(0deg, #7FFFAF 0%, #E2FCEC 100%)",
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
  },
  leftGridText: {
    height: "100%",
    textAlign: "left",
    fontSize: "32px",
    fontStyle: "italic",
    color: "#304669FF",
    fontWeight: "bold",
    margin: "7.8% 4.2% 5% 3.6%",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  rightGrid: {
    width: "60%",
    height: "auto",
    display: "flex",
    alignItems: "center",
  },
  textLabel: {
    height: "15px !important",
    fontSize: "18px !important",
    fontFamily: "Lato !important",
    fontWeight: "400 !important",
    color: "#4B5669 !important",
  },
  inputField: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ADC0D1 !important",
      border: "1px solid !important",
      borderRadius: "6px",
    },
  },
  rightHeader: {
    fontSize: "32px !important",
    color: "#304669FF !important",
    fontWeight: "bold !important",
    marginTop: "4% !important",
    marginBottom: "3% !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
    },
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  labels: {
    fontSize:'14px',
    color:'#474B4F',
    opacity:'0.8'
  }
}));

export default useStyles;
