import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'
// import { ReactComponent as NoResultFoundPIC } from '../assets/images/icon_no_results_found.svg';

export const NoResultFound = (props) => {
  return (
    <>
      <Paper elevation={0}>
        <Typography style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          lineHeight: '450px',
          height: '450px'
        }}>
          {props.message ? props.message : 'No Result Found'}
        </Typography>
      </Paper>
    </>
  )
}
