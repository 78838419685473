import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';


const Loader = () => {
  const [loader, setLoader] = useState(false);

  const setLoaderFunction = () => {
    const isLoading = localStorage.getItem("isLoading");
    setLoader(isLoading === "true");
  };

  useEffect(() => {
    setLoader(true);

    const timerId = setTimeout(() => {
      setLoader(false);
      localStorage.setItem("isLoading", "false");
    }, 5000);

    window.addEventListener("storage", setLoaderFunction);
    return () => {
      clearTimeout(timerId);
      window.removeEventListener("storage", setLoaderFunction);
    };
  }, []);
  return (
    <div className="loader-overlay">
      <div className="loader">{loader}</div>
    </div>
  );
};

export default Loader;