import api from "../configurations/http-common";


export const saveChat = async (payload) => {
  return api.securedAxios().post("/web/message", payload);
};

export const getAllMessages = async (orderId) => {
  return api.securedAxios().get("/web/message/getAll/" + orderId);
};

export const getAllMessagesRead = async (orderId) => {
  return api.securedAxios().get("/web/message/getAllMessages/" + orderId);
};

export const getUnreadMessageCount = async (orderIds) => {
  debugger
  return api.securedAxios().get("/web/message/getUnreadMessageCount/" + orderIds);
};

const ChatService = {
  saveChat,
  getAllMessages,
  getAllMessagesRead,
  getUnreadMessageCount
};

export default ChatService;