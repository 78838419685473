import { Box, Grid, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { ReactComponent as CloseIcon } from "../assets/images/closeIcon.svg";

export default function CustomDialog(props) {
  const styleDialog = {
    "& .MuiDialog-paper": {
      position: "absolute",
      boxShadow: 1,
      minWidth: {
        xs: "80%",
        md: props.customWidth ? props.customWidth : "60%",
      },
      borderRadius: "12px",
      padding: "1rem",
      top: props.customWidth ? "none" : "4rem",
    },
  };
  return (
    <Dialog sx={styleDialog} open={props.open} onClose={() => props.onClose()}>
      <Box>
        <Box display="flex" justifyContent={"space-between"}>
          <Typography
            component="div"
            gutterBottom
            style={{ color: "#474B4F", fontWeight: "bold", opacity: '0.8', fontSize: '18px' }}
          >
            {props.title}
          </Typography>
          <IconButton disableRipple onClick={props.onClose} >
            <CloseIcon />
          </IconButton>
        </Box>
        {props.children}
      </Box>
    </Dialog>
  );
}
CustomDialog.defaultProps = {
  customWidth: "0px",
  open: true,
  onClose: () => {
    console.log("defaultProps");
  },
  title: "title",
  children: {},
};
