import React from "react";
import RightViewLayout from '../../components/RighViewLayout'

export default function Dashboard() {
  return (
    <RightViewLayout navigateBack={false}   title='Dashboard'>

      </RightViewLayout>
  );
}
