import React, { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";

const CommonUtil = {
  isValidEmail(email) {
    if (email === undefined || email === '') return true;
    const regex = /^[a-zA-Z0-9_!#$%&'*+=?`{|}~^-]+(?:\.[a-zA-Z0-9_!#$%&'*+=?`{|}~^-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(String(email).toLowerCase());
  },
  isValidEmailLogin(email) {
    if (email === undefined || email === '' ) return false;
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return regex.test(String(email).toLowerCase());
  },
  
  isValidMobile(mobile) {
    if (mobile === undefined || mobile === '') return true;
    const re = /^(\+\d{1,3}[- ]?)?\d{8,15}$/;
    return re.test(String(mobile));
  },

  isExceptNumericName(name) {
    if (name === undefined || name === '') return true;
    let finalString = name.replace(/[^\d.-]/g, '');
    return finalString;
  },

  isValidName(name) {
    if (name === undefined || name === '') return true;
    let regex = /^[a-zA-Z0-9]+[\'\s]?[a-zA-Z0-9 ]+$/;
    return regex.test(name);
  },
  isAlphaNumeric(data) {
    if (data === undefined || data === '') return true;
    let regex = /^[a-zA-Z0-9]+$/i;
    return regex.test(data);
  },

  isValidNumericName(name) {
    if (name === undefined || name === '') return true;
    let regex = /^[A-Za-z0-9 ]+$/;
    return regex.test(name);
  },

  isValidNumeric(name) {
    if (name === undefined || name === '') return true;
    let regex = /^[0-9\. ]+$/;
    return regex.test(name);
  },
  isValidPasitiveNumeric(name) {
    if (name === undefined || name === '') return true;
    let regex = /^[0-9]+$/;
    return regex.test(name);
  },
  isValidDoubleValue(value) {
    if (value === undefined || value === '') return true;
    let regex = /^[+-]?([1-9][0-9]*|0)(\.[0-9]+)?((e|E)[+-]?[0-9]+)?$/;
    return regex.test(value);
  },

  isValidCode(code) {
    if (code === undefined || code === '') return true;
    let regex = /^[a-zA-Z0-9_-]*$/;
    return regex.test(code);
  },
  isValidZipcode(areaCode) {
    if (areaCode.length < 5 || areaCode.length > 6 || !areaCode.match(/^[0-9 ]+$/)) return true;
  },
  isAlphaNumericSlash(code) {
    if (code === undefined || code === '') return true;
    let regex = /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _]*)$/;
    return regex.test(code);
  },

  isEmptyString(val) {
    if (val === null || val === undefined || val === '') return true;
    else return false;
  },
  isAlphaNumericSpace(data) {
    if (data == undefined || data === '') return true;
    let regex = /^[a-zA-Z0-9_ ]+$/i;
    return regex.test(data);
  },
  isAlphaNumericSpecialChar(data) {
    if (data == undefined || data === '') return true;
    let regex = /^[ A-Za-z0-9_@./#&+-]*$/;
    return regex.test(data);
  },
  isAlphaNumericUnderscore(data) {
    if (data == undefined || data === '') return true;
    let regex = /^[A-Za-z0-9_]*$/;
    return regex.test(data);
  },
  formatDayAndMonth(numValue) {
    if (numValue <= 0) {
      return '-' + (numValue + 12);
    }
    if (numValue < 10) {
      return '-0' + numValue;
    } else {
      return '-' + numValue;
    }
  },

  isEmpty(obj) {
    if (obj === null || obj === undefined) return true;
    return Object.keys(obj).length === 0;
  },
  getCompany() {
    return localStorage ? localStorage.getItem('companyId') : null;
  },

  getLocalDate(date) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let newDate = new Date(date);
    let formated =
      newDate.getDate() +
      '-' +
      monthNames[newDate.getMonth()].substring(0, 3) +
      '-' +
      newDate.getFullYear() +
      ', ' +
      (newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours()) +
      ':' +
      (newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes()) +
      ':' +
      (newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds());
    return formated;
  },

  getFormatedDate(date) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let newDate = new Date(date);
    let formated = newDate.getDate() + ', ' + monthNames[newDate.getMonth()] + ' ' + newDate.getFullYear();
    return formated;
  },

  handleTextOverflow(text) {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...';
    } else {
      return text;
    }
  },

  getLocalDate1(date) {
    let newDate = new Date(date);
    return newDate
      .toISOString()
      .replace('T', ' ')
      .substr(0, newDate.toISOString().lastIndexOf(':'));
  },

  getLocalTime(time) {
    let newTime = new Date(parseInt(time));
    return newTime.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });
  },

  sentenceCase(str) {
    if (str === null || str === '') return false;
    else str = str.toString();

    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
  getDefaultCountryCode() {
    let countryCode = '';
    if (localStorage.getItem('locationInfo')) {
      countryCode = JSON.parse(localStorage.getItem('locationInfo')).country_code.toLowerCase();
    }
    return countryCode;
  },
  getDefaultCallingCountryCode() {
    let countryCallingCode = '';
    if (localStorage.getItem('locationInfo')) {
      countryCallingCode = JSON.parse(localStorage.getItem('locationInfo')).country_calling_code.toLowerCase();
    }
    return countryCallingCode;
  },
  isValidPassword(password) {
    if (password === undefined || password === '') return true;
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/;
    return re.test(String(password));
  },

  formatLongText(text){
    if(text && text.length > 20){
      return text.substr(0, 19) + "...";
    } else {
      return text;
    }

  },
  getLocationDetails(query) {
    let res = '';
    if (localStorage.getItem('locationInfo')) {
      let location = JSON.parse(localStorage.getItem('locationInfo'));
      switch (query) {
        case 'ip': {
          res = location.ip;
          break;
        }
        case 'latitude': {
          res = location.latitude;
          break;
        }
        case 'longitude': {
          res = location.longitude;
          break;
        }
        case 'country_code': {
          res = location.country_code;
          break;
        }
        case 'country_name': {
          res = location.country_name;
          break;
        }
        case 'country_calling_code': {
          res = location.country_calling_code;
          break;
        }
      }
    }
    return res;
  },
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    if (window.location.href.includes('minervaiotdev')) {
      document.cookie = cname + '=' + cvalue + ';' + expires + ';domain=.minervaiotdev.com;path=/';
    } else if (window.location.href.includes('minervaiot')) {
      document.cookie = cname + '=' + cvalue + ';' + expires + ';domain=.minervaiot.com;path=/';
    } else {
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }
  },
  getCookie(cname) {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },

  getFormatedDateString(date) {
    let today = new Date(date);
    let fdate =
      (today.getDate() < 10 ? '0' + today.getDate() : today.getDate()) +
      '-' +
      (today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) +
      '-' +
      today.getFullYear();
    return fdate;
  },
  GetWindowDimenssion() {
    const [size, setSize] = useState({
      width: window.innerWidth + 'px',
      height: window.innerHeight + 'px',
    });
    const updateSize = () =>
      setSize({
        width: window.innerWidth + 'px',
        height: window.innerHeight + 'px',
      });
    useEffect(() => (window.onresize = updateSize), [size]);
    return size;
  },
  capitalizeFirst(str) {
    return typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  },
  getPageNumber(int) {
    if (int == 0) {
      return 1;
    } else {
      return int + 1;
    }
  },
  getDecodedUserToken() {
    try {
      const token = jwt_decode(localStorage.getItem("USER_TOKEN"));
      return token;
    } catch (e) {
      console.log("USER_TOKEN is invalid for common util")
      window.location.href = '/';
    }
  },
  getUserRefreshToken() {
    try {
      const token = jwt_decode(localStorage.getItem("REFRESH_TOKEN"));
      return token;
    } catch (e) {
      console.log("REFRESH_TOKEN  is invalid for common util")
      window.location.assign("/")
    }
  },
};

export default CommonUtil;
