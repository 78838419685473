export const REACT_DASHBOARD = "/dashboard";
export const REACT_DEPARTMENT = "/department";
export const REACT_EMPLOYEE = "/employee";
export const REACT_ADD_EMPLOYEE = "/addEmployee";
export const REACT_FORGOT_PASSWORD = '/partner-portal/forgot-password';
export const REACT_LOGIN = "/login";
export const REACT_SIGNUP = "/signUp";
export const REACT_LANDING = "/";
export const REACT_PREVIEW_IMAGE ="/previewImage"
export const REACT_ORDER_TEMPLATE ="/orderTemplate"
export const REACT_TEMPALTE_LIST = "/TemplateList"
export const REACT_CUSTOMER_ORDER = "/CustomerOrders"
export const REACT_MY_ORDERS = "/MyOrders"
export const REACT_ORDER_DETAILS = "/OrderDetails"
export const REACT_INDUSTRIES_LAYOUT = "/IndustriesLayout"
export const REACT_AUDIT_TEMPLATES = "/TemplateAuditList"
export const REACT_TEMPLATE_IMAGES = "/TemplateImages"
export const REACT_HOME_SCREEN = "/HomeScreen"
export const REACT_EDIT_TEMPLATE = "/EditTemplates"
export const REACT_BANNER_MANAGEMENT = "/BannerManagement"
export const REACT_PROFILE = "/profileDetails"
export const REACT_EDIT_PROFILE = "/EditProfileDetails"
export const REACT_UPDATE_PASSWORD = "/UpdatePassword"
export const REACT_INDUSTRIES_BEFORE_LOGIN = "/Industries"
export const REACT_TAGS = "/Tags"
export const REACT_ADD_TAGS = "/AddTags"
export const REACT_EDIT_TAGS = "/EditTags"



