import { Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout-components/MainLayout";
import {
  REACT_DASHBOARD, REACT_DEPARTMENT, REACT_EMPLOYEE,
  REACT_LANDING, REACT_LOGIN, REACT_SIGNUP, REACT_ADD_EMPLOYEE, REACT_PREVIEW_IMAGE, REACT_ORDER_TEMPLATE, REACT_TEMPALTE_LIST, REACT_CUSTOMER_ORDER, REACT_MY_ORDERS, REACT_ORDER_DETAILS, REACT_FB_GALLERY, REACT_INDUSTRIES_LAYOUT, REACT_AUDIT_TEMPLATES, REACT_TEMPLATE_IMAGES, REACT_EDIT_TEMPLATE, REACT_BANNER_MANAGEMENT, REACT_PROFILE, REACT_EDIT_PROFILE, REACT_UPDATE_PASSWORD, REACT_CHAT_BOX, REACT_ZDummy, REACT_TAGS, REACT_ADD_TAGS, REACT_EDIT_TAGS
} from "./constants/ReactEndPoints";
import Dashboard from "./pages/dashboard/Index";
import Login from "./pages/onboard/Login";
import Landing from "./pages/onboard/LandingPage";
import SignupUser from "./pages/onboard/SingupUser";
import ForgotPassword from "./pages/onboard/ForgotPassword";
import TemplateList from "./pages/person/templates/TemplatesList";
import Employee from "./pages/person/employee/employee";
import EmployeeAdd from "./pages/person/employee/EmployeeAdd";

import RejectedTemplates from "./pages/person/templates/RejectedTemplates"
import HomeScreen from "./pages/person/home/HomeScreen";
import PreviewTemplate from "./pages/person/templates/PreviewTemplate";
import OrderTemplate from "./pages/person/templates/OrderTemplate";
import CustomerOrders from "./pages/person/templates/CustomerOrders";
import MyOrders from "./pages/person/templates/MyOrders";
import OrderDetails from "./pages/person/templates/OrderDetails"
import MyWork from "./pages/person/templates/MyWork"
import UploadTemplate from "./pages/person/templates/UploadTemplate";
import IndustriesLayout from "./components/layout-components/IndustriesLayout";
import TemplateAuditList from "./pages/person/templates/TemplateAuditList";
import TemplateImages from "./pages/person/templates/TemplateImages";
import EditTemplates from "./pages/person/templates/EditTemplates";
import BannerManagement from "./pages/person/templates/BannerManagement";
import ProfileDetails from "./pages/person/employee/ProfileDetails";
import EditProfileDetails from "./pages/person/employee/EditProfileDetails";
import UpdatePassword from "./pages/person/employee/UpdatePassword";
import IndustriesLayoutBeforeLogin from "./components/layout-components/IndustriesLayoutBeforeLogin";
import Tags from "./pages/person/templates/Tags";
import TagsAdd from "./pages/person/templates/TagsAdd";
import TagsEdit from "./pages/person/templates/TagsEdit";


export default function AppRoutes() {
  return (
    <Routes>
      <Route path={"/"} element={<Landing />} />
      <Route path={"/ForgotPassword"} element={<ForgotPassword />} />
      <Route path={"/login"} element={<Login />} />
      <Route path={"/SingupUser"} element={<SignupUser />} />
      {<Route path={"/TemplatesList"} element={<MainLayout><TemplateList /></MainLayout>} />}
      <Route path={"/UserList"} element={<MainLayout><Employee /></MainLayout>} />
      <Route path={"/AddUser"} element={<MainLayout><EmployeeAdd /></MainLayout>} />
      <Route path={"/AddTemplate"} element={<MainLayout><UploadTemplate /></MainLayout>} />
      <Route path={"/RejectedTemplates"} element={<MainLayout><RejectedTemplates /></MainLayout>} />
      <Route path={"/HomeScreen"} element={<MainLayout><HomeScreen /></MainLayout>} />
      <Route path={"/MyWork"} element={<MainLayout><MyWork /></MainLayout>} />
      <Route path={"/Industries"} element={<IndustriesLayoutBeforeLogin />} />
      <Route path={REACT_DASHBOARD} element={<MainLayout><Dashboard /></MainLayout>} />
      <Route path={REACT_ORDER_TEMPLATE} element={<MainLayout><OrderTemplate /></MainLayout>} />
      <Route path={REACT_PREVIEW_IMAGE} element={<MainLayout><PreviewTemplate /></MainLayout>} />
      <Route path={REACT_ORDER_DETAILS} element={<MainLayout><OrderDetails /></MainLayout>} />
      <Route path={REACT_CUSTOMER_ORDER} element={<MainLayout><CustomerOrders /></MainLayout>} />
      <Route path={REACT_MY_ORDERS} element={<MainLayout><MyOrders /></MainLayout>} />
      <Route path={REACT_INDUSTRIES_LAYOUT} element={<MainLayout><IndustriesLayout /></MainLayout>} />
      <Route path={REACT_AUDIT_TEMPLATES} element={<MainLayout><TemplateAuditList /></MainLayout>} />
      <Route path={REACT_TEMPLATE_IMAGES} element={<MainLayout><TemplateImages /></MainLayout>} />
      <Route path={REACT_TEMPALTE_LIST} element={<MainLayout><TemplateList /></MainLayout>} />
      <Route path={REACT_EDIT_TEMPLATE} element={<MainLayout><EditTemplates /></MainLayout>} />
      <Route path={REACT_BANNER_MANAGEMENT} element={<MainLayout><BannerManagement /></MainLayout>} />
      <Route path={REACT_PROFILE} element={<MainLayout><ProfileDetails /></MainLayout>} />
      <Route path={REACT_EDIT_PROFILE} element={<MainLayout><EditProfileDetails /></MainLayout>} />
      <Route path={REACT_UPDATE_PASSWORD} element={<MainLayout><UpdatePassword /></MainLayout>} />
      <Route path={REACT_TAGS} element={<MainLayout><Tags /></MainLayout>} />
      <Route path={REACT_ADD_TAGS} element={<MainLayout><TagsAdd /></MainLayout>} />
      <Route path={REACT_EDIT_TAGS} element={<MainLayout><TagsEdit /></MainLayout>} />
      <Route
        path={REACT_LANDING}
        element={
          <MainLayout pageTitle={'Landing'}>
            {" "}
            <Landing />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_DEPARTMENT}
        element={
          <MainLayout pageTitle={'Department'}>
            {" "}
            <Landing />{" "}
          </MainLayout>
        }
      />
      <Route
        path={REACT_EMPLOYEE}
        element={
          <MainLayout pageTitle={'Users'}>
            {" "}
            <Landing />{" "}
          </MainLayout>
        }
      />
    </Routes>
  );
}
