
import { FormControl, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import DeatilsTextFieldTitle from "../../components/layout-components/CustomInputLabel";
import CutomOuterlayoutSignup from '../../components/layout-components/CustomOuterLayoutSignup';
import { REACT_LANDING } from '../../constants/ReactEndPoints';
import CustomerService from '../../services/CustomerService';
import useStyles from "../onboard/Styles";
import Loader from "../../components/Loader";
import { t } from "i18next";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { countryList } from "../../util/CountryList";

export default function SignupUser(props) {

    const navigate = useNavigate();
    const classes = useStyles();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [phoneCountryCode, setPhoneCountryCode] = useState("62")
    const [country, setCountry] = useState("")
    const [status, setStatus] = useState("ACTIVE")
    const [siteId, setSiteId] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setComfimPassword] = useState("")

    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [message4, setMessage4] = useState("");
    const [message5, setMessage5] = useState("");
    const [message6, setMessage6] = useState("");
    const [message7, setMessage7] = useState("");
    const [message8, setMessage8] = useState("");

    const handleClose = () => {
        navigate(REACT_LANDING);
    };

    const firstNameHandler = (event) => {
        const values = event.target.value;
        const capitalizedValue3 = values.charAt(0).toUpperCase() + values.slice(1).toLowerCase();
        setFirstName(capitalizedValue3)
        if (values.trim() === '') {
            setMessage1(t('REG25'));
        } else if (!/^[a-zA-Z]+$/.test(values)) {
            setMessage1(t('REG26'));
        } else if (!/^.{1,23}$/g.test(values)) {
            setMessage1(t('REG27'));
        }
        else {
            setMessage1('')
        }

    }

    const lastNameHandler = (event) => {
        const values = event.target.value;
        const capitalizedValue = values.charAt(0).toUpperCase() + values.slice(1).toLowerCase();
        setLastName(capitalizedValue)
        if (values.trim() === '') {
            setMessage2(t('REG28'));
        } else if (!/^[a-zA-Z]+$/.test(values)) {
            setMessage2(t('REG26'));
        } else if (!/^.{1,23}$/g.test(values)) {
            setMessage2(t('REG27'));
        }
        else {
            setMessage2('')
        }
    }

    const formatPhoneNumber = (value) => {
        const digitsOnly = value.replace(/\D/g, "");
        return digitsOnly;
    };

    // const countryCodeHandler = (event) => {
    //     const values = event.target.value;
    //     const digitsOnly = formatPhoneNumber(values);
    //     setPhoneCountryCode(digitsOnly)
    //     if (values.trim() === '') {
    //         setMessage5(t('LVL0007'));
    //     } else if (digitsOnly.length >= 5) {
    //         setMessage5(t('REG29'))
    //     }
    //     else {
    //         setMessage5("");
    //     }

    // } 

    const countryCodeHandler = (event) => {
        setPhoneCountryCode(event)
        // setPhoneCountryCode2(event)
      };

    const mobileHandler = (event) => {
        const values = event.target.value;
        const digitsOnly = formatPhoneNumber(values);
        setPhone(digitsOnly);
        if (values.trim() === '') {
            setMessage6(t('REG30'));
        } else if (digitsOnly.length >= 19) {
            setMessage6(t('REG31'));
        } else if (digitsOnly.length < 8) {
            setMessage6(t('REG32'));
        }
        else {
            setMessage6("");
        }

    }

    const employeeEmailHandler = (event) => {
        const values = event.target.value;
        setEmail(values)
        if (values.trim() === '') {
            setMessage4(t('REG33'));
        }
        else if (!/^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(values)) {
            setMessage4(t('REG34'));
        } else {
            setMessage4("");
        }
    }

    const passPattern = /^(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[!@#$%^&*])\S{8,24}$/;
    const passwordHandler = (event) => {
        const values = event.target.value;
        setPassword(values)
        if (values.trim() === '') {
            setMessage3(t('REG35'));
        }
        else if (!values.match(passPattern)) {
            setMessage3(t('REG36'));
        }
        else {
            setMessage3("");
        }
    }

    const confirmPasswordHandler = (event) => {
        const values = event.target.value;
        setComfimPassword(values)
        if (values.trim() === '') {
            setMessage7(t('REG37'));
        }
        else if (!values.match(password)) {
            setMessage7(t('REG38'));
        }
        else {
            setMessage7("");
        }

    }

    const countryCodes = [
        { code: '+1', country: t('REG43') },
        { code: '+44', country: t('REG44') },
        { code: '+91', country: t('REG45') },
        { code: '+852', country: 'Hong Kong, China (+852)' },
        { code: '+853', country: 'Macau, China (+853)' }
        // Add more countries and their corresponding country codes here
    ];

    // const countries = [
    //     { code: '+1', country: t('REG40') },
    //     { code: '+44', country: t('REG41') },
    //     { code: '+91', country: t('REG42') },
    //     { code: '+852', country: 'Hong Kong, China' },
    //     { code: '+853', country: 'Macau, China' }
    //     // Add more countries and their corresponding country codes here
    // ];


    const handleCodeChange = (event) => {
        const values = event.target.value
        setPhoneCountryCode(values);
        if (values === '') {
            setMessage5(t('LVL0007'));
        } else {
            setMessage5('');
        }
    };

   const handleCountryChange = (event) => {
        const values = event.target.value
        setCountry(values);
        console.log(country);
        if (values === '') {
            setMessage8(t('REG39'));
        } else {
            setMessage8('');
        }
    };

    const [loading, setLoading] = useState(false);


    const saveBtnHandler = (e) => {
        if (message1 == '' && message2 == '' && message3 == '' && message4 == '' && message5 == '' && message6 == '' && message7 == '' && message8 =='' && firstName !== '' && lastName !== '' && password !== '' && confirmPassword !== '' && password === confirmPassword && phoneCountryCode !== ''&& email!==''&& phone!==''&& country !== '') {
            e.preventDefault();

            const payload = ({
                firstName,
                lastName,
                email,
                phoneCountryCode,
                country,
                phone,
                status,
                siteId,
                password,
                confirmPassword
            })
            console.log(payload + "palyload")
            // if (id) {
            //     EmployeeService.updateEmployee(id, payload).then((res) => {
            //         console.log(payload);
            //         console.log(res);

            //         if (res.data.code === "UASI0002") {
            //             toast.success("User updated successfully..", {
            //                 position: "top-right",
            //             });
            //             // if(email!==undefined){
            //             //   toast.success("Mail Sent Successfully to "+email, {
            //             //     position: "top-right",
            //             //   });
            //             // }
            //             setFlag(false);
            //             loadData();
            //         } else {
            //             toast.error(res.data.message, {
            //                 position: "top-right",
            //             });
            //         }
            //     });
            // } else {
            setLoading(true);
            CustomerService.createCustomer(payload).then((res) => {
                setLoading(false);
                if (res.data.code === 'ZKCSAI0001') {
                    console.log(res)
                    toast.success(t("REG20"), {
                        position: "top-right"
                    });
                    if (email !== undefined || email !== "") {
                        toast.success(t("REG21") + email, {
                            position: "top-right",
                        });
                    }
                    setFirstName('')
                    setLastName('')
                    setLoading(false);
                    navigate(REACT_LANDING);
                    // setCode('')
                    // setFlag(false)
                    // loadData();
                    props.onClose();
                } else if (res.data.code === 'ZKCSAE0020') {
                    toast.error(t("REG22"), {
                        position: "top-right"
                    });
                    // setLoading(false);
                } else {
                    toast.error(res.data.message, {
                        position: "top-right"
                    });
                    // setLoading(false);
                }
            })
        }

        // }
        else {
            if (firstName === '') {
                setLoading(false);
                setMessage1(t("REG23"))
            }
            if (lastName === '') {
                setLoading(false);
                setMessage2(t("REG23"))
            }
            if (password === '') {
                setLoading(false);
                setMessage3(t("REG23"))
            }
            if (email === '') {
                setLoading(false);
                setMessage4(t("REG23"))
            }
            if (phoneCountryCode === '') {
                setMessage5(t("REG23"))
            }
            if (phone === '') {
                setLoading(false);
                setMessage6(t("REG23"))
            }
            if (confirmPassword === '') {
                setLoading(false);
                setMessage7(t("REG23"))
            }
            if (password != confirmPassword) {
                setLoading(false);
                setMessage7(t("REG24"))
            }
            if (country === '') {
                setLoading(false);
                setMessage8(t("REG23"))
            } 
        }
    }

    return (
        <Grid container>
            {loading ?
                <>
                    <Loader />
                    <CutomOuterlayoutSignup
                        headerTitle={t("REG01")}
                        buttonName={t("REG02")}
                        handleClose={handleClose}
                        handldeButton={saveBtnHandler}
                        onClose={props.onClose}
                        handleClickOpen={props.handleClickOpen}
                        handleAlreadyAccount={props.handleAlreadyAccount}
                    >

                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={6}>
                                <DeatilsTextFieldTitle titleValue={t("REG04")} required />
                                <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                    <OutlinedInput
                                        id="outlined-required"
                                        value={firstName}
                                        name="firstName"
                                        placeholder={t("REG03")}
                                        onChange={firstNameHandler}
                                    />
                                    {message1 && <div className={classes.redColor}>{message1}</div>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <DeatilsTextFieldTitle titleValue={t("REG05")} required />
                                <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                    <OutlinedInput
                                        id="outlined-required"
                                        value={lastName}
                                        name="lastName"
                                        placeholder={t("REG06")}
                                        onChange={lastNameHandler}
                                    />
                                    {message2 && <div className={classes.redColor}>{message2}</div>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <DeatilsTextFieldTitle titleValue={t("REG07")} required style={{ marginBottom: '-4px' }} />
                                <Grid item style={{width:'100%'}}>
                      {/* <Typography className={classes.labels}> Country Code <span className={classes.redAsterisk}>  *</span></Typography> */}
                        <PhoneInput
                         id="phone-inputCustomer1"
                          style={{
                            height: "40px",
                            display:'flex',
                            alignContent:'center',
                            alignItems: "center",
                            border:'1px solid rgba(20, 20, 20, 0.80)',
                            borderRadius:'2px',
                          }}
                          country={"id"}
                          value={phoneCountryCode}
                          onChange={countryCodeHandler}
                          enableSearch="true"
                          searchPlaceholder={"search"}
                          searchNotFound={"search"}
                          inputProps={{ maxLength: 18}}
                          name="phoneCountryCode"
                        />
                      </Grid>
                                {message5 && <div className={classes.redColor}>{message5}</div>}
                            </Grid>

                            <Grid item xs={6}>
                                <DeatilsTextFieldTitle titleValue={t("REG09")} required />
                                <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                    <OutlinedInput
                                        id="outlined-required"
                                        value={phone} name="mobile" placeholder={t("REG10")} onChange={mobileHandler}
                                    />
                                    {message6 && <div className={classes.redColor}>{message6}</div>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <DeatilsTextFieldTitle titleValue={t("REG11")} required />
                                <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                    <OutlinedInput
                                        id="outlined-required"
                                        value={email} name="email" placeholder={t("REG12")} onChange={employeeEmailHandler}
                                    />
                                    {message4 && <div className={classes.redColor}>{message4}</div>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <DeatilsTextFieldTitle titleValue={t("REG13")} required style={{ marginBottom: '-4px' }} />
                                <TextField sx={{ mt: '0px', mb: '8px' }}
                                    fullWidth
                                    select
                                    label={country === "" ? t("REG14"): ""}
                                    value={country}
                                    onChange={handleCountryChange}
                                    InputLabelProps={{ shrink: false }}
                                    variant="outlined"
                                >
                                    {countryList.map(country => (
                                        <MenuItem style={{border:'1px solid red'}} key={country.country} value={country.country}>
                                            {country.country}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {message8 && <div className={classes.redColor}>{message8}</div>}
                            </Grid>
                            <Grid item xs={6}>
                                <DeatilsTextFieldTitle titleValue={t('LOGIN005')} required />
                                <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                    <OutlinedInput
                                        id="outlined-required"
                                        value={password} name="password"
                                        placeholder={t("REG15")} onChange={passwordHandler} autoComplete="off"
                                    />
                                    {message3 && <div className={classes.redColor}>{message3}</div>}
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <DeatilsTextFieldTitle titleValue={t("REG16")} required />
                                <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                    <OutlinedInput
                                        id="outlined-required"
                                        value={confirmPassword} name="confirmPassword" placeholder={t("REG17")} onChange={confirmPasswordHandler} autoComplete="off"
                                    />
                                    {message7 && <div className={classes.redColor}>{message7}</div>}
                                </FormControl>
                            </Grid>

                        </Grid>
                    </CutomOuterlayoutSignup>
                </>
                :
                <CutomOuterlayoutSignup
                    headerTitle={t("REG01")}
                    buttonName={t("REG02")}
                    handleClose={handleClose}
                    handldeButton={saveBtnHandler}
                    onClose={props.onClose}
                    handleClickOpen={props.handleClickOpen}
                    handleAlreadyAccount={props.handleAlreadyAccount}
                >

                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                            <DeatilsTextFieldTitle titleValue={t("REG04")} required />
                            <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                <OutlinedInput
                                    id="outlined-required"
                                    value={firstName}
                                    name="firstName"
                                    placeholder={t("REG03")}
                                    onChange={firstNameHandler}
                                />
                                {message1 && <div className={classes.redColor}>{message1}</div>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DeatilsTextFieldTitle titleValue={t("REG05")} required />
                            <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                <OutlinedInput
                                    id="outlined-required"
                                    value={lastName}
                                    name="lastName"
                                    placeholder={t("REG06")}
                                    onChange={lastNameHandler}
                                />
                                {message2 && <div className={classes.redColor}>{message2}</div>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DeatilsTextFieldTitle titleValue={t("REG07")} required style={{ marginBottom: '-4px' }} />
                            <Grid item style={{width:'100%'}}>
                      {/* <Typography className={classes.labels}> Country Code <span className={classes.redAsterisk}>  *</span></Typography> */}
                        <PhoneInput
                          id="phone-inputCustomer"
                          style={{
                            height: "40px",
                            display:'flex',
                            alignContent:'center',
                            alignItems: "center",
                            border:'1px solid rgba(20, 20, 20, 0.80)',
                            borderRadius:'2px',
                          }}
                          country={"id"}
                          value={phoneCountryCode}
                          onChange={countryCodeHandler}
                          enableSearch="true"
                          searchPlaceholder={"search"}
                          searchNotFound={"search"}
                          inputProps={{ maxLength: 18}}
                          name="phoneCountryCode"
                        />
                      </Grid>
                            {message5 && <div className={classes.redColor}>{message5}</div>}
                        </Grid>

                        <Grid item xs={6}>
                            <DeatilsTextFieldTitle titleValue={t("REG09")} required />
                            <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                <OutlinedInput
                                    id="outlined-required"
                                    value={phone} name="mobile" placeholder={t("REG10")} onChange={mobileHandler}
                                />
                                {message6 && <div className={classes.redColor}>{message6}</div>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DeatilsTextFieldTitle titleValue={t("REG11")} required />
                            <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                <OutlinedInput
                                    id="outlined-required"
                                    value={email} name="email" placeholder={t("REG12")} onChange={employeeEmailHandler}
                                />
                                {message4 && <div className={classes.redColor}>{message4}</div>}
                            </FormControl>
                        </Grid>
                       <Grid item xs={6}>
                            <DeatilsTextFieldTitle titleValue={t("REG13")} required style={{ marginBottom: '-4px' }} />
                            <TextField sx={{ mt: '0px', mb: '8px' }}
                                fullWidth
                                select
                                label={country === "" ? t("REG14") : ""}
                                value={country}
                                onChange={handleCountryChange}
                                InputLabelProps={{ shrink: false }}
                                variant="outlined"
                            >
                                {countryList.map(country => (
                                    <MenuItem sx={{"& .MuiPopover-paper-MuiMenu-paper":{border:'1px solid red'}}}style={{}} key={country.country} value={country.country}>
                                        {country.country}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {message8 && <div className={classes.redColor}>{message8}</div>}
                        </Grid>
                        <Grid item xs={6}>
                            <DeatilsTextFieldTitle titleValue={t("LOGIN005")} required />
                            <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                <OutlinedInput
                                    id="outlined-required"
                                    value={password} name="password"
                                    placeholder={t("REG15")} onChange={passwordHandler} autoComplete="off"
                                />
                                {message3 && <div className={classes.redColor}>{message3}</div>}
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <DeatilsTextFieldTitle titleValue={t("REG16")} required />
                            <FormControl variant="outlined" fullWidth margin="normal" sx={{ mt: '0px', }}>
                                <OutlinedInput
                                    id="outlined-required"
                                    value={confirmPassword} name="confirmPassword" placeholder={t("REG17")} onChange={confirmPasswordHandler} autoComplete="off"
                                />
                                {message7 && <div className={classes.redColor}>{message7}</div>}
                            </FormControl>
                        </Grid>

                    </Grid>
                </CutomOuterlayoutSignup>
            }
        </Grid>
    );
}

