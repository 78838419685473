import { ReactComponent as List_white } from "../../assets/images/List.svg";
import { ReactComponent as ListIcon } from "../../assets/images/Union 413.svg";
import { ReactComponent as HomeIcon_white } from "../../assets/images/home (1).svg";
import { ReactComponent as HomeIcon } from "../../assets/images/home (2).svg";

import { ReactComponent as applicationIcon } from "../../assets/images/ApplicationUpload.svg";
import { ReactComponent as applicationIcon_white } from "../../assets/images/applicationUpload_w.svg";
import { ReactComponent as customerIcon } from "../../assets/images/customerOrder.svg";
import { ReactComponent as customerIcon_white } from "../../assets/images/customerOrder_white.svg";

const dataEn = [
  {
    id: "8",
    name: "Home",
    link: "/HomeScreen",
    Icon: HomeIcon,
    Icon1: HomeIcon_white,
  },
  {
    id: "1",
    name: "Application Uploads",
    link: "/TemplatesList",
    Icon: applicationIcon_white,
    Icon1: applicationIcon,
  },

  {
    id: "14",
    name: "Template List",
    link: "/TemplateAuditList",
    Icon: List_white,
    Icon1: ListIcon,
  },

  {
    id: "2",
    name: "Customer Orders",
    link: "/CustomerOrders",
    Icon: customerIcon_white,
    Icon1: customerIcon,
  }

];

export default dataEn;


