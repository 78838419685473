import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from '@mui/icons-material/Download';
import {
    Box, Button, Dialog,
    DialogContent,
    DialogTitle, FormControl, Grid, InputAdornment, Menu, MenuItem, TextField, Tooltip, Typography
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../../../components/DataTable";
import IconHandler from "../../../components/IconHandler";
import { REACT_ORDER_DETAILS } from "../../../constants/ReactEndPoints";
import OrderService, { uploadCustomerOrder } from "../../../services/Orderservice";
import { ReactComponent as FileUploadIcon } from "../../../assets/images/Upload.svg";
import { ReactComponent as Visibility } from "../../../assets/images/View.svg";
import { ReactComponent as Filter } from "../../../assets/images/filter_Icon.svg";
import { ReactComponent as Refresh } from "../../../assets/images/refresh_Icon.svg";

import CustomDropDownDatePicker from "../../../components/CustomDropDownDatePicker";
import RightViewLayout from "../../../components/RighViewLayout";
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import {
    updateTemplate
} from "../../../services/AdvertService";
import { t } from "i18next";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ChatService from "../../../services/ChatService";
import { useRef } from "react";


const options = [
    { value: 'Today', label: 'Today' },
    { value: 'Yesterday', label: 'Yesterday' },
    { value: 'Week', label: 'Week' },
];

// const customChatBox = {

//     ".css-6hp17o-MuiList-root-MuiMenu-list": {
//         paddingTop: 0,
//         paddingBottom: 0
//     }

// }
export default function TemplateList() {
    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = useState([]);
    const [deptFlag, setDeptFlag] = useState(false);
    const [open, setOpen] = useState(false);
    const [imageurl, setImageUrl] = useState("");
    const [file, setFile] = useState("");
    const [callApiFilter, setCallApiFilter] = useState(true);
    const [id, setId] = useState("");
    const [tempId, setTempId] = useState("");
    const [orderId, setOrderId] = useState("");
    const [selectedValue, setSelectedValue] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [anchorE3, setAnchorE3] = React.useState(null);
    const [anchore5, setAnchore5] = React.useState(null);
    const [imageURL, setImageURL] = React.useState(localStorage.getItem("imageURL") || '');
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMenuOpenHelp = Boolean(anchorE2);
    const isMenuOpenConfigration = Boolean(anchorE3);
    const isMenuOpenNotification = Boolean(anchore5);
    const [chat, setChat] = useState("");
    let orderIds = [];

    const [pageOperation, setPageOperation] = useState({
        page: 0,
        rowsPerPage: 10,
    });
    const [filters, setFilters] = useState({});
    const userId = localStorage.getItem("USER_NAME");
    const token = localStorage.getItem("USER_TOKEN");
    const approved = "Approved";
    const rejected = "Rejected";

    const formatDate = (dateString) => {
        const originalDate = moment(dateString);
        return originalDate.format("DD MMM YYYY HH:mm:ss");
    };

    const chatHandler = (event) => {
        const values = event.target.value;
        console.log('ssss', event.target.value)
        setChat(values);
    };
    var demoId = "";
    const handleSelectedRows = (data) => {
        setTempId(data)
        demoId = data.join(',');
        setOrderId(demoId)
        console.log(demoId);

    };
    demoId = tempId
    const payload = ({
        content: chat,
        senderUsername: localStorage.getItem("FIRST_NAME"),
        orderId: orderId,
        senderEmail: localStorage.getItem("USER_NAME")

    })
    const [allMessage, setAllMessage] = useState([])

    // useEffect(() => {
    //     getAllMessages()
    // }, [])
    

    const getAllMessages = (orderId) => {
        ChatService.getAllMessages(orderId).then((res) => {
            if (res?.data?.code === "ZKCSAI0001") {
                let listData = res?.data?.data;
                const contentArray = listData.map((data) => ({
                    message: data.content
                }))

                setAllMessage(contentArray);
                // console.log(contentArray);
                // console.log(allMessage);
            }
        });
    }

    const getAllMessagesRead = (orderId) => {
        ChatService.getAllMessagesRead(orderId).then((res) => {
            if (res?.data?.code === "ZKCSAI0001") {
                let listData = res?.data?.data;
                const contentArray = listData.map((data) => ({
                    message: data.content
                }))

                setAllMessage(contentArray);
                console.log(contentArray);
                console.log(allMessage);
            }
        });
    }

    const handleSubmit = async (event) => {
        if (/^\s*$/.test(payload.content)) {
            return null;
          }
        ChatService.saveChat(payload).then((res) => {
            if (res.data.code === 'ZKCSAI0001') {
                setChat('')
                getAllMessages(orderId)
            }
        })
    }

    const scrollEnd = useRef();

    useEffect(() => {
        if (scrollEnd.current) {
            scrollEnd.current.scrollTop = scrollEnd.current.scrollHeight;
        }
    }, [allMessage]);

    const loadData = () => {
        let query = "";

        if (filters?.createdAt) {
            query += "&createdAt=" + filters.createdAt;
        }

        if (filters?.createdBy) {
            query += "&createdBy=" + filters.createdBy;
        }

        if (filters?.status) {
            query += "&status=" + filters.status;
        }

        if (userFilter) {
            query += "&filterAdvert=" + userFilter;
        }

        if (query === "") {
            OrderService.getMyWork(
                pageOperation.page + 1,
                pageOperation.rowsPerPage,
                userId, query
            ).then((res) => {
                if (res.data.code === "ZKCSAE0001") {
                    setRows(res.data.data.customerOrders);
                    setTotalRecords(res?.data?.data?.totalCount);
                } else {
                    setRows([]);
                    setTotalRecords(0);
                }
            });
        } else if (query !== "") {
            OrderService.getMyWork(pageOperation.page + 1, pageOperation.rowsPerPage, userId, query).then(
                (res) => {
                    if (res != undefined) {
                        if (res.data.code === "ZKCSAE0001") {
                            setRows(res.data.data.customerOrders);
                            setTotalRecords(res?.data?.data?.totalCount);
                        } else {
                            setRows([]);
                            setTotalRecords(0);
                        }
                    }
                }
            );
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
    const [message1, setMessage1] = useState("");
    const handleImageUpload = (event, rowData) => {
        const file = event.target.files[0];
        if (!allowedImageTypes.includes(file.type)) {
            toast.error(t('ADDTEMP23'));
            return;
        }

        if (!file) {
            toast.error(t('ADDTEMP23'));
            return;
        } else {
            setFile(file);
            handleActions("Upload", rowData, file);
        }


    };
    const [unreadCount, setUnreadCount] = useState(0);
    useEffect(() => {
        if (callApiFilter) {
            userFilter = selectedValue
            loadData();
            setCallApiFilter(false);
        }
    }, [callApiFilter]);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 50,
            flex: 1,
            renderCell: (e) => e.row.id,
            hide: true,
        },
        {
            field: "createdAt",
            headerName: t("WOR2"),
            width: 200,
            flex: 1.2,
            renderCell: (e) => formatDate(e.row.createdAt),
        },
        {
            field: "templateName",
            headerName: t("WOR3"),
            width: 200,
            flex: 1,
            renderCell: (e) => e.row.templateName,
        },
        {
            field: "customerName",
            headerName: t("WOR4"),
            width: 200,
            flex: 1,
            renderCell: (e) => e.row.customerName,
        },
        {
            field: "customerEmail",
            headerName: t("WOR5"),
            width: 200,
            flex: 1,
            renderCell: (e) => e.row.customerEmail,
        },
        {
            headerName: t("USER10"),
            sortable: false,
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (e) => (
                <IconHandler>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <Tooltip
                             title={t("CUS32")} 
                            arrow
                        >
                            <MessageOutlinedIcon
                                id="myWork-message"
                                onClick={() => handleConfigration(e)}
                                style={{ margin: "7px", width: '1.3em', cursor: 'pointer' }}
                                fontSize="medium"
                            />
                            {unreadCount > 0 && (
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '-3px',
                                        right: '2px',
                                        background: 'black',
                                        borderRadius: '20%',
                                        padding: '2px 6px',
                                        color: 'white',
                                        fontSize: '12px',
                                    }}
                                >
                                    {unreadCount}
                                </span>
                            )}
                        </Tooltip>
                    </div>
                    <Tooltip title={t("CUS7")} arrow>
                        <Visibility id="myWork-view"
                            onClick={() => handleActions("View", e, "")}
                            style={{ margin: "7px", width: '1.3em', cursor: 'pointer' }}
                        />
                    </Tooltip>
                    <Tooltip title={t("BAN01")} arrow>
                        {/* <FileUploadIcon
                            type="file" onClick={handleUpload}
                            style={{ alignSelf: "center", paddingTop: "0px", margin: "5px" }}
                        /> */}
                        <label htmlFor={`file-upload-${e.row.id}`}>
                            <FileUploadIcon id="myWork-upload"
                                style={{ margin: "7px", width: '1.1em', cursor: 'pointer' }}
                            />
                        </label>
                        <TextField
                            type="file"
                            id={`file-upload-${e.row.id}`} // Make sure this is unique for each row\
                            style={{ display: "none" }}
                            onChange={(event) => handleImageUpload(event, e.row)}
                        />

                    </Tooltip>
                    {e.row.status === "Delivered" && (
                        <Tooltip title={t("CUS31")} arrow>
                            <DownloadIcon
                                onClick={() => handleActions("Download", e, "")}
                                style={{ alignSelf: "center", paddingTop: "0px", margin: "5px" }}
                            />
                        </Tooltip>
                    )}
                </IconHandler>
            ),
        },
    ];
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMenuConfigrationClose = () => {
        setAnchorE3(null);
        handleMobileMenuClose();
        setChat('')
    };

    const renderMenuConfigration = () => {
        return (
            <Dialog
                // anchorEl={anchorEl}
                // anchorOrigin={{
                //     vertical: 10,
                //     horizontal: "right",
                // }}

                // keepMounted
                // transformOrigin={{
                //     vertical: "top",
                //     horizontal: 330,
                // }}
                // sx={customChatBox}
                PaperProps={{
                    style: {
                        width: "300px",
                        height: "400px",
                        borderRadius: '10px',
                        background: '#FFFFFF',
                        position: 'absolute',
                        bottom: '0',
                        right: '0',

                    },
                }}
                open={isMenuOpenConfigration}
                onClose={handleMenuConfigrationClose} >

                <Grid container  >

                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item style={{ display: 'flex', width: "300px", height: "55px", background: "linear-gradient(180deg, rgba(51, 125, 200, 1) 0%, rgba(77, 165, 109, 1) 50%, rgba(100, 177, 71, 1) 100%)", borderRadius: '4px' }}>
                        <Grid xs={12} sm={12} md={4} lg={2} xl={2} item style={{ maxWidth: '12%' }}>
                            <AccountCircleOutlinedIcon style={{ paddingTop: '10px' }} fontSize="large"></AccountCircleOutlinedIcon>
                        </Grid>
                        <Grid xs={12} sm={12} md={10} lg={10} xl={10} item >
                            <Grid xs={12} sm={12} md={10} lg={10} xl={10} >
                                <Typography pt={1.5} style={{ fontWeight: "bold", textAlign: 'left', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                    {localStorage.getItem("FIRST_NAME")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} sm={12} md={2} lg={2} xl={2} style={{ flexBasis: "10%", paddingTop: '4px' }} >
                            <CloseOutlinedIcon fontSize="small" onClick={handleMenuConfigrationClose}></CloseOutlinedIcon>

                        </Grid>
                    </Grid>

                    <Grid ref={scrollEnd} pt={'2px'} style={{ width: "300px", height: '280px', boxSizing: 'border-box', overflowX: 'hidden', overflowY: 'auto' }} >
                        {allMessage.map((item) => (

                            <Typography variant="body2" className="categoryStyle1" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', marginBottom: '2px', marginLeft: '5px', marginRight: '5px', borderRadius: '4px' }}>
                                <span>
                                    {item.message}
                                </span>
                            </Typography>))}
                    </Grid>

                    <Grid style={{ display: 'block', width: "300px", height: "50px", display: "flex", alignItems: "end", bottom: 0 }}>
                        <TextField
                            style={{ width: "300px", marginLeft: '10px', marginRight: '10px' }}
                            value={chat}
                            placeholder="Enter your message"
                            onChange={chatHandler}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <Button id='sendMessage-myWorkButton'
                                        style={{ marginLeft: '10px' }}
                                        size="small" color="primary"
                                        variant="outlined"
                                        onClick={handleSubmit}
                                    >
                                        Send
                                    </Button>
                                </InputAdornment>,
                            }}
                        />

                    </Grid>

                </Grid>

            </Dialog>
        )
    }



    const handleConfigration = (event) => {
        console.log(event.id);
        console.log(event);
        setAnchorE3(event);
        getAllMessagesRead(event.id)
    };
    const handleActions = (action, data, file) => {
        const templateId = data.id;
        setId(templateId);
        if (action === "Upload") {
            const formData = new FormData();
            formData.append("file", file);
            uploadCustomerOrder(templateId, formData).then((response) => {
                if (response != undefined) {
                    if (response.data.code === "ZKCSAI0001") {
                        toast.success(t("WOR6"), {
                            position: "top-right",
                        });
                        loadData();
                    } else {
                        toast.error(t("TEM11"), {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            });
        } else if (action === "Approve") {
            updateTemplate(templateId, approved).then((response) => {
                if (response != undefined) {
                    if (response.data.code === "ZKCSAI0001") {
                        toast.success("Advert Approved successfully.", {
                            position: "top-right",
                        });
                        loadData();
                    } else {
                        toast.error(t("TEM11"), {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            });
        } else if (action === "View") {
            navigate(REACT_ORDER_DETAILS, { state: { imgId: templateId } });
        }
    };

    const handleChangePage = (newPage) => {
        // setSelectedValue("");
        setPageOperation({ ...pageOperation, page: newPage });
        setCallApiFilter(true);
    };

    const handleChangeRowsPerPage = (pageSize) => {
        setSelectedValue("");
        setPageOperation({ ...pageOperation, page: 0, rowsPerPage: pageSize });
        setCallApiFilter(true);
    };


    const handleRefresh = () => {
        setSelectedValue("");
        loadData();
        setFilters({
            createdAt: "",
            createdBy: "",
            status: "",
        });
        setPageOperation({ page: 0, rowsPerPage: 10 });
        setCallApiFilter(true);
    }
    var userFilter = "";
    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedValue(value)

        userFilter = value;
        loadData();
        setPageOperation({ ...pageOperation, page: 0 });
    };

    const actions = () => (
        <Box item style={{ display: "inherit" }}>
            <CustomDropDownDatePicker value={selectedValue} onChange={handleChange} options={options} />
            <Tooltip title={t("USER2")} arrow>
                <Refresh id="refresh-myWork"
                    width={"40px"}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => {
                        handleRefresh();
                    }}
                />
            </Tooltip>
            {/* <Tooltip arrow title="Filter">
                <Filter
                    width={"40px"}
                    //   onClick={() => setOpenFilter(!openFilter)}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                />s
            </Tooltip> */}
        </Box>
    );

    return (
        <>
            <Grid >
                <RightViewLayout
                    title={t("WOR1")}
                    actions={actions}
                    type="table"
                >
                    <Grid container margin='auto' paddingLeft={'0px'} overflow={"initial"} display="flex" >
                        <DataTable
                            columns={columns}
                            rows={rows}
                            onSelection={handleSelectedRows}
                            page={pageOperation.page}
                            totalRecords={totalRecords}
                            rowsPerPage={pageOperation.rowsPerPage}
                            onPageChange={handleChangePage}
                            onPageSizeChange={handleChangeRowsPerPage}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </Grid>
                </RightViewLayout>
                {renderMenuConfigration()}
            </Grid>
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h6">Image Preview</Typography>
                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <img src={imageurl} alt="Selected Template" style={{ width: "100%" }} />
                    <div hidden>{id}</div>
                </DialogContent>
            </Dialog>
        </>
    );
}
