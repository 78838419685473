import api from "../configurations/http-common";

const createUser = (data) => {
  return api.unsecuredAxios().post("/web/user", data);
};

const verifyUser = (email) => {
  return api.unsecuredAxios().get("/web/user/verify/" + email);
};

const autorize = (data) => {
  return api.unsecuredAxios().post("/web/user/authorize", data);
};

const ActiveUser = (data) => {
  return api.unsecuredAxios().post("/web/user/account-activate", data);
};

const companyVerify = (companyCode) => {
  return api.securedAxios().put("/web/user/company/" + companyCode);
};

const forgotPassword = (userName) => {
  return api.unsecuredAxios().post("/web/user/forgot_password/" + userName);
};
const verifyCode = (emailCode) => {
  return api.securedAxios().post("/web/user/verify_code/" + emailCode);
};

const resetPassword = (resetPasswordEmail, payload) => {
  return api.unsecuredAxios().put("/web/user/resetPassword?userIdentifier=" + resetPasswordEmail, payload);
};

const logout = () => {
  return api.securedAxios().get("web/user/revoke");
};

const tokenRefresh = (payload) => {
  return api.securedAxios().put("web/user/refresh_token", payload);
};

const getUser = (email) => {
  return api.securedAxios().get("/web/user/details/" + email);
};

const UserService = {
  createUser,
  autorize,
  forgotPassword,
  resetPassword,
  logout,
  tokenRefresh,
  ActiveUser,
  verifyCode,
  verifyUser,
  companyVerify,
  getUser
  
};

export default UserService;
