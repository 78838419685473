
import { ReactComponent as UserIcon } from "../../assets/images/ProPic.svg";
import { ReactComponent as languages } from "../../assets/images/translate-language.svg";
import { ReactComponent as language_w } from "../../assets/images/translate-language_w.svg";

const dataEn = [

  {
    id: "12",
    name: "Profile",
    link: "",
    Icon: UserIcon,
    Icon1: UserIcon,
  },
  {
    id: "4",
    name: "Languages",
    Icon: language_w,
    Icon1: languages,
    link: "",
  },

];


export default dataEn;


