import i18n from 'i18next';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AppRoutes from './AppRoutes';
import BrowserLanguage from './util/BrowserLanguage';

function App() {

  useEffect(() => {
    let language = BrowserLanguage.getDefaultLanguage();
    language = language === null ? 'en' : language;
    i18n.changeLanguage(language);
  }, []);
// alert(mode)
  return (
    <BrowserRouter basename='/'>
    
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <AppRoutes />
        <ToastContainer autoClose={2000} position='top-center' hideProgressBar className='toast-container' toastClassName='dark-toast' />
      </SnackbarProvider>
    
    </BrowserRouter>
  );
}

export default App;
