import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useState } from "react";
import { useNavigate } from 'react-router';
import { toast } from "react-toastify";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_TAGS } from "../../../constants/ReactEndPoints";
import TagService from "../../../services/TagService";
import useStyles from '../../onboard/Styles';

export default function TagsAdd() {

  const classes = useStyles();
  const navigate = useNavigate();
  const [tagName, setTagName] = useState("");
  const [tagType, setTagType] = useState("");
  const [sequenceOrder, setSequenceOrder] = useState("");
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");
  const [message3, setMessage3] = useState("");


  const handleCancel = () => {
    navigate(REACT_TAGS);
  };

  const tagsNameHandler = (event) => {
    const values = event.target.value;
    setTagName(values);
    if (values.trim() === '') {
      setMessage1(t("TAGS2"));
    } else if (!/^(?!.*\d)(?!.*[^\w\s])(?!.*\s{2,})[^\s].{2,19}$/.test(values)) {
      setMessage1(t("TAGS3"));
    } else {
      setMessage1('');
    }
  };

  const tagTypeHandler = (event) => {
    const values = event.target.value;
    const capitalizedValues = values ? values.charAt(0).toUpperCase() + values.slice(1).toLowerCase() : "";
    setTagType(capitalizedValues);
  };

  const sequenceHandler = (event) => {
    const values = event.target.value;
    setSequenceOrder(values);
    if (values === null || values === undefined || values === '') {
      setMessage3(t("TAGS4"));
    } else if (!/^[1-9][0-9]*$/.test(values)) {
      setMessage3(t("TAGS5"));
    } else {
      setMessage3("");
    }
  };

  const payload = ({
    tag: tagName,
    type: tagType,
    sequenceOder: sequenceOrder,
  })

  const handleSubmit = async (event) => {
    if (message1 == '' && message3 == '' && tagName !== '' && sequenceOrder !== '') {
      TagService.createTags(payload).then((res) => {
        if (res.data.code === 'ZKCSAI0001') {
          toast.success(t("TAGS6"), {
            position: "top-right"
          });
          navigate(REACT_TAGS);
        } else if (res.data.code === 'ZKCSAE0079') {
          toast.error(t("TAGS7"), {
            position: "top-right"
          });
        }
        else if (res.data.code === 'ZKCSAE0081') {
          toast.error(t("TAGS8"), {
            position: "top-right"
          });
        }
        else if (res.data.code === 'ZKCSAE0083') {
          toast.error(t("TAGS9"), {
            position: "top-right"
          });
        }
        else if (res.data.code === 'ZKCSAE0084') {
          toast.error(t("TAGS10"), {
            position: "top-right"
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right"
          });
        }
      })
    } else {
      if (tagName === '') {
        setMessage1(t("ORDTEM10"))
      }
      if (sequenceOrder === '') {
        setMessage3(t("ORDTEM10"))
      }

    }
  }


  return (
    <Grid >
      <RightViewLayout
        title={t("TAG06")}
        navigateBack={'/'}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" flexDirection="row" >
          <Grid item xs={6} sm={6} className="OrderStyle">
            <Grid item xs={12} sm={9}>
              <Typography className="viewTypography"> {t("TAG07")} <span className={classes.redAsterisk}>  *</span></Typography>
              <TextField fullWidth variant="outlined" value={tagName} name="tagName" placeholder={t("TAG08")} onChange={tagsNameHandler}
                style={{ paddingTop: "10px", paddingLeft: '27px' }} />
              {message1 && <div className={classes.redColor1}>{message1}</div>}
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography className="viewTypography"> {t("TAG09")}</Typography>
              <TextField fullWidth variant="outlined" value={tagType} name="tagType" placeholder={t("TAG010")} onChange={tagTypeHandler}
                style={{ paddingTop: "10px", paddingLeft: '27px' }} />
              {message2 && <div className={classes.redColor1}>{message2}</div>}
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography className="viewTypography"> {t("TAG011")}<span className={classes.redAsterisk}>  *</span></Typography>
              <TextField fullWidth variant="outlined" value={sequenceOrder} name="sequenceOrder" placeholder={t("TAG012")} onChange={sequenceHandler}
                style={{ paddingTop: "10px", paddingLeft: '27px' }} />
              {message3 && <div className={classes.redColor1}>{message3}</div>}
            </Grid>
            <Grid item xs={12} sm={9}>
              <Box display="flex" width={'100%'} justifyContent={"flex-end"} gap={'10px'} mt={'25px'} marginBottom={'20px'} >
                <Button id="cancleAddTags-button" paddingLeft={'27px'} variant="outlined" color="primary" size="medium" onClick={handleCancel}  >{t("TAG013")}</Button>
                <Button id="saveAddTags-button" paddingLeft={'27px'} sx={{ width: '80px' }} variant="contained" color="primary" size="medium" onClick={handleSubmit}>{t("TAG014")}</Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </RightViewLayout>
    </Grid>
  );
}