import {
    Box,
    Grid,
    Tooltip,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { ReactComponent as Refresh } from "../../../assets/images/refresh_Icon.svg";
import { ReactComponent as Delete } from "../../../assets/industries/DeleteIcon.svg";
import { ReactComponent as Upload } from "../../../assets/industries/UploadIcon.svg";
import { ReactComponent as BannerUpload } from "../../../assets/industries/bannerUploadIcon.svg";
import DropzoneComponent from "../../../components/layout-components/DropzoneComponent";

import { t } from "i18next";
import Loader from "../../../components/Loader";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_BANNER_MANAGEMENT } from "../../../constants/ReactEndPoints";
import { createBanner, deleteBanner, getBannerList } from '../../../services/BannerService';
import useStyles from '../../onboard/Styles';

export default function BannerManagement(props) {
    const navigate = useNavigate();
    const classes = useStyles();

    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [previewkey, setPreviewkey] = useState(null);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [message3, setMessage3] = useState("");
    const [message4, setMessage4] = useState("");
    const [message5, setMessage5] = useState("");
    const [message6, setMessage6] = useState("");
    const [message7, setMessage7] = useState("");
    const [file1, setFile1] = useState("");
    const [imageName, setImageName] = useState("");

    const [thumbnailImageUrl, setThumbnailImageUrl] = useState(null);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [placeHolderImageUrl, setPlaceHolderImageUrl] = useState(null);
    const [placeHolderkey, setPlaceHolderkey] = useState(null);

    const [thumbnailImageId, setThumbnailImageId] = useState(null);
    const [previewImageId, setPreviewImageId] = useState(null);
    const [placeHolderImageId, setPlaceHolderImageId] = useState(null);
    const [image4Id, setImage4Id] = useState(null);

    const [image4Url, setImage4Url] = useState(null);
    const [image4UrlKey, setImage4UrlKey] = useState(null);
    const [loading, setLoading] = useState(false);

    const [uploadDisabled1, setUploadDisabled1] = useState(false);
    const [uploadDisabled2, setUploadDisabled2] = useState(false);
    const [uploadDisabled3, setUploadDisabled3] = useState(false);
    const [uploadDisabled4, setUploadDisabled4] = useState(false);


    const [uploadDisabled, setUploadDisabled] = useState(false);



    useEffect(() => {
        loadBannerList()
    }, [])

    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif'];

    const handleImageUploadThumbnail = (excelData) => {
        const file = excelData[0];
        if (!file) {
            setMessage4(t('BAN04'));
        } else {
            setMessage4('');
            setFile1(excelData[0]);
            setImageName("thumbnailImageUrl");
            setThumbnailImage(excelData[0].name);
            setThumbnailImageUrl(URL.createObjectURL(excelData[0]));
            setUploadDisabled1(false);
            localStorage.setItem('uploadDisabled', JSON.stringify(false));
            props.getExcelFile(excelData[0]);
        }
        if (!allowedImageTypes.includes(excelData.type)) {
            setMessage4(t('BAN04'));
            return;
        }
    };

    const handleImageUploadPreview = (excelData) => {
        const file = excelData[0];
        if (!file) {
            setMessage5(t('BAN04'));
        } else {
            setMessage5('');
            setFile1(excelData[0]);
            setImageName("previewImageUrl");
            setPreviewkey(excelData[0].name);
            setPreviewImageUrl(URL.createObjectURL(excelData[0]));
            setUploadDisabled2(false);
            localStorage.setItem('uploadDisabled', JSON.stringify(false));
            props.getExcelFile(excelData[0]);
        }
        if (!allowedImageTypes.includes(excelData.type)) {
            setMessage5(t('BAN04'));
            return;
        }
    };

    const handleImageUploadPlaceHolder = (excelData) => {
        const file = excelData[0];
        if (!file) {
            setMessage7(t('BAN04'));
        } else {
            setMessage7('');
            setFile1(excelData[0]);
            setImageName("placeHolderImageUrl");
            setPlaceHolderkey(excelData[0].name);
            setPlaceHolderImageUrl(URL.createObjectURL(excelData[0]));
            setUploadDisabled3(false);
            localStorage.setItem('uploadDisabled', JSON.stringify(false));
            props.getExcelFile(excelData[0]);
        }
        if (!allowedImageTypes.includes(excelData.type)) {
            setMessage7(t('BAN04'));
            return;
        }
    };

    const handleImage4Upload = (excelData) => {
        const file = excelData[0];
        if (!file) {
            setMessage6(t('BAN04'));
        } else {
            setMessage6('');
            setFile1(excelData[0]);
            setImageName("image4Url");
            setImage4UrlKey(excelData[0].name);
            setImage4Url(URL.createObjectURL(excelData[0]));
            setUploadDisabled4(false);
            localStorage.setItem('uploadDisabled', JSON.stringify(false));
            props.getExcelFile(excelData[0]);
        }
        if (!allowedImageTypes.includes(excelData.type)) {
            setMessage6(t('BAN04'));
            return;
        }
    };


    const loadBannerList = () => {
        getBannerList().then((res) => {
            setLoading(true);
            if (res?.data?.code === "ZKCSAE0001") {
                const bannerListData = res?.data?.data;

                // Check if the bannerListData is an array and not empty
                if (Array.isArray(bannerListData) && bannerListData.length > 0) {
                    bannerListData.forEach((banner) => {
                        // Compare the imageName and set the corresponding image URL
                        if (banner.imageName === "thumbnailImageUrl") {
                            setThumbnailImageUrl(banner.imageUrl);
                            setThumbnailImageId(banner.id);
                            setUploadDisabled1(true);
                        } else if (banner.imageName === "previewImageUrl") {
                            setPreviewImageUrl(banner.imageUrl);
                            setPreviewImageId(banner.id);
                            setUploadDisabled2(true);
                        } else if (banner.imageName === "placeHolderImageUrl") {
                            setPlaceHolderImageUrl(banner.imageUrl);
                            setPlaceHolderImageId(banner.id);
                            setUploadDisabled3(true);
                        } else if (banner.imageName === "image4Url") {
                            setImage4Url(banner.imageUrl);
                            setImage4Id(banner.id);
                            setUploadDisabled4(true);
                        }
                    });
                }
                setLoading(false);
            }
        });
    }


    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData();
        formData.append('file1', file1);
        formData.append("imageName", imageName)

        createBanner(formData).then((res) => {

            if (res.data.code === 'ZKCSAI0001') {
                toast.success(t("BAN05"), {
                    position: "top-right"
                });
                loadBannerList();
                localStorage.setItem('uploadDisabled', JSON.stringify(true));
                navigate("/BannerManagement")
                setFile1("");
                setImageName("");
                setLoading(false);
                return;
            }
        });
    }


    const handleDelete = (id) => {
        setLoading(true);
        deleteBanner(id).then((response) => {
            setLoading(false);
            if (response !== undefined) {
                if (response.data.code === "ZKCSAI0001") {
                    toast.success(t("BAN06"), {
                        position: "top-right",
                    });
                    setThumbnailImageUrl("");
                    setPreviewImageUrl("");
                    setPreviewkey("")
                    setPlaceHolderkey("")
                    setPlaceHolderImageUrl("");
                    setThumbnailImage("");
                    setImage4Url("");
                    setImage4UrlKey("")
                    setThumbnailImageId("");
                    setPreviewImageId("");
                    setImage4Id("");
                    setPlaceHolderImageId("");
                    loadBannerList();
                    navigate(REACT_BANNER_MANAGEMENT);
                    return;
                }
            }
        })
    }

    const handleRefresh = () => {
        window.location.reload();
    };


    const actions = () => (
        <Box item style={{ display: "inherit" }}>
            <Tooltip title={t("USER2")} arrow>
                <Refresh id='banner-refresh'
                    width={"40px"}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => {
                        handleRefresh();
                    }}
                />
            </Tooltip>
        </Box>
    );


    return (
        <Grid>
            <RightViewLayout
                title={t("TEM8")}
                actions={actions}
            >
                <Grid container overflow={"initial"} display="flex">
                    {loading ?
                        <Loader />
                        :
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                                {thumbnailImageUrl ? (
                                    <Grid display={'flex'} justifyContent={'end'}>
                                        <Tooltip title={t('BAN01')}>
                                            <Upload
                                                width={"40px"}
                                                style={{
                                                    marginRight: "10px",
                                                    display: uploadDisabled1 ? 'none' : 'block',
                                                    cursor: uploadDisabled ? "not-allowed" : "pointer",
                                                    opacity: uploadDisabled ? 0.1 : 1
                                                }}
                                                onClick={uploadDisabled ? null : handleSubmit}
                                            />
                                        </Tooltip>
                                        {thumbnailImageId ? (
                                            <Tooltip title={t("USER12")}>
                                                <Delete id='banner1-delete' width={"40px"} style={{ cursor: "pointer" }} onClick={() => handleDelete(thumbnailImageId)} />
                                            </Tooltip>
                                        ) : ""}
                                    </Grid>
                                ) : ""}
                                <DropzoneComponent getExcelFile={(excelData) => handleImageUploadThumbnail(excelData)}>
                                    {thumbnailImageUrl ? (
                                        <img src={thumbnailImageUrl} alt={"thubnail preview"} draggable="false"
                                            style={{ width: '100%', height: '280px', borderRadius: '5px' }} />
                                    ) : (
                                        <Box mt={2} p={1} sx={{
                                            border: '2px dashed #1487ca80', borderRadius: '5px',
                                            backgroundColor: "#FFFFFF", display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', flexDirection: 'column', height: '250px'
                                        }} >
                                            {thumbnailImage !== null && thumbnailImage ? (
                                                <>
                                                    <BannerUpload width={"40px"} />
                                                    <Typography sx={{ fontSize: "11px", textAlign: 'center' }}>
                                                        <span>{t("BAN02")}</span><br />
                                                        <span>{t("BAN03")}</span>
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <BannerUpload width={"40px"} />
                                                    <Typography sx={{ fontSize: "11px", textAlign: 'center' }}>
                                                        <span>{t("BAN02")}</span><br />
                                                        <span>{t("BAN03")}</span>
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    )}
                                    {message4 && <div className={classes.redColor}>{message4}</div>}
                                </DropzoneComponent>
                            </Grid>
                            <Grid item xs={12}>
                                {previewImageUrl ? (
                                    <Grid display={'flex'} justifyContent={'end'}>
                                        <Tooltip title={t('BAN01')}>
                                            <Upload
                                                width={"40px"}
                                                style={{
                                                    marginRight: "10px",
                                                    display: uploadDisabled2 ? 'none' : 'block',
                                                    cursor: uploadDisabled ? "not-allowed" : "pointer",
                                                    opacity: uploadDisabled ? 0.1 : 1
                                                }}
                                                onClick={uploadDisabled ? null : handleSubmit}
                                            />
                                        </Tooltip>
                                        {previewImageId ? (
                                            <Tooltip title={t("USER12")}>   <Delete id='banner2-delete' width={"40px"} style={{ cursor: "pointer" }} onClick={() => handleDelete(previewImageId)} />
                                            </Tooltip>
                                        ) : ""}
                                    </Grid>
                                ) : ""}
                                <DropzoneComponent getExcelFile={(excelData) => handleImageUploadPreview(excelData)}>
                                    {previewImageUrl ? (
                                        <img src={previewImageUrl} alt="Preview" draggable="false" style={{ width: '100%', height: '280px' }} />
                                    ) : (
                                        <Box mt={2} p={1} sx={{
                                            border: '2px dashed #1487ca80', borderRadius: '5px',
                                            backgroundColor: "#FFFFFF", display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', flexDirection: 'column', height: '250px'
                                        }}>
                                            {previewkey !== null && previewkey ? (
                                                <>
                                                    <BannerUpload width={"40px"} />
                                                    <Typography sx={{ fontSize: "11px", textAlign: 'center' }}>
                                                        <span> {t('BAN02')}</span><br />
                                                        <span>{t('BAN03')}</span>
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <BannerUpload width={"40px"} />
                                                    <Typography sx={{ fontSize: "11px", textAlign: 'center' }}>
                                                        <span>{t('BAN02')}</span><br />
                                                        <span>{t('BAN03')}</span>
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    )}
                                    {message5 && <div className={classes.redColor}>{message5}</div>}
                                </DropzoneComponent>
                            </Grid>
                            <Grid item xs={12}>
                                {placeHolderImageUrl ? (
                                    <Grid display={'flex'} justifyContent={'end'}>
                                        <Tooltip title={t('BAN01')}>
                                            <Upload
                                                width={"40px"}
                                                style={{
                                                    marginRight: "10px",
                                                    display: uploadDisabled3 ? 'none' : 'block',
                                                    cursor: uploadDisabled ? "not-allowed" : "pointer",
                                                    opacity: uploadDisabled ? 0.1 : 1
                                                }}
                                                onClick={uploadDisabled ? null : handleSubmit}
                                            />
                                        </Tooltip>
                                        {placeHolderImageId ? (
                                            <Tooltip title={t("USER12")}>
                                                <Delete id='banner3-delete' width={"40px"} style={{ cursor: "pointer" }} onClick={() => handleDelete(placeHolderImageId)} />
                                            </Tooltip>
                                        ) : ""}
                                    </Grid>
                                ) : ""}
                                <DropzoneComponent getExcelFile={(excelData) => handleImageUploadPlaceHolder(excelData)}>
                                    {placeHolderImageUrl ? (
                                        <img src={placeHolderImageUrl} alt="Thumbnail Preview" draggable="false"
                                            style={{ width: '100%', height: '280px', borderRadius: '5px' }} />
                                    ) : (
                                        <Box mt={2} p={1} sx={{
                                            border: '2px dashed #1487ca80', borderRadius: '5px',
                                            backgroundColor: "#FFFFFF", display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', flexDirection: 'column', height: '250px'
                                        }} >
                                            {placeHolderkey !== null && placeHolderkey ? (
                                                <>
                                                    <BannerUpload width={"40px"} />
                                                    <Typography sx={{ fontSize: "11px", textAlign: 'center' }}>
                                                        <span>{t('BAN02')}</span><br />
                                                        <span>{t('BAN03')}</span>
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <BannerUpload width={"40px"} />
                                                    <Typography sx={{ fontSize: "11px", textAlign: 'center' }}>
                                                        <span>{t('BAN02')}</span><br />
                                                        <span>{t('BAN03')}</span>
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    )}
                                    {message7 && <div className={classes.redColor}>{message7}</div>}
                                </DropzoneComponent>
                            </Grid>
                            <Grid item xs={12}>
                                {image4Url ? (
                                    <Grid display={'flex'} justifyContent={'end'}>
                                        <Tooltip title={t('BAN01')}>
                                            <Upload
                                                width={"40px"}
                                                style={{
                                                    marginRight: "10px",
                                                    display: uploadDisabled4 ? 'none' : 'block',
                                                    cursor: uploadDisabled ? "not-allowed" : "pointer",
                                                    opacity: uploadDisabled ? 0.1 : 1
                                                }}
                                                onClick={uploadDisabled ? null : handleSubmit}
                                            />
                                        </Tooltip>
                                        {image4Id ? (
                                            <Tooltip title={t("USER12")}> <Delete id='banner3-delete' width={"40px"} style={{ cursor: "pointer" }} onClick={() => handleDelete(image4Id)} />
                                            </Tooltip>
                                        ) : ""}
                                    </Grid>
                                ) : ""}
                                <DropzoneComponent getExcelFile={(excelData) => handleImage4Upload(excelData)}>
                                    {image4Url ? (
                                        <img src={image4Url} alt="Thumbnail Preview" draggable="false"
                                            style={{ width: '100%', height: '280px', borderRadius: '5px' }} />
                                    ) : (
                                        <Box mt={2} p={1} sx={{
                                            border: '2px dashed #1487ca80', borderRadius: '5px',
                                            backgroundColor: "#FFFFFF", display: 'flex', justifyContent: 'center',
                                            alignItems: 'center', flexDirection: 'column', height: '250px'
                                        }} >
                                            {image4UrlKey !== null && image4UrlKey ? (
                                                <>
                                                    <BannerUpload width={"40px"} />
                                                    <Typography sx={{ fontSize: "11px", textAlign: 'center' }}>
                                                        <span>{t('BAN02')}</span><br />
                                                        <span>{t('BAN03')}</span>
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <BannerUpload width={"40px"} />
                                                    <Typography sx={{ fontSize: "11px", textAlign: 'center' }}>
                                                        <span>{t('BAN02')}</span><br />
                                                        <span>{t('BAN03')}</span>
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    )}
                                    {message6 && <div className={classes.redColor}>{message6}</div>}
                                </DropzoneComponent>
                            </Grid>
                        </Grid>}
                </Grid>
            </RightViewLayout>
        </Grid>
    );
}

