import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Visibility } from "../../../assets/images/View.svg";
import { ReactComponent as Done } from "../../../assets/images/approve.svg";
import { ReactComponent as Filter } from "../../../assets/images/filter_Icon.svg";
import { ReactComponent as Refresh } from "../../../assets/images/refresh_Icon.svg";
import { ReactComponent as Clear } from "../../../assets/images/reject.svg";
import CustomDropDownDatePicker from "../../../components/CustomDropDownDatePicker";
import DataTable from "../../../components/DataTable";
import IconHandler from "../../../components/IconHandler";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_ORDER_DETAILS } from "../../../constants/ReactEndPoints";
import OrderService, { rejectOrder, updateOrderStatus } from "../../../services/Orderservice";
import useStyles from '../../onboard/Styles';
import { t } from "i18next";

const options = [
    { value: 'Today', label: 'Today' },
    { value: 'Yesterday', label: 'Yesterday' },
    { value: 'Week', label: 'Week' },
];
export default function TemplateList() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = useState([]);
    const [deptFlag, setDeptFlag] = useState(false);
    const [callApiFilter, setcallApiFilter] = useState(true);
    const [id, setId] = useState('')
    const [open, setOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    const [message1, setMessage1] = useState("");
    const role = localStorage.getItem("ROLE_NAME");
    const [selectedValue, setSelectedValue] = React.useState("");
    const [pageOperation, setPageOperation] = useState({
        page: 0,
        rowsPerPage: 10,
    });
    const [filters, setFilters] = useState({});
    const formatDate = (dateString) => {
        const originalDate = moment(dateString);
        const formattedDate = originalDate.format('DD MMM YYYY HH:mm:ss');
        return formattedDate;
    };

    const rejectionReasonHandler = (event) => {
        const values = event.target.value;
        setRejectionReason(values);
        if (values.trim() === '') {
            setMessage1(t('CUS14'));
        } /* else if (!/^(?!^\s*$)(?!.*\s{2})[A-Za-z,&\s]{1,200}$/.test(values)) {
            setMessage1('Accepts Aphabets Comma and & no special Characters, length will be upto Characters 100 allowed and not more than one consecutive space');
        } */ else {
            setMessage1('');

        }
    };

    var rejected = "Rejected";
    const handleSubmit = async (id) => {

        if (rejectionReason !== "" && rejectionReason !== null && rejectionReason !== undefined) {

            if (role === "ADMIN") {
                rejected = "Processing";
            }
            rejectOrder(id, rejected, rejectionReason).then((response) => {
                if (response != undefined) {
                    if (response.data.code === "ZKCSAI0001") {
                        setOpen(false);
                        toast.success(t("CUS15"), {
                            position: "top-right",
                        });
                        setRejectionReason("")
                        loadData();
                    } else {
                        toast.error(t("TEM11"), {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                }
            })
        } else {
            setMessage1(t('CUS14'))
        }
    };


    const handleRefresh = () => {
        setSelectedValue("");
        loadData()
        setFilters({
            ...filters,
            createdAt: "",
            createdBy: "",
            status: "",
        });
        setcallApiFilter(true);
        setPageOperation({ page: 0, rowsPerPage: 10 });
    };


    const loadData = () => {
        let query = "";

        if (filters?.createdAt) {
            query += "&createdAt=" + filters.createdAt;
        }

        if (filters?.createdBy) {
            query += "&createdBy=" + filters.createdBy;
        }

        if (filters?.status) {
            query += "&status=" + filters.status;
        }
        if (userFilter) {
            query += "&filterAdvert=" + userFilter;
        }

        console.log("query", query);
        const country = localStorage.getItem("COUNTRY");
        if (query === "") {
            OrderService.getCustomerOrders(pageOperation.page + 1, pageOperation.rowsPerPage, "", country).then(
                (res) => {
                    if (res.data.code === "ZKCSAE0001") {

                        console.log(res.data.code)

                        if (res.data.code === "ZKCSAE0001") {
                            console.log(res);
                            setRows(res.data.data.customerOrders);
                            setTotalRecords(res?.data?.data?.totalCount);
                        } else {
                            setRows([]);
                            setTotalRecords(0);
                        }

                    }
                }
            );
        } else if (query !== "") {
            OrderService.getCustomerOrders(pageOperation.page + 1, pageOperation.rowsPerPage, query, country).then((res) => {
                // if (res.status === 200) {
                if (res != undefined) {
                    if (res.data.code === "ZKCSAE0001") {
                        setRows(res.data.data.customerOrders);
                        setTotalRecords(res?.data?.data?.totalCount);
                    } else {
                        setRows([]);
                        setTotalRecords(0);
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (callApiFilter) {
            userFilter = selectedValue
            loadData();
            setcallApiFilter(false);
        }
    }, [callApiFilter]);


    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 50,
            flex: 1,
            renderCell: (e) => e.row.id,
            hide: true,
        },
        {
            field: "advertId",
            headerName: "Advert",
            width: 50,
            flex: 1,
            renderCell: (e) => e.row.advertId,
            hide: true,
        },

        {
            field: "createdAt",
            headerName: t("CUS2"),
            width: 200,
            flex: 1.2,
            renderCell: (e) => formatDate(e.row.createdAt),
        },

        {
            field: "createdBy",
            headerName: t("CUS3"),
            width: 200,
            flex: 1,
            renderCell: (e) => e.row.createdBy,
        },
        {
            field: "templateName",
            headerName: t("CUS4"),
            width: 50,
            flex: 1.2,
            renderCell: (e) => e.row.templateName,
        },
        {
            field: "customerId",
            headerName: t("CUS5"),
            width: 200,
            flex: 1.5,
            renderCell: (e) => e.row.customerId,
        },
        {
            field: "status",
            headerName: t("CUS6"),
            width: 200,
            flex: 1,
            renderCell: (e) => e.row.status,
        },

        {
            headerName: t("USER10"),
            sortable: false,
            headerAlign: "center",
            align: "center",
            flex: 1.2,
            renderCell: (e) => (
                <IconHandler>
                    <Tooltip title={t("CUS7")} arrow>
                        <Visibility
                            onClick={() => handleActions("View", e)}
                            style={{ alignSelf: "center", paddingTop: "0px", margin: "7px", width: '1.3em' }}
                        />
                    </Tooltip>
                    {e.row.status === "Submitted" && role === "GRAPHIC DESIGNER" || e.row.status === "Review" && role === "ADMIN" ? (
                        <Tooltip title={t("CUS8")} arrow>
                            <Done
                                onClick={() => handleActions("Approve", e)}
                                style={{ alignSelf: "center", paddingTop: "0px", margin: "7px", width: '1.1em' }}
                            />
                        </Tooltip>
                    ) : (null)}
                    {e.row.status === "Submitted" && role === "GRAPHIC DESIGNER" || e.row.status === "Review" && role === "ADMIN" ? (
                        <Tooltip title={t("CUS9")} arrow>
                            <Clear
                                onClick={() => handleActions("Reject", e)}
                                style={{ margin: "7px", width: '1.1em' }}
                            />
                        </Tooltip>
                    ) : null}
                </IconHandler>
            ),
        },
    ];

    const handleClose = () => {
        setOpen(false);
        setMessage1("")
        navigate("/CustomerOrders")
    };

    const handleCancel = () => {
        setOpen(false);
        setMessage1("")
        setRejectionReason("")
        navigate("/CustomerOrders")
    };
    const token = localStorage.getItem('USER_TOKEN');
    var processing = 'Processing';

    const handleActions = (action, data) => {
        if (action === "Reject") {
            setId(data['id'])
            setOpen(true);
        } else if (action === "Approve") {
            setId(data['id'])
            if (role === "ADMIN") {
                processing = "Delivered";
            }
            updateOrderStatus(data['id'], processing).then((response) => {
                if (response != undefined) {
                    if (response.data.code === "ZKCSAI0001") {
                        toast.success(t("CUS10"), {
                            position: "top-right",
                        });
                        loadData();
                    } else {
                        toast.error(t("CUS11"), {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error(t("CUS11"), {
                        position: "top-right",
                    });
                }
            })
        } else if (action === "View") {
            const imgId = data['id'];
            navigate(REACT_ORDER_DETAILS, { state: { imgId } });
        }
    };

    const handleChangePage = (newPage) => {
        // setSelectedValue("");
        setPageOperation({ ...pageOperation, page: newPage });
        setcallApiFilter(true);
    };

    const handleChangeRowsPerPage = (pageSize) => {
        setSelectedValue("");
        setPageOperation({ ...pageOperation, page: 0, rowsPerPage: pageSize });
        setcallApiFilter(true);
    };

    const handleSelectedRows = (data) => {
        console.log("data", data);
    };

    var userFilter = "";
    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedValue(value)

        userFilter = value;
        loadData();
        setPageOperation({ ...pageOperation, page: 0 });
    };

    const actions = () => (
        <Box item style={{ display: "inherit" }}>
            <CustomDropDownDatePicker value={selectedValue} onChange={handleChange} options={options} />
            <Tooltip title={t("USER2")} arrow>
                <Refresh
                    width={"40px"}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => {
                        handleRefresh();
                    }}
                />
            </Tooltip>
            {/* <Tooltip arrow title="Filter">
                <Filter
                    width={"40px"}
                    //   onClick={() => setOpenFilter(!openFilter)}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                />
            </Tooltip> */}
        </Box>
    );

    return (
        <Grid >
            <RightViewLayout
                title={t("CUS1")}
                actions={actions}
                type="table"
            >
                <Grid container margin='auto' paddingLeft={'0px'} overflow={"initial"} display="flex" >
                    <DataTable
                        columns={columns}
                        rows={rows}
                        onSelection={(data) => handleSelectedRows(data)}
                        page={pageOperation.page}
                        totalRecords={totalRecords}
                        rowsPerPage={pageOperation.rowsPerPage}
                        onPageChange={(pn) => handleChangePage(pn)}
                        onPageSizeChange={(ps) => handleChangeRowsPerPage(ps)}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Grid>
            </RightViewLayout>
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h6">{t("CUS12")}</Typography>
                        <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <textarea value={rejectionReason} placeholder={t("CUS13")} onChange={rejectionReasonHandler}
                        rows={10} cols={52} style={{ resize: "vertical" }} />
                    {message1 && <div className={classes.redColor}>{message1}</div>}
                    <div hidden>{id}</div>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} paddingTop={"10px"}>
                        <Grid sx={{ display: 'flex', justifyContent: 'flex-end', gap: '15px', marginRight: '19%', marginTop: '20%' }} item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <Button id="customer-orderCancel" variant="outlined" color="primary" size="large" onClick={() => handleCancel()} sx={{ marginRight: 2 }}> {t("TEM14")}</Button>
                            <Button id="customer-orderSubmit" variant="contained" color="primary" size="large" onClick={() => handleSubmit(id)}> {t("TEM16")}</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}
